import Header from "../navigation/header";
import {
  StyleSheet,
  TouchableOpacity,
  Modal,
  Button,
  Switch,
  TextInput as TextInputRN,
  FlatList,
  Alert,
  ScrollView,
  SafeAreaView,
  ActivityIndicator,
  Dimensions,
} from "react-native";
import { useFocusEffect } from "@react-navigation/native";

import { Text, View } from "../../components/Themed";
import { RootStackScreenProps } from "../../types"; // @ts-ignore
// import { DataTable } from "react-native-paper";
import Icon from "react-native-vector-icons/AntDesign";
import IconEdit from "react-native-vector-icons/FontAwesome";
import IconEye from "react-native-vector-icons/Entypo";
import React, { useEffect, useState, useMemo, useRef } from "react";
import IconCross from "react-native-vector-icons/Entypo";
import AsyncStorage from "@react-native-async-storage/async-storage";
import AwesomeAlert from "react-native-awesome-alerts";
import { TextInput } from "react-native-paper";
import { baseUrl, postData } from "../../networking/api";
import { Provider as PaperProvider } from "react-native-paper";
import { Item } from "react-native-paper/lib/typescript/components/List/List";
import DataTable from "react-data-table-component";
import SuccessPopup from "../../components/SuccessPopup";
import { useSelector } from "react-redux";
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';

export default function ValveDetailsReport({ navigation, route }) {
  const { status, zoneId, subzoneId } = route?.params;
  const [isVisible, setIsVisible] = useState(false);
  const [issuccessVisible, setissuccessVisible] = useState(false);
  const [responsestatus, setresponsestatus] = useState("");
  const [PopupMessage, setPopupMessage] = useState("");
  const [isEnabled, setIsEnabled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [countryIds, setCountryId] = useState();
  const [isAdd, setisAdd] = useState(false);
  var [counter, setCounter] = useState(1);
  const [sort, setsort] = useState(0);
  const [editpopup, setEditPopup] = useState(false);
  const [popid, setpopid] = useState("");
  const [popindex, setpopindex] = useState("");
  const [projectId, setProjectId] = useState("");

  // const toggleSwitch = () => setIsActive(0);

  const [showAlert, setAlert] = useState(false);
  const [isvalveOmegaDataValid, setVlaveOmegaDataValid] = useState(false);
  const [isvalveOmegaDataEntered, setVlaveOmegaDataEntered] = useState(false);
  const [valveOmegaData, setVlaveOmegaData] = useState([]);
  const [CountryByName, setCountryByName] = useState("");
  const [error, setError] = useState("");
  const textvalveOmegaData = useRef("");
  const [countryLength, setCountryLength] = useState(0);
  const [isLoadingSync, setIsLoadingSync] = useState(false);

  const [searchData, setSearch] = useState("");
  const [filterData, setFilteredDataSource] = useState([]);
  var idProject = useSelector((state) => state.idUpdating);

  //Pagination
  const optionsPerPage = [10, 25, 50, 100];

  const [page, setPage] = React.useState(0);
  const [itemsPerPage, setItemsPerPage] = React.useState(optionsPerPage[0]);
  const from = page * itemsPerPage;
  const to = Math.min((page + 1) * itemsPerPage, countryLength);
  const screenwidth = Dimensions.get('window').width
  const isMobile = screenwidth < 768;

  // var alpha_pattern = new RegExp(/^[a-zA-Z]+(\s[a-zA-Z]+)?$/);
  var alpha_pattern = new RegExp(/^[a-zA-Z]+(\s[a-zA-Z]+)?$/);
  // var alpha_pattern = new RegExp(/^(?!\s*$).+/);

  useFocusEffect(
    React.useCallback(() => {
      getAdminData();
      console.log("zoneId", zoneId);
      console.log("subzoneId", subzoneId);


      searchFilterFunction("");
    }, [idProject])
  );

  const getAdminData = async () => {
    let jsonValue = await AsyncStorage.getItem("userData");
    // console.log("jsonValue:", jsonValue);
    let projValue = await AsyncStorage.getItem("projectData");

    // console.log("jsonValue & projValue:", jsonValue, projValue);
    if (projValue != null) {
      // console.log("project id select in zone create", projValue);
      setProjectId(projValue);
      getValvesbyOmega(projValue);
    } else if (jsonValue != null) {
      var userDetailsresponse = JSON.parse(jsonValue);
      // console.log("userDetailsresponse in zone ==>", userDetailsresponse);
      setProjectId(userDetailsresponse.project_selected);
      getValvesbyOmega(userDetailsresponse.project_selected);
    }
  };

  const searchFilterFunction = (text) => {
    // Check if searched text is not blank
    if (text) {
      // Inserted text is not blank
      // Filter the masterDataSource
      // Update FilteredDataSource
      const newData = valveOmegaData.filter(function (item) {
        const itemData = item.country
          ? item.country.toUpperCase()
          : "".toUpperCase();
        const textData = text.toUpperCase();
        return itemData.indexOf(textData) > -1;
      });
      // console.log("new data search---->", newData);
      setFilteredDataSource(newData);
      setSearch(text);
      setPage(0);
    } else {
      // Inserted text is blank
      // Update FilteredDataSource with masterDataSource
      setFilteredDataSource(valveOmegaData);
      setSearch(text);
      setPage(0);
    }
  };
  var counter = 1;
  const customStyles = {
    rows: {
      style: {
        minHeight: "30px", // override the row height
      },
    },
    headRow: {
      style: {
        borderTopStyle: "solid",
        borderTopWidth: "1px",
        backgroundColor: "#319ef0a3",
        minHeight: "40px",
        fontSize: 16,
        fontWeight: "600",
      },
    },
    headCells: {
      style: {
        // paddingLeft: '8px', // override the cell padding for head cells
        // paddingRight: '8px',
        justifyContent: "center",
        minHeight: "20px",

        // borderRightWidth: 2,
        "&:not(:last-of-type)": {
          borderRightStyle: "solid",
          borderRightWidth: "1px",
          // fontSize: 16,
          // fontWeight: '600',
          // borderRightColor: defaultThemes.default.divider.default,
        },
      },
    },
    cells: {
      style: {
        paddingLeft: "8px", // override the cell padding for data cells
        paddingRight: "8px",
        justifyContent: "center",

        "&:not(:last-of-type)": {
          borderRightStyle: "solid",
          borderRightWidth: "1px",
          fontSize: 16,
          fontWeight: "400",
          textTransform: "Capitalize",

          // borderRightColor: defaultThemes.default.divider.default,
        },
      },
    },
  };

  const caseInsensitiveSort = (rowA, rowB) => {
    const a = rowA.country.toLowerCase();
    const b = rowB.country.toLowerCase();

    if (a > b) {
      return 1;
    }

    if (b > a) {
      return -1;
    }

    return 0;
  };

  // filterData.forEach((id, index) => { filterData.id = index + 1; });

  const columns = [
    {
      name: "Sr.No",
      selector: (row) => row.index,
      // //cell: (row, index) => index + 1,
      // sortable: true,
      width: "100px",
    },
    {
      name: "Omega Name",
      selector: (row) => row.device_name,
      sortable: true,
      sortFunction: caseInsensitiveSort,
      width: "400px",
    },
    {
      name: "Valve",
      selector: (row) => row.name,
      sortable: true,
      sortFunction: caseInsensitiveSort,
      // width: "100px",
    },
    {
      name: "State",
      // selector: (row) => row.state,
      selector: (row) => row.status,
      sortable: true,
      sortFunction: caseInsensitiveSort,
      width: "200px",
    },
  ];

  // get valve omega Get ApiS
  const getValvesbyOmega = async (projId) => {
    setIsLoading(true);

    try {
      var valveData = JSON.stringify({
        project_id: projId,
        state: status,
        zone_id: zoneId,
        sub_zone_id: subzoneId
      });
      // console.log("valveData", valveData);
      const valveOmegaResponse = await postData(
        valveData,
        "dashboards/valvesDashDetails"
      );
      setIsLoading(false);
      var code = valveOmegaResponse.code;
      if (code == 1) {
        // console.log("list====>", valveOmegaResponse.data.omegaDataResponseData);
        // setVlaveOmegaData(valveOmegaResponse.data.omegaDataResponseData);
        // setFilteredDataSource(valveOmegaResponse.data.omegaDataResponseData);

        const valveData = valveOmegaResponse?.data?.map((element: any, index: number) => {
          const { id, ...rest } = element;
          return {
            id: index + 1,
            ...rest,
          };
        }) || [];


        console.log("valveData", JSON.stringify(valveData));
        setVlaveOmegaData(valveData);
        setFilteredDataSource(valveData);
      } else {
        setIsLoading(false);
        //alert(valveOmegaResponse.message);
      }
    } catch (error) {
      setIsLoading(false);
      // console.log(error);
    }
  };


  const downloadExcelReport = async () => {
    const filteredData = valveOmegaData.map(({ device_name, name, status }, index) => ({
      'Sr No': index + 1,
      'Omega Name': device_name,
      'Valve': name,
      'State': status
    }));
    // const filteredData = valveOmegaData.map(({ device_ids, id, omega_id, lat, long, active, project_id, device_id, type, value, input_id, input_index, flow, unitOfMeasure, createdAt, updatedAt, index, ...rest }) => rest);
    const worksheet = XLSX.utils.json_to_sheet(filteredData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    const excelBuffer = XLSX.write(workbook, {
      type: 'array',
      bookType: 'xlsx',
    });
    const excelData = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    saveAs(excelData, 'valve_details_report.xlsx');
  }

  return (
    <View style={styles.container}>
      <SuccessPopup
        visible={issuccessVisible}
        PopupMessage={PopupMessage}
        Responsestatus={responsestatus}
        onChange={() => setissuccessVisible(false)}
      />
      <Modal
        onRequestClose={() => setIsVisible(false)}
        transparent={true}
        visible={isVisible}
      >
        <View
          style={{
            position: "absolute",
            width: "100%",
            height: "100%",
            justifyContent: "center",
            alignItems: "center",
            alignContent: "center",
            backgroundColor: "rgba(100,100,100, 0.5)",
          }}
        >
          <View style={[styles.containeralt]}>
            <TouchableOpacity
              style={{ alignSelf: "flex-end" }}
              onPress={() => setIsVisible(false)}
            >
              <IconCross
                name="circle-with-cross"
                size={20}
                color="#000"
                style={{ bottom: 5, top: 10 }}
              />
            </TouchableOpacity>

            <View style={{ width: "100%" }}>
              <Text style={{ fontWeight: "600" }}>Country Name</Text>
              {isAdd == true && (
                <TextInputRN
                  style={styles.input}
                  placeholder=""
                  // value={CountryByName}
                  autoFocus={true}
                  onChange={textTovalveOmegaData}
                />
              )}

              {isAdd == false && (
                <TextInputRN
                  style={styles.input}
                  placeholder=""
                  value={CountryByName}
                  key="Edit"
                  autoFocus
                  onChangeText={(text) => {
                    setCountryByName(text);
                  }}
                />
              )}
              <Text style={[styles.error_text]}>{error}</Text>
            </View>
            {isAdd == true && (
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-evenly",
                  marginHorizontal: 20,
                }}
              >
                <TouchableOpacity
                  style={styles.saveBtn}
                  onPress={() => CheckEnteredData()}
                >
                  <Text style={styles.btnText}>Save</Text>
                </TouchableOpacity>

                <TouchableOpacity
                  style={styles.saveBtn}
                  onPress={() => {
                    setIsVisible(false), setError("");
                  }}
                >
                  <Text style={styles.btnText}>Cancel</Text>
                </TouchableOpacity>
              </View>
            )}
            {isAdd == false && (
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-evenly",
                  marginHorizontal: 20,
                }}
              >
                <TouchableOpacity
                  style={styles.saveBtn}
                  onPress={() => CheckEnteredData()}
                >
                  <Text style={styles.btnText}>Update</Text>
                </TouchableOpacity>

                <TouchableOpacity
                  style={styles.saveBtn}
                  onPress={() => setIsVisible(false)}
                >
                  <Text style={styles.btnText}>Cancel</Text>
                </TouchableOpacity>
              </View>
            )}

            {/* <Gap />
{isVisible ? <Modalception depth={depth + 1} /> : null}
<Gap /> */}
          </View>
        </View>
      </Modal>
      <Modal
        visible={editpopup}
        animationType={"slide"}
        transparent={true}
        onRequestClose={() => {
          setEditPopup(false);
        }}
      >
        <View style={[styles.contanerSwitch]}>
          <TouchableOpacity
            style={{ alignSelf: "flex-end" }}
            onPress={() => setEditPopup(false)}
          >
            <IconCross
              name="circle-with-cross"
              size={20}
              color="#000"
              style={{ bottom: 5, top: 0 }}
            />
          </TouchableOpacity>

          <Text style={[styles.tableHead, { marginBottom: 20 }]}>
            Do You Want Update Status
          </Text>
          <View
            style={{
              flexDirection: "row",
              paddingRight: 10,
              justifyContent: "space-between",
            }}
          >
            <TouchableOpacity
              onPress={() => {
                setEditPopup(false), toggleSwitch();
              }}
              style={styles.saveBtn}
            >
              <Text style={{ color: "white" }}>Yes</Text>
            </TouchableOpacity>
            <TouchableOpacity
              onPress={() => {
                setEditPopup(false);
              }}
              style={styles.saveBtn}
            >
              <Text style={{ color: "white" }}>No</Text>
            </TouchableOpacity>
          </View>
        </View>
      </Modal>

      <View style={isMobile ? { flexDirection: "row", width: "100%", marginTop: "15%" } : { flexDirection: "row", width: "100%", marginTop: "5%" }}>
        <View style={{ flex: 1, margin: 10 }}>
          <Text style={styles.lableText}>
            Report {">"} Valve Details Report
          </Text>
        </View>
      </View>

      <View style={{ width: '100%', alignItems: "flex-end" }}>
        <TouchableOpacity
          style={isMobile ? [styles.addCountry, { width: '45%' }] : [styles.addCountry, { width: '10%' }]}
          onPress={() => {
            downloadExcelReport();
          }}
        >
          <Text style={styles.btnText}>
            <Icon name="exclefile1" size={15} color="#fff" /> Download
          </Text>
        </TouchableOpacity>
      </View>
      {/* <ScrollView
        style={[styles.card, styles.shadowProp]}
        contentContainerStyle={{ flexGrow: 1 }}
      > */}

      <View style={[styles.card, { flexGrow: 1 }]}>
        <View
          style={[
            styles.shadowProp,
            {
              backgroundColor: "white",
            },
          ]}
        >
          <View
            style={{
              width: "100%",
              // flex: 1,
              backgroundColor: "#F6F9F9",
            }}
          >
            <View
              style={isMobile ? {
                borderWidth: 1,
                borderColor: "#e3e0e0",
                width: "220%",
                marginLeft: "-60%",
              } : {
                width: "130%",
                borderWidth: 1,
                borderColor: "#e3e0e0",
              }}
            >
              <View>
                {isLoading ? (
                  <ActivityIndicator
                    //visibility of Overlay Loading Spinner
                    color="#1DA1F2"
                    visible={isLoading}
                    //Text with the Spinner
                    textContent={"Loading..."}
                    size={100}
                  //  //Text style of the Spinner Text
                  //  textStyle={styles.spinnerTextStyle}
                  />
                ) : (
                  <DataTable
                    // title="Country"
                    columns={columns}
                    data={filterData}
                    customStyles={customStyles}
                    pagination
                    defaultSortFieldId={"country"}
                    // striped
                    defaultSortAsc={true}
                  // defaultSortDsc={false}
                  />
                )}
              </View>
              {/* prvider datatable code here */}

              {/* ended */}
            </View>
          </View>
        </View>
        {/* </ScrollView> */}
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    padding: 20,
    // marginTop: 350,
    height: "100%",
  },
  itemStyle: {
    padding: 10,
  },
  textInputStyle: {
    height: 35,
    borderWidth: 2,
    paddingLeft: 20,
    borderRadius: 5,
    borderColor: "#319ef0",
    backgroundColor: "#FFFFFF",
    width: "40%",
    fontWeight: "400",
  },
  tableHead: {
    fontSize: 14,
    /* FontFamily: 'Muli' ,*/
    fontWeight: "700",
    color: "#000000",
  },

  containeralt: {
    // alignItems: "center",
    backgroundColor: "white",
    borderColor: "#eee",
    borderRadius: 10,
    borderWidth: 1,
    // justifyContent: "center",
    height: "auto",
    margin: "auto",
    padding: 30,
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
    // marginRight: 10,
    // marginRight: 1,
    width: "30%",
  },
  contanerSwitch: {
    backgroundColor: "white",
    borderColor: "#eee",
    borderRadius: 5,
    alignSelf: "center",
    borderWidth: 1,
    // justifyContent: "center",
    height: "25%",
    margin: "auto",
    padding: 10,
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
    // marginRight: 10,
    // marginRight: 1,
    width: "30%",
  },
  tableHeader: {
    color: "#000",
    fontSize: 14,
    /* FontFamily: 'Muli' ,*/
    fontWeight: "400",
  },
  input: {
    borderColor: "gray",
    width: "100%",
    borderWidth: 1,
    borderRadius: 2,

    padding: 10,
    marginTop: 20,
    marginBottom: 20,
  },
  title: {
    fontSize: 20,
    fontWeight: "bold",
  },
  addCountry: {
    alignItems: "center",
    backgroundColor: "#006EB9",
    padding: 10,
    borderWidth: 1,
    borderColor: "#006EB9",
    width: "50%",
    // marginTop: 20,
  },
  saveBtn: {
    alignItems: "center",
    backgroundColor: "#006EB9",
    padding: 10,
    borderWidth: 1,
    borderColor: "#006EB9",
    width: "50%",
    marginRight: 10,
  },
  btnText: {
    color: "#fff",
    fontWeight: "600",
  },
  card: {
    width: "45%",
    flex: 1,
    marginVertical: 10,
  },
  shadowProp: {
    shadowColor: "#171717",
    shadowOffset: { width: -2, height: 4 },
    shadowOpacity: 0.2,
    shadowRadius: 15,
  },
  // Error message css
  error_text: {
    color: "red",
    fontSize: 14,
    fontWeight: "600",
    width: "70%",
    marginLeft: 0,
    marginBottom: 10,
  },

  link: {
    marginTop: 15,
    paddingVertical: 15,
  },
  linkText: {
    fontSize: 14,
    color: "#2e78b7",
  },
  lableText: {
    color: "#484848",
    fontWeight: "700",
    // marginTop: 18,
    // fontSize: 21,
  },
});
