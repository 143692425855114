import { StatusBar } from "expo-status-bar";
import { SafeAreaProvider } from "react-native-safe-area-context";

import useCachedResources from "./hooks/useCachedResources";
import useColorScheme from "./hooks/useColorScheme";
import LoginScreen from "./screens/Login/LoginScreen";

import Navigation from "./navigation";
import MainHeader from "./navigation/header";
import MyRoutes from "./screens/MyRoutes";
import { Image, LogBox, Text, View } from "react-native";
import MainNavigation from "./navigation";
import { createStore } from "redux";
import allReducers from "./components/redux/reducers";
import { Provider } from "react-redux";
import socketServices from "./constants/utils/socketService";
import { Provider as PaperProvider } from 'react-native-paper';

const mystore = createStore(allReducers);

export default function App() {
  const isLoadingComplete = useCachedResources();
  const colorScheme = useColorScheme();
  // socketServices.initializeSocket();
  LogBox.ignoreLogs(["Require cycle: node_modules/victory"]);

  if (!isLoadingComplete) {
    return null;
  } else {
    return (
      <PaperProvider>
      <SafeAreaProvider>
        <Provider store={mystore}>
          <MainNavigation colorScheme={colorScheme} />
          <StatusBar />
        </Provider>
      </SafeAreaProvider>
    </PaperProvider>
    );
  }
}
