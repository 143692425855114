import Header from "../navigation/header";
import {
  StyleSheet,
  TouchableOpacity,
  TextInput,
  Modal,
  ScrollView,
  Alert,
  ActivityIndicator,
  Switch,
  useWindowDimensions,
  Image,
  NativeSyntheticEvent,
  TextInputChangeEventData,
  Dimensions,
} from "react-native";
import * as ImagePicker from "expo-image-picker";

import { RadioButton, Button } from "react-native-paper";
import IconEye from "react-native-vector-icons/Entypo";

import moment from "moment";
import { TabView, SceneMap, TabBar } from "react-native-tab-view";
// import { Provider as PaperProvider ,DataTable } from "react-native-paper";
import DataTable from "react-data-table-component";
import { CheckBox } from "react-native-elements";
import AsyncStorage from "@react-native-async-storage/async-storage";
import IconEdit from "react-native-vector-icons/FontAwesome";

import SuccessPopup from "../../../components/SuccessPopup";

import { DatePickerModal } from "react-native-paper-dates";
import { Dropdown } from "react-native-element-dropdown";

import { Text, View } from "../../../components/Themed";
import IconRefresh from "react-native-vector-icons/FontAwesome";
import IconLoc from "react-native-vector-icons/MaterialIcons";
import Icon from "react-native-vector-icons/AntDesign";
import React, { useState, useRef, useEffect } from "react";
import IconCross from "react-native-vector-icons/Entypo";
import { baseUrl, postData, getMethod } from "../../../networking/api";
import { useFocusEffect } from "@react-navigation/native";
import Colors from "../../../constants/Colors";

export default function CreateProjectProfile({ navigation }) {
  const [isVisible, setIsVisible] = useState(false);
  const [isEnabled, setIsEnabled] = useState(false);
  const [animating, setAnimating] = useState(true);
  const [isAdd, setisAdd] = useState(false);
  const [Lat, setLatitude] = useState("");
  const [Long, setLongitude] = useState("");

  const [issuccessVisible, setissuccessVisible] = useState(false);
  const [responsestatus, setresponsestatus] = useState("");
  const [PopupMessage, setPopupMessage] = useState("");

  const [editpopup, setEditPopup] = useState(false);
  const [popid, setpopid] = useState("");
  const [popindex, setpopindex] = useState("");

  const [projectImage, setProjectImage] = useState(null);
  const [imageError, setImageError] = useState("");

  const [ProjectNameData, setProjectNameData] = useState([]);
  const [filterData, setFilteredDataSource] = useState([]);

  const [genderchecked, setGenderChecked] = useState(1);
  const [isMarriedchecked, setMaritialChecked] = useState(1);
  const [isstatuschecked, setStatusChecked] = useState(1);
  const [DataRecord, setDataRecord] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [isOmegaLoading, setIsOmegaLoading] = useState(false);

  const [empCode, setEmpCode] = useState("");
  const [ProjectCode, setProjectCode] = useState("");

  const [ProjectIdOmega, setProjectIdOmega] = useState("");
  const [ProjectIdNew, setProjectIdNew] = useState("");

  const [ProjectID, setProjectID] = useState("");
  const [isProjectIDValid, setProjectIDValid] = useState(false);
  const [isProjectIDEntered, setProjectIDEntered] = useState(false);

  const [ProjectName, setProjectName] = useState("");
  const [isProjectNameValid, setProjectNameValid] = useState(false);
  const [isProjectNameEntered, setProjectNameEntered] = useState(false);

  const [ContractorName, setContractorName] = useState("");
  const [isContractorNameValid, setContractorNameValid] = useState(false);
  const [isContractorNameEntered, setContractorNameEntered] = useState(false);

  const [ClientName, setClientName] = useState("");
  const [isClientNameValid, setClientNameValid] = useState(false);
  const [isClientNameEntered, setClientNameEntered] = useState(false);

  const [ProjectDetails, setProjectDetails] = useState("");
  const [isProjectDetailsValid, setProjectDetailsValid] = useState(false);
  const [isProjectDetailsEntered, setProjectDetailsEntered] = useState(false);

  const [OmegaIdData, setOmegaIdData] = useState([]);
  const [OmegaDetails, setOmegaDetails] = useState([]);
  const [AnalogInput, setAnalogInput] = useState([]);
  const [DigitalInput, setDigitalInput] = useState([]);
  const [dashboardName, setDashboardName] = useState([]);

  const [OmegavalueId, setOmegaValueiD] = useState("");
  const [shouldShow, setShouldShow] = useState(true);

  const [ProjectTypeData, setProjectTypeData] = useState([]);
  const [ProjectTypevalueId, setProjectTypeValueiD] = useState("");

  const [CountryNameData, setCountryNameData] = useState([]);
  const [isCountryNameDataValid, setCountryNameDataValid] = useState(false);
  const [isCountryNameDataEntered, setCountryNameDataEntered] = useState(false);

  const [StateNameData, setStateNameData] = useState([]);
  const [isStateNameDataValid, setStateNameDataValid] = useState(false);
  const [isStateNameDataEntered, setStateNameDataEntered] = useState(false);

  const [CityNameData, setCityNameData] = useState([]);
  const [isCityNameDataValid, setCityNameDataValid] = useState(false);
  const [isCityNameDataEntered, setCityNameDataEntered] = useState(false);

  const [TahasilNameData, setTahasilNameData] = useState([]);
  const [isTahasilNameDataValid, setTahasilNameDataValid] = useState(false);
  const [isTahasilNameDataEntered, setTahasilNameDataEntered] = useState(false);

  const [VillageNameData, setVillageNameData] = useState([]);
  const [isVillageNameDataValid, setVillageNameDataValid] = useState(false);
  const [isVillageNameDataEntered, setVillageNameDataEntered] = useState(false);

  const [PinCode, setPinCode] = useState("");
  const [isPinCodeValid, setPinCodeValid] = useState(false);
  const [isPinCodeEntered, setPinCodeEntered] = useState(false);

  const [Address, setAddress] = useState("");
  const [isAddressValid, setAddressValid] = useState(false);
  const [isAddressEntered, setAddressEntered] = useState(false);

  const [KhasaraNumber, setKhasaraNum] = useState("");
  const [isKhasaraValid, setKhasaraValid] = useState(false);
  const [isKhasaraEntered, setKhasaraEntered] = useState(false);

  const [loginId, setloginId] = useState("");
  const [isloginIdValid, setloginIdValid] = useState(false);
  const [isloginIdEntered, setloginIdEntered] = useState(false);

  const [Password, setPassword] = useState("");
  const [isPasswordValid, setPasswordValid] = useState(false);
  const [isPasswordEntered, setPasswordEntered] = useState(false);

  const [isFocus, setIsFocus] = useState(false);

  // Master Id's and names
  const [countryValue, setCountryValue] = useState("");
  const [countryValId, setCountryValId] = useState("");
  const [stateValue, setStateValue] = useState("");
  const [stateValId, setStateValId] = useState("");
  const [cityValue, setCityValue] = useState("");
  const [cityValId, setCityValId] = useState("");
  const [tahasilValue, setTahasilValue] = useState("");
  const [tahasilValId, setTahasilValId] = useState("");
  const [VillageValue, setVillageValue] = useState("");
  const [VillageValId, setVillageValId] = useState("");
  const [KhasaraValue, setKhasaraValue] = useState("");
  const [KhasaraValId, setKhasaraValId] = useState("");

  //counter in list
  var [counter, setCounter] = useState(1);

  const [editInput, setEditInput] = useState(false);

  //Dropdown value pickup
  const [OmegaIdValue, setOmegaIdValue] = useState(null);
  const [ProjectTypeValue, setProjectTypeValue] = useState(null);

  const [sort, setsort] = useState(0);
  const [ProjectDataLength, setProjectDataLength] = useState(0);

  //Error message
  const [ProjectIdError, setProjectIDError] = useState("");
  const [ProjectNameError, setProjectNameError] = useState("");
  const [ClientnameError, setClientnameError] = useState("");
  const [ProjectDetailsError, setProjectDetailsError] = useState("");
  const [ProjectTypeError, setProjectTypeError] = useState("");

  const [ContractorError, setContractorError] = useState("");

  const [pinError, setPinError] = useState("");
  const [khasaraError, setkhasaraError] = useState("");
  const [OmegaError, setOmegaError] = useState("");

  const [qualification_Error, setQualificationError] = useState("");
  //Dropdown
  const [isAssignchecked, setAssignChecked] = useState(0);
  const [basedOnSelected, setBasedOnSelected] = useState(false);
  const dropdownRefOmegaiD = useRef();
  const dropdownRefProjectType = useRef();
  const screenwidth = Dimensions.get('window').width
  const isMobile = screenwidth < 768;
  const statusTableData = [
    { old_status: "Open", new_status: "" },
    { old_status: "Close", new_status: "" }
  ]
  const [originalStatusData, setOriginalStatusData] = useState([...statusTableData]);
  const [statusData, setStatusData] = useState<any>([...statusTableData]);

  const [statusTableVisible, setStatusTableVisible] = useState(false)
  const [tableVisible, setTableVisible] = useState(false)
  const [tempRowData, setTempRowData] = useState<any>({})

  // const closeMenu = () => {
  //   dropdownRefOmegaiD.current.close();
  //   dropdownRefProjectType.current.close();
  //   //return calls;
  //   // // console.log("on scroll close in dropdown")
  // };

  // useEffect(() => {
  //   window.addEventListener("scroll", closeMenu);
  //   return () => window.removeEventListener("scroll", closeMenu);
  // }, []);

  const pickImage = async () => {
    // No permissions request is necessary for launching the image library
    let result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.All,
      allowsEditing: true,
      aspect: [4, 3],
      quality: 1,
      base64: true,
    });

    // console.log("result of imggg====", result.uri);
    setProjectImage(result.uri);

    if (!result.cancelled) {
      setProjectImage(result.uri);
    }
  };

  const radioButtonsData = [
    {
      id: "1",
      label: "Male",
      value: 1,
      color: "red",
      selected: true,
    },
    {
      id: "2",
      label: "Female",
      value: 2,
      color: "red",
      selected: false,
    },
  ];

  const isInlineEditable = (inlineId) => {
    // console.log("inInlineEditable id---->", inlineId);
    setEditInput(true);
  };
  // DATATABLE PROJECT TYPE COLUMN AND STYLE
  const customStyles = {
    rows: {
      style: {
        // maxWidth: 50,
        minHeight: "30px", // override the row height
      },
    },
    headRow: {
      style: {
        borderTopStyle: "solid",
        borderTopWidth: "1px",
        backgroundColor: "#319ef0a3",
        minHeight: "40px",
        fontSize: 16,
        fontWeight: "600",
      },
    },
    headCells: {
      style: {
        // paddingLeft: '8px', // override the cell padding for head cells
        // paddingRight: '8px',
        justifyContent: "center",
        minHeight: "20px",

        // borderRightWidth: 2,
        "&:not(:last-of-type)": {
          borderRightStyle: "solid",
          borderRightWidth: "1px",
          // fontSize: 16,
          // fontWeight: '600',
          // borderRightColor: defaultThemes.default.divider.default,
        },
      },
    },
    cells: {
      style: {
        paddingLeft: "8px", // override the cell padding for data cells
        paddingRight: "8px",
        justifyContent: "center",

        "&:not(:last-of-type)": {
          borderRightStyle: "solid",
          borderRightWidth: "1px",
          fontSize: 16,
          fontWeight: "400",
          // borderRightColor: defaultThemes.default.divider.default,
        },
      },
    },
  };
  const columns = [
    // {
    //   name: "Sr.No",
    //   selector: "id",
    //   sortable: true
    // },
    {
      name: "Output number",
      // selector: row => !editInput ? row.project_name : <TextInput value='helllo'></TextInput>,
      selector: (row) => row.value,
      sortable: true,
    },
    {
      name: "Output Name",
      selector: (row) => row.name,
      sortable: true,
    },
    // {
    //   name: "Area",
    //   selector: "area",
    //   sortable: true
    // },
    // {
    //   name: "Water Demand",
    //   selector: "water_demand",
    //   sortable: true
    // },
  ];
  const columnsAnalog = [
    // {
    //   name: "Sr.No",
    //   selector: "id",
    //   sortable: true
    // },
    {
      name: "Output number",
      // selector: row => !editInput ? row.project_name : <TextInput value='helllo'></TextInput>,
      selector: (row) => row.value,
      sortable: true,
    },
    {
      name: "Output Name",
      selector: (row) => row.name,
      sortable: true,
    },
    // {
    //   name: "Area",
    //   selector: "area",
    //   sortable: true
    // },
    // {
    //   name: "Water Demand",
    //   selector: "water_demand",
    //   sortable: true
    // },
  ];

  const columnDigitalInput = [
    // {
    //   name: "Sr.No",
    //   selector: "id",
    //   sortable: true
    // },
    {
      name: "Output number",
      // selector: row => !editInput ? row.project_name : <TextInput value='helllo'></TextInput>,
      selector: (row) => row.value,
      sortable: true,
    },
    {
      name: "Output Name",
      selector: (row) => row.name,
      sortable: true,
    },
    // {
    //   name: "Area",
    //   selector: "area",
    //   sortable: true
    // },
    // {
    //   name: "Water Demand",
    //   selector: "water_demand",
    //   sortable: true
    // },
  ];
  const toggleSwitch = () => setIsEnabled((previousState) => !previousState);

  //DOb
  const [date, setDate] = React.useState<Date | undefined>(undefined);
  const [open, setOpen] = React.useState(false);

  const onDismissSingle = React.useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const onConfirmSingle = React.useCallback(
    (params) => {
      setOpen(false);
      setDate(params.date);
    },
    [setOpen, setDate]
  );

  //DOJ
  const [joinDate, setJoinDate] = React.useState<Date | undefined>(undefined);
  const [joinOpen, setJoinOpen] = React.useState(false);

  const onDismissJoin = React.useCallback(() => {
    setOpen(false);
  }, [setJoinOpen]);

  const onConfirmJoin = React.useCallback(
    (params) => {
      setJoinOpen(false);
      setJoinDate(params.date);
    },
    [setJoinOpen, setJoinDate]
  );
  //Regex
  // var alpha_pattern = new RegExp(/^([a-zA-Z0-9]+\s)*[a-zA-Z0-9]+$/);

  // var alpha_pattern = new RegExp(/^[a-zA-Z0-9!@#$%^&-]+(\s[a-zA-Z0-9!@#$%^&-]+)?$/);
  var alpha_numeric = new RegExp(/^[a-z0-9][a-z0-9 ]{1,48}[a-z0-9]$/i);
  // /^[^\s].*[^\s]$/
  var alpha_pattern = new RegExp(/^[a-zA-Z0-9 ]*$/);
  var Email_Id_pattern = new RegExp(
    /^\w+([\.-]?\w+)@\w+([\.-]?\w+)(\.\w\w+)+$/
  );
  var password_pattern = new RegExp(
    /^[a-zA-Z0-9]+$/
    // /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d@$!%*#?&^_-]{8,}$/
  );
  // var MobileNo_pattern = new RegExp(/^[0]?[789]\d{9}$/);
  // var number_regex = new RegExp(/^[0-9]*$/);
  const regex = /^[0-9]*$/;

  //Pagination
  const optionsPerPage = [10, 25, 50, 100];

  const [page, setPage] = React.useState(0);
  const [itemsPerPage, setItemsPerPage] = React.useState(optionsPerPage[0]);
  const from = page * itemsPerPage;
  const to = Math.min((page + 1) * itemsPerPage, ProjectDataLength);
  React.useEffect(() => {
    setPage(0);
    // setpopindex('');
    // setEditPopup(false);
    // setpopid('');
  }, [itemsPerPage]);

  useFocusEffect(
    React.useCallback(() => {
      getAdminData();

      // getCountry();
      // getOmegaIdList();
      getProjectTypeList();
      getAllProjectData([]);
      // getDashboardNameDetails()
    }, [])
  );

  //Get details when click on omega id
  const getOmegaDetails = async (deviceId) => {
    setIsLoading(true);
    try {
      var OmegaDetails = JSON.stringify({
        device_id: deviceId,
      });
      // console.log("OmegaDetails payload", OmegaDetails);
      const OmegaDetailsResponse = await postData(
        OmegaDetails,
        "masters/getOmegaDetails"
      );
      setIsLoading(false);
      var code = OmegaDetailsResponse.code;
      if (code == 1) {
        // console.log(" details api====>", OmegaDetailsResponse.data);


        setShouldShow(false);
        setOmegaDetails(OmegaDetailsResponse.data[0].valves);
        setAnalogInput(OmegaDetailsResponse.data[0].analogInput);
        setDigitalInput(OmegaDetailsResponse.data[0].digitalInput);
      } else {
        setDataRecord(OmegaDetailsResponse.data)
        setIsLoading(false);

        // alert(OmegaDetailsResponse.message);
      }
    } catch (error) {
      setIsLoading(false);
      // console.log(error);
    }
  };

  //Get Omega  id from Bermad when click on refresh button
  const getOmegaIdList = async () => {
    if (ProjectID == "") {
      setProjectIDError("Please enter Project Id");
      return;
    }
    if (ProjectTypevalueId == "") {
      setProjectTypeError("Please select Project Type");
      return;
    }
    if (ContractorName == "") {
      setContractorError("Please enter Contractor name ");
      return;
    }
    if (ClientName == "") {
      setClientnameError("Please select Client name");
      return;
    }

    setIsOmegaLoading(true);

    try {
      var OmegaIdData = JSON.stringify({
        id: ProjectID,
        client: ClientName,
        contractor: ContractorName,
        project_type_id: ProjectTypevalueId,
      });
      // console.log("payload for omega reload", OmegaIdData);
      const OmegaIdList = await postData(
        OmegaIdData,
        // "masters/getMasterList"
        "masters/getOmegaFromBermad"
      );
      setIsOmegaLoading(false);
      var code = OmegaIdList.code;
      if (code == 1) {
        setIsOmegaLoading(false);

        // console.log("Bermad Omega list api====>", OmegaIdList.data);
        setProjectName(OmegaIdList.data[0].project_name)
        setProjectIdNew(OmegaIdList.data[0].id)
        var mergeData = OmegaIdList.data;
        mergeData.unshift({
          id: 0,
          omega_id: "",
          device_name: "None",
          lat: "",
          long: "",

        });
        setOmegaIdData(mergeData);
        getDashboardNameDetails()
      } else {
        setIsOmegaLoading(false);
        setPopupMessage(OmegaIdList.message);
        setissuccessVisible(true);
        setresponsestatus("failed");
        var mergeData = OmegaIdList.data;
        mergeData.unshift({
          id: 0,
          omega_id: "",
          device_name: "None",
          lat: "",
          long: "",

        });
        setOmegaIdData(mergeData);
        if (ProjectID == "") {
          setProjectIDError(OmegaIdList.message);
        }
        // alert(OmegaIdList.message);
      }
    } catch (error) {
      setIsOmegaLoading(false);
      // console.log(error);
    }
  };

  // Get all Project list Api
  const getAllProjectData = async (sortdata: never[]) => {
    setIsLoading(true);
    if (sortdata.length > 0) {
      setProjectDataLength(sortdata.length);
      setProjectNameData(sortdata);
      setFilteredDataSource(sortdata);
    } else {
      try {
        var getProjectData = JSON.stringify({
          module: "project",
          relation: [],
        });
        const ProjectNameResponse = await postData(
          getProjectData,
          "masters/getMasterList"
        );
        setIsLoading(false);
        var code = ProjectNameResponse.code;
        if (code == 1) {

          setProjectDataLength(ProjectNameResponse.data.length);
          setProjectNameData(ProjectNameResponse.data);
          setFilteredDataSource(ProjectNameResponse.data);
          // const results = jsonToCSV(ProjectNameResponse.data);
          // // console.log("csv==", results);
        } else {
          setIsLoading(false);
          alert(ProjectNameResponse.message);
        }
      } catch (error) {
        setIsLoading(false);
        // console.log(error);
      }
    }
  };

  //Get all dashboard names
  const getDashboardNameDetails = async () => {
    setIsLoading(true);
    let jsonValue = await AsyncStorage.getItem("projectData");
    try {
      if (jsonValue != null) {
        var projectId = JSON.parse(jsonValue);
        fetch(baseUrl + `masters/getProjectWiseDashboardListNew/${projectId}`, {
          method: "GET"
        })
          .then((data) => {
            setTableVisible(true)
            return data.json();
          })
          .catch((error) => {
            // console.log(error);
          })
          .then((response) => {
            if (response.code == 1) {
              setDashboardName(response.data)
              setTableVisible(true)
            }
          });
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const DigitalOutputRoute = () => (
    <View
      style={{
        flex: 1,
        justifyContent: "flex-start",
        backgroundColor: "#F6F9F9",
      }}
    >
      {" "}
      <View style={[styles.card, { flexGrow: 1 }]}>
        <View
          style={[
            styles.shadowProp,
            {
              backgroundColor: "white",
            },
          ]}
        >
          <View
            style={{
              width: "100%",
              // flex: 1,
              backgroundColor: "#F6F9F9",
            }}
          >
            <View
              style={{
                borderWidth: 1,
                borderColor: "#e3e0e0",
              }}
            >
              <View>
                <DataTable
                  // title="Country"
                  columns={columns}
                  data={OmegaDetails}
                  customStyles={customStyles}
                  pagination
                />
              </View>
            </View>
          </View>
          {/* </ScrollView> */}
        </View>
      </View>
    </View>
  );

  const AnalogInputRoute = () => (
    <View
      style={{
        flex: 1,
        justifyContent: "flex-start",
        backgroundColor: "#F6F9F9",
      }}
    >
      {" "}
      <View style={[styles.card, { flexGrow: 1 }]}>
        <View
          style={[
            styles.shadowProp,
            {
              backgroundColor: "white",
            },
          ]}
        >
          <View
            style={{
              width: "100%",
              // flex: 1,
              backgroundColor: "#F6F9F9",
            }}
          >
            <View
              style={{
                borderWidth: 1,
                borderColor: "#e3e0e0",
              }}
            >
              <View>
                <DataTable
                  // title="Country"
                  columns={columnsAnalog}
                  data={AnalogInput}
                  customStyles={customStyles}
                  pagination
                />
              </View>
            </View>
          </View>
          {/* </ScrollView> */}
        </View>
      </View>
    </View>
  );
  const DigitalInputRoute = () => (
    <View
      style={{
        flex: 1,
        justifyContent: "flex-start",
        backgroundColor: "#F6F9F9",
      }}
    >
      {" "}
      <View style={[styles.card, { flexGrow: 1 }]}>
        <View
          style={[
            styles.shadowProp,
            {
              backgroundColor: "white",
            },
          ]}
        >
          <View
            style={{
              width: "100%",
              // flex: 1,
              backgroundColor: "#F6F9F9",
            }}
          >
            <View
              style={{
                borderWidth: 1,
                borderColor: "#e3e0e0",
              }}
            >
              <View>
                <DataTable
                  // title="Country"
                  columns={columnDigitalInput}
                  data={DigitalInput}
                  customStyles={customStyles}
                  pagination
                />
              </View>
            </View>
          </View>
          {/* </ScrollView> */}
        </View>
      </View>
    </View>
  );

  const renderScene = SceneMap({
    DigitalOutput: DigitalOutputRoute,
    DigitalInput: DigitalInputRoute,
    AnalogInput: AnalogInputRoute,
  });
  const layout = useWindowDimensions();

  const [index, setIndex] = React.useState(0);
  const [routes] = React.useState([
    { key: "DigitalOutput", title: "Digital Output" },

    { key: "DigitalInput", title: "Digital Input" },
    { key: "AnalogInput", title: "Analog Input" },
  ]);

  const getAdminData = async () => {
    let jsonValue = await AsyncStorage.getItem("userData");
    // console.log("jsonValue:", jsonValue);
    if (jsonValue != null) {
      var userDetailsresponse = JSON.parse(jsonValue);
      // console.log("userDetailsresponse==>", userDetailsresponse);

      setloginId(userDetailsresponse.id);
      setEmpCode(userDetailsresponse.employee_code);
      // setProjectCode(userDetailsresponse.project_selected[0].project_code)
    }
  };
  // Country Get Api


  const renderOmegaIdListItems = (item: any) => {
    return (
      <View style={styles.item}>
        {OmegaIdData.length > 0 && (
          <Text style={styles.textItem}>{item.device_name}</Text>
        )}
        {/* {isAdd == false && (
          <Text style={styles.textItem}>{item.device_name}</Text>
        )} */}
        {item.id === OmegavalueId && (
          <Icon style={styles.icon} color="black" name="Safety" size={20} />
        )}
      </View>
    );
  };
  const renderProjectTypeListItems = (item: any) => {
    return (
      <View style={styles.item}>
        {isAdd == true && <Text style={styles.textItem}>{item.type}</Text>}
        {isAdd == false && <Text style={styles.textItem}>{item.type}</Text>}
        {item.id === ProjectTypevalueId && (
          <Icon style={styles.icon} color="black" name="Safety" size={20} />
        )}
      </View>
    );
  };
  const CheckEnteredData = async () => {
    setProjectIDEntered(true);
    setProjectNameEntered(true);

    setContractorNameEntered(true);
    setProjectDetailsEntered(true);
    setClientNameEntered(true);
    // console.log("check nenter data");

    //setOmegaId(OmegaIds);
    if (
      isProjectIDValid &&
      // isProjectNameValid &&

      isContractorNameValid &&
      isClientNameValid &&
      isProjectDetailsValid &&
      OmegavalueId &&
      ProjectName
    ) {
      // console.log("success");
      createProjectProfileData();
      setImageError("");
      setProjectIDError("");
      // setProjectNameError("");
      setProjectTypeError("");
      setContractorError("");
      setClientnameError("");
      setProjectDetailsError("");
    } else {
      // console.log("error ELSE");
      if (ProjectID == "") {
        setProjectIDError("Please enter Project Id");
        return;
      }
      if (ProjectName == "") {
        setProjectNameError("");
        setissuccessVisible(true);
        setresponsestatus("failed");
        setPopupMessage("Project Name is not get yet");
        return false;
      }

      //project type
      if (ProjectTypevalueId == "") {
        setProjectTypeError("Please select Project Type");
        return;
      }

      //Contractor name
      if (ContractorName == "") {
        setContractorError("Please enter Contractor name ");
        return;
      }
      //omega id
      // if (OmegavalueId == "") {
      //   setOmegaError("Please select Omega Type");
      // }

      //  //Pro id
      //  if (OmegavalueId == "") {
      //   setOmegaError("Please select Omega Type");
      // }

      //client name
      if (ClientName == "") {
        setClientnameError("Please select Client name");
        return;
      }

      //Project details
      if (ProjectDetails == "") {
        setProjectDetailsError("Please select Project details");
        return;
      }
      createProjectProfileData();
    }
  };

  // project type Get Api
  const getProjectTypeList = async () => {
    setIsLoading(true);
    try {
      var ProjectTypeData = JSON.stringify({
        module: "project_type",
        relation: [],
      });
      const ProjectTypeList = await postData(
        ProjectTypeData,
        "masters/getMasterList"
      );
      setIsLoading(false);
      var code = ProjectTypeList.code;
      if (code == 1) {
        // console.log("project list api====>", ProjectTypeList.data);
        setProjectTypeData(ProjectTypeList.data);
      } else {
        setIsLoading(false);
        alert(ProjectTypeList.message);
      }
    } catch (error) {
      setIsLoading(false);
      // console.log(error);
    }
  };

  //Project Profile Add Api
  const createProjectProfileData = async () => {
    setIsLoading(true);
    console.log("ProjectID==>", ProjectID)
    if (!basedOnSelected) {
      setProjectNameError("");
      setissuccessVisible(true);
      setresponsestatus("failed");
      setPopupMessage("Please select based on");
      return false;
    }

    try {
      console.log('ProjectIdNew', ProjectIdNew);

      var ProjectMasterData = JSON.stringify({
        modelName: "project",
        id: ProjectIdNew,
        inputData: {
          profile_pic: projectImage,
          project_type_id: ProjectTypevalueId,
          project_code: ProjectID,
          project_name: ProjectName,
          client: ClientName,
          contractor: ContractorName,
          project_details: ProjectDetails,
          omega_id: OmegaIdValue,
          active: 1,
          user_id: loginId,
          based_on: isAssignchecked,
        },
      });
      console.log("ProjectIdOmega", ProjectIdOmega),

        console.log("Project payload===", ProjectMasterData);
      const FaarmerProfileCreateResponse = await postData(
        ProjectMasterData,
        "masters/profileCreationAndUpdation"
      );

      // setIsLoading(false);

      var code = FaarmerProfileCreateResponse.code;
      if (code == 1) {
        if (tempArray.length == 0) {
          setPopupMessage("Record Created Successfully");
          setissuccessVisible(true);
          setresponsestatus("success");
          navigation.navigate("ProjectProfile");
        } else {
          updateProjectWiseDashboardList(FaarmerProfileCreateResponse.project_id)
        }
        // setPopupMessage("Record Created Successfully");
        // setissuccessVisible(true);
        // setresponsestatus("success");
        // // setIsVisible(false);
        // // console.log("Project profile added", FaarmerProfileCreateResponse.data);
        // navigation.navigate("ProjectProfile");
      } else {
        setIsLoading(false);
        setissuccessVisible(true);
        setresponsestatus("failed");
        setPopupMessage(FaarmerProfileCreateResponse.message);
      }
    } catch (error) {
      setIsLoading(false);
      // console.log(error);
    }
  };

  //Change dashboard names
  const updateProjectWiseDashboardList = async (projectId) => {
    setIsLoading(true);
    try {
      var dashboardNameBody = JSON.stringify({
        dashboard_name_array: tempArray
      });
      console.log("dashboardNameBody===>", dashboardNameBody);
      const updateProjectWiseDashboardResponse = await postData(
        dashboardNameBody,
        "masters/updateProjectWiseDashboardListNew/" + projectId + "/" + loginId
      );

      setIsLoading(false);

      var code = updateProjectWiseDashboardResponse.code;
      if (code == 1) {
        setPopupMessage("Record Created Successfully");
        setissuccessVisible(true);
        setresponsestatus("success");
        navigation.navigate("ProjectProfile");
      }
    } catch (error) {
      setIsLoading(false);
      // console.log(error);
    }
  };

  const [tempArray, setTempArray] = useState<any>([]);
  const customDashboardNameStyles = {
    rows: {
      style: {
        // maxWidth: 50,
        minHeight: "25px", // override the row height
        // width: "800px",
      },
    },
    headRow: {
      style: {
        borderTopStyle: "solid",
        borderTopWidth: "1px",
        backgroundColor: "#319ef0a3",
        minHeight: "40px",
        fontSize: 16,
        fontWeight: "600",
        // width: "800px",
      },
    },
    headCells: {
      style: {
        // paddingLeft: '8px', // override the cell padding for head cells
        // paddingRight: '8px',
        justifyContent: "center",
        minHeight: "20px",

        // borderRightWidth: 2,
        "&:not(:last-of-type)": {
          borderRightStyle: "solid",
          borderRightWidth: "1px",
          // fontSize: 16,
          // fontWeight: '600',
          // borderRightColor: defaultThemes.default.divider.default,
        },
      },
    },
    cells: {
      style: {
        paddingLeft: "8px", // override the cell padding for data cells
        paddingRight: "8px",
        justifyContent: "center",

        "&:not(:last-of-type)": {
          borderRightStyle: "solid",
          borderRightWidth: "1px",
          fontSize: 16,
          fontWeight: "400",
          // borderRightColor: defaultThemes.default.divider.default,
        },
      },
    },
  };
  const columnsDashboardName = [
    {
      name: "Sr. No",
      selector: (row, index) => row?.sr_index || index + 1, // Auto-increment if sr_index is missing
      sortable: true
    },
    {
      name: "Dashboard Name",
      // selector: row => !editInput ? row.project_name : <TextInput value='helllo'></TextInput>,
      selector: (row) => row.dashboard_name,
      sortable: true,
    },
    {
      name: "New Dashboard Name",
      // selector: (row) => row.new_dashboard_name,
      selector: row => (
        <View style={{ flexDirection: 'row', alignItems: 'center', marginLeft: row?.type == 4 ? 35 : 0 }}>
          <TextInput
            value={row.new_dashboard_name}
            style={styles.input}
            placeholder="Enter new name"
            onChangeText={(text) => {
              var dashboard_report_id = row.id
              var new_dashboard_name = text
              console.log("text", "" + text)
              row.new_dashboard_name = text
              let data = tempArray.find(object => object['dashboard_report_id'] === row.id);
              // console.log("dataarray==>", data)
              if (data !== undefined) {
                const newState = tempArray.map(obj => {
                  if (obj?.dashboard_report_id === row.id) {
                    return { ...obj, new_dashboard_name: text };
                  }
                  return obj;
                })
                // console.log("newState", newState)
                setTempArray(newState)
              } else {
                let data = { dashboard_report_id, new_dashboard_name }
                // console.log("data", JSON.stringify(data))
                tempArray.push(data)
                // console.log("tempArray", JSON.stringify(tempArray))
              }
              console.log("tempArray", JSON.stringify(tempArray))
            }}
          />
          {row?.type == 4 && (
            <TouchableOpacity onPress={() => {
              setStatusTableVisible(true)
              setTempRowData(row)
              if (row?.status !== null) {
                setStatusData(row?.status)
              }
            }} style={{ marginLeft: 10 }}>
              <IconLoc name="remove-red-eye" size={24} color={"black"} />
            </TouchableOpacity>
          )}
        </View>
      ),
      sortable: true,
    },
  ];

  const columnsStatusName = [
    {
      name: "Sr. No",
      selector: (row: any, index: any) => index + 1,
    },
    {
      name: "Status",
      selector: (row: any) => row.old_status,
      sortable: true,
    },
    {
      name: "Updated Status",
      selector: (row: any, rowIndex: any) => (
        <>
          <TextInput
            value={row?.new_status}
            style={styles.input}
            placeholder="Enter Updated Status"
            onChangeText={(text) => {
              const updatedData = [...statusData];
              updatedData[rowIndex] = { ...updatedData[rowIndex], new_status: text };
              setStatusData(updatedData);
            }}
          />
        </>
      ),
      sortable: true,
    },
  ];

  const handleSaveStatus = () => {
    const dashboardReportId = tempRowData?.id;
    const newDashboardName = tempRowData?.new_dashboard_name;
    const statusDataCopy = [...statusData];
    const dataIndex = tempArray.findIndex((obj: any) => obj.dashboard_report_id === dashboardReportId);
    if (dataIndex !== -1) {
      const updatedTempArray : any = tempArray.map((obj: any, index: any) =>
        index === dataIndex ? { ...obj, status: statusDataCopy } : obj
      );
      setTempArray(updatedTempArray);
    } else {
      const newData : any = { dashboard_report_id: dashboardReportId, new_dashboard_name: newDashboardName, status: statusDataCopy };
      setTempArray([...tempArray, newData]);
    }
    const updatedDashboardName : any = [...dashboardName];
    const itemIndex = updatedDashboardName.findIndex((item : any) => item?.id === dashboardReportId);
    if (itemIndex !== -1) {
      updatedDashboardName[itemIndex] = {
        ...updatedDashboardName[itemIndex],
        status: statusDataCopy,
      };
    } else {
      updatedDashboardName.push({
        ...tempRowData,
        status: statusDataCopy,
      });
    }
    setDashboardName(updatedDashboardName);
    setStatusData([...originalStatusData]);
    setStatusTableVisible(false);
  };

  const handleCancel = () => {
    setStatusData([...originalStatusData])
    setStatusTableVisible(false)
  }

  const popUp = () => {
    return (
      <View>
        <Modal visible={statusTableVisible} transparent={true}>
          <View style={{ flex: 1, backgroundColor: "rgba(0,0,0,0.5)", alignItems: "center", justifyContent: "center" }}>
            <View style={{ width: "60%", backgroundColor: "#fff", padding: "3%" }}>
              <View
                style={[
                  styles.shadowProp,
                  {
                    backgroundColor: "white",
                    borderWidth: 1,
                    borderColor: "#e3e0e0",

                  },
                ]}
              >
                <DataTable
                  columns={columnsStatusName}
                  data={statusData}
                  customStyles={customDashboardNameStyles}
                  pagination
                  paginationPerPage={5}
                  paginationRowsPerPageOptions={[5, 10, 15, 20, 25]}
                />
              </View>
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "center",
                  marginTop: "3%"
                }}
              >
                <TouchableOpacity style={isMobile ? {
                  alignItems: "center", backgroundColor: "#006EB9", padding: 10, borderWidth: 1,
                  borderColor: "#006EB9",
                  width: "30%",
                  marginRight: 10,
                } : {
                  alignItems: "center",
                  backgroundColor: "#006EB9",
                  padding: 10,
                  borderWidth: 1,
                  borderColor: "#006EB9",
                  width: "15%",
                  marginRight: 10,
                }}
                  onPress={() => { handleSaveStatus() }}
                >
                  <Text style={styles.btnText}>Save</Text>
                </TouchableOpacity>

                <TouchableOpacity
                  style={isMobile ? {
                    alignItems: "center",
                    backgroundColor: "#006EB9",
                    padding: 10,
                    borderWidth: 1,
                    borderColor: "#006EB9",
                    width: "30%"
                  } : {
                    alignItems: "center",
                    backgroundColor: "#006EB9",
                    padding: 10,
                    borderWidth: 1,
                    borderColor: "#006EB9",
                    width: "15%"
                  }}
                  onPress={() => { handleCancel() }}
                >
                  <Text style={styles.btnText}>Cancel</Text>
                </TouchableOpacity>
              </View>
            </View>
          </View>
        </Modal>
      </View>
    )
  }

  return (
    <View style={styles.container}>
      <SuccessPopup
        visible={issuccessVisible}
        PopupMessage={PopupMessage}
        Responsestatus={responsestatus}
        onChange={() => setissuccessVisible(false)}
      />
      {popUp()}
      <View style={{ flexDirection: "row", width: "100%", marginTop: "5%" }}>
        {" "}
        <View style={{ flex: 4, margin: 10 }}>
          <Text style={styles.lableText}>
            Profile {">"}{" "}
            <Text onPress={() => navigation.navigate("ProjectProfile")}>
              {" "}
              Project Profile{" "}
            </Text>{" "}
            {">"} Create Project Profile
          </Text>
        </View>
        {/* <View style={{ flex: 1 }}>
          <TouchableOpacity
            style={styles.addCountry}
            onPress={() => setIsVisible(true)}
          >
            <Text style={styles.btnText}>
              <Icon name="plus" size={15} color="#fff" /> Add Users
            </Text>
          </TouchableOpacity>
        </View> */}
      </View>
      {/* <ScrollView
        style={[styles.card, styles.shadowProp]}
        contentContainerStyle={{ flexGrow: 1 }}
      > */}
      <View style={[styles.card, { flexGrow: 1, }]}>
        <View
          style={isMobile ? [
            styles.shadowProp,
            {
              backgroundColor: "white",
              borderRadius: 8,
              paddingVertical: 0,
              paddingHorizontal: 25,
              justifyContent: "center",
              alignContent: "center",
              alignItems: "center"
            },
          ] : [
            styles.shadowProp,
            {
              backgroundColor: "white",
              borderRadius: 8,
              paddingVertical: 0,
              paddingHorizontal: 25,
              justifyContent: "center",
              alignContent: "center",
              alignItems: "center"
            },
          ]}
        >
          <View
            style={isMobile ? {
              // flex: 1,
              justifyContent: "center",
              alignItems: "center",
              // flexDirection: "row",
              width: "100%"
            } : {
              flex: 1,
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "row",
              width: "100%"
            }}
          >
            <View style={isMobile ? { marginLeft: "10%" } : { flex: 1, marginLeft: "10%" }}>
              <View
                style={{

                  // backgroundColor: "#F2F2F2",
                  borderRadius: 10,
                  padding: 10,
                  margin: 7,
                }}
              >
                <View
                  style={isMobile ? { flex: 1, alignItems: "flex-start", padding: 10, marginLeft: 10 } : { flex: 1, alignItems: "flex-start", padding: 10 }}
                >
                  {/* <Button title='Pick an image from camera roll'   color="#000000"
                   onPress={pickImage} /> */}
                  <TouchableOpacity
                    style={{
                      backgroundColor: projectImage ? "" : "grey",
                      padding: 10,
                    }}
                    onPress={pickImage}
                  >
                    <Image
                      source={
                        projectImage
                          ? { uri: projectImage }
                          : require("../../../assets/images/projectImg.png")
                      }
                      style={isMobile ? { width: 80, height: 80 } : { width: 100, height: 100 }}
                    />
                  </TouchableOpacity>
                </View>
                <Text style={[styles.error_text]}>{imageError}</Text>

                <View style={isMobile ? { marginLeft: -45, marginTop: -15 } : null}>
                  <Text style={styles.lableText}>Project Code</Text>
                  <View style={isMobile ? { flexDirection: 'row', width: "115%", } : { flexDirection: 'row', }}>
                    <TextInput
                      style={styles.input}
                      placeholder="Enter Project Code"
                      value={ProjectID}
                      onChangeText={(value) => {
                        setProjectIDValid(false);
                        setProjectID(value);
                        if (value.length == 0) {
                          setProjectIDError("Please enter Project Code");
                        } else if (!alpha_numeric.test(value)) {
                          setProjectIDError("Please enter alphanumeric only");
                        } else {
                          setProjectIDValid(true);
                          setProjectIDError("");
                        }
                      }}
                    />
                    <View style={{ marginLeft: 0, padding: 10 }}>
                      {isOmegaLoading ? (
                        <ActivityIndicator
                          //visibility of Overlay Loading Spinner
                          color="#1DA1F2"
                          visible={isLoading}
                          //Text with the Spinner
                          textContent={"Loading..."}
                          size={40}
                        //  //Text style of the Spinner Text
                        //  textStyle={styles.spinnerTextStyle}
                        />
                      ) : (
                        <IconRefresh
                          name="refresh"
                          size={30}
                          onPress={() => getOmegaIdList()}
                        />
                      )}
                    </View>

                  </View>

                  {/* <View> */}
                  <Text style={[styles.error_text]}>{ProjectIdError}</Text>
                  {/* </View> */}

                </View>

                <View style={isMobile ? { marginLeft: -45, marginTop: -15 } : null}>
                  <Text style={styles.lableText}>Project Type</Text>
                  <View>
                    <Dropdown
                      ref={dropdownRefOmegaiD}
                      style={isMobile ? {
                        borderColor: "#c3c3c3",
                        width: "110%",
                        borderWidth: 1,
                        borderRadius: 2,
                        height: 40,
                        outlineStyle: "none",
                        padding: 10,
                        marginTop: 5,
                        marginBottom: 5,
                      } : styles.dropdown}
                      placeholderStyle={styles.placeholderStyle}
                      selectedTextStyle={styles.selectedTextStyle}
                      inputSearchStyle={styles.inputSearchStyle}
                      iconStyle={styles.iconStyle}
                      data={ProjectTypeData}
                      search
                      maxHeight={300}
                      labelField="type"
                      valueField="type"
                      placeholder={!isFocus ? "Select Project type" : "...."}
                      searchPlaceholder="Search..."
                      value={OmegaIdValue}
                      onChange={(item) => {
                        setProjectTypeValue(item.type);
                        setProjectTypeValueiD(item.id);
                        setProjectTypeError("");
                        // console.log("project id value---", item.id);
                      }}
                      renderItem={renderProjectTypeListItems}
                    />
                    <Text style={[styles.error_text]}>{ProjectTypeError}</Text>
                  </View>
                </View>

                <View style={isMobile ? { marginLeft: -45, marginTop: -15 } : null}>
                  <Text style={styles.lableText}>Contractor Name</Text>
                  <TextInput
                    style={isMobile ? {
                      borderColor: "#c3c3c3",
                      width: "110%",
                      borderWidth: 1,
                      borderRadius: 4,
                      padding: 10,
                      marginTop: 4,
                      marginBottom: 6,
                    } : styles.input}
                    placeholder="Enter Contractor name"
                    value={ContractorName}
                    onChangeText={(value) => {
                      setContractorNameValid(false);
                      setContractorName(value);
                      if (value.length == 0) {
                        setContractorError("Please enter Contractor name");
                      } else if (!alpha_pattern.test(value)) {
                        setContractorError("Please enter character only");
                      } else {
                        setContractorNameValid(true);
                        setContractorError("");
                      }
                    }}
                  />
                  <Text style={[styles.error_text]}>{ContractorError}</Text>
                </View>

                <View style={isMobile ? { marginLeft: -45, marginTop: -15 } : null}>
                  <Text style={styles.lableText}>Omega name</Text>
                  <View style={{ flexDirection: "row" }}>
                    <View style={{ width: "100%" }}>
                      <Dropdown
                        ref={dropdownRefOmegaiD}
                        style={isMobile ? {
                          borderColor: "#c3c3c3",
                          width: "110%",
                          borderWidth: 1,
                          borderRadius: 2,
                          height: 40,
                          outlineStyle: "none",

                          padding: 10,
                          marginTop: 5,
                          marginBottom: 5,
                        } : styles.dropdown}
                        placeholderStyle={styles.placeholderStyle}
                        selectedTextStyle={styles.selectedTextStyle}
                        inputSearchStyle={styles.inputSearchStyle}
                        iconStyle={styles.iconStyle}
                        data={OmegaIdData}
                        search
                        maxHeight={300}
                        labelField="device_name"
                        valueField="device_name"
                        placeholder={!isFocus ? "Select Omega name" : "...."}
                        searchPlaceholder="Search..."
                        value={OmegaIdValue}
                        onChange={(item) => {
                          setOmegaIdValue(item.device_name);
                          setOmegaValueiD(item.id);
                          setOmegaError("");
                          getOmegaDetails(item.id);
                          setLatitude(item.lat);
                          setLongitude(item.long);
                          console.log("project_id", item.project_id)
                          console.log("project_name", item.project_name)
                          setProjectIdOmega(item.project_id);
                          //setProjectName(item.project_name);
                          // console.log("selected omega id value---", item);
                        }}
                        renderItem={renderOmegaIdListItems}
                      />
                      {/* <Text style={[styles.error_text]}>{OmegaError}</Text> */}
                    </View>

                  </View>
                </View>

              </View>
            </View>
            <View
              style={isMobile ? {
                // flex: 1,
                // marginTop: 53,
                // backgroundColor: "#F2F2F2",
                borderRadius: 10,
                padding: 10,
                // margin: 150,
                marginBottom: "0%",
              } : {
                flex: 1,
                marginTop: 53,
                // backgroundColor: "#F2F2F2",
                borderRadius: 10,
                padding: 10,
                margin: 150,
                marginBottom: "0%",
              }}
            >
              <View style={isMobile ? { marginLeft: -10, marginTop: -30 } : null}>
                <Text style={styles.lableText}>Project Name</Text>
                <TextInput
                  style={isMobile ? {
                    borderColor: "#c3c3c3",
                    width: "105%",
                    borderWidth: 1,
                    borderRadius: 4,
                    padding: 10,
                    marginTop: 4,
                    marginBottom: 6,
                    backgroundColor: "#DEDEDE"
                  } : [styles.input, { backgroundColor: "#DEDEDE" }]}
                  // placeholder="Enter Project name"
                  value={ProjectName}
                  editable={false}
                // onChangeText={(value) => {
                //   setProjectNameValid(false);
                //   setProjectName(value);
                //   if (value.length == 0) { setProjectNameError("Please enter Project  name") }
                //   else if (!alpha_pattern.test(value)) {
                //     setProjectNameError("Please enter character only");
                //   }
                //   else {
                //     setProjectNameValid(true);
                //     setProjectNameError('')
                //   }
                // }}
                />
                {/* <View> */}
                <Text style={[styles.error_text]}>{ProjectNameError}</Text>
                {/* </View> */}
              </View>

              <View style={isMobile ? { marginLeft: -15, marginTop: -10 } : null} >
                <Text style={styles.lableText}>Client Name</Text>
                <TextInput
                  style={isMobile ? {
                    borderColor: "#c3c3c3",
                    width: "105%",
                    borderWidth: 1,
                    borderRadius: 4,
                    padding: 10,
                    marginTop: 4,
                    marginBottom: 6,
                  } : styles.input}
                  placeholder="Enter Client name"
                  value={ClientName}
                  onChangeText={(value) => {
                    setClientNameValid(false);
                    setClientName(value);
                    if (value.length == 0) {
                      setClientnameError("Please enter Client name");
                    } else if (!alpha_pattern.test(value)) {
                      setClientnameError("Please enter character only");
                    } else {
                      setClientNameValid(true);
                      setClientnameError("");
                    }
                  }}
                />
                {/* <View> */}
                <Text style={[styles.error_text]}>{ClientnameError}</Text>
                {/* </View> */}
              </View>

              <View style={isMobile ? { marginLeft: -15, marginTop: -10 } : null}>
                <Text style={styles.lableText}>Project Details</Text>
                <TextInput
                  style={isMobile ? {
                    borderColor: "#c3c3c3",
                    width: "105%",
                    borderWidth: 1,
                    borderRadius: 4,
                    padding: 10,
                    marginTop: 4,
                    marginBottom: 6,
                  } : styles.input}
                  placeholder="Enter Projects details"
                  value={ProjectDetails}
                  onChangeText={(value) => {
                    setProjectDetailsValid(false);
                    setProjectDetails(value);
                    if (value.length == 0) {
                      setProjectDetailsError("Please enter Project details ");
                    } else if (!alpha_numeric.test(value)) {
                      setProjectDetailsError("Please enter alpha numeric only");
                    } else {
                      setProjectDetailsValid(true);
                      setProjectDetailsError("");
                    }
                  }}
                />
                {/* <View> */}
                <Text style={[styles.error_text]}>{ProjectDetailsError}</Text>

              </View>

              <View style={isMobile ? { marginLeft: -15, marginTop: -10 } : null}>
                <Text style={styles.lableText}>Location </Text>
                <View style={{ flexDirection: "row" }}>
                  <TextInput
                    placeholder="Latitude"
                    style={isMobile ? {
                      borderColor: "#c3c3c3",
                      width: "100%",
                      borderWidth: 1,
                      borderRadius: 4,
                      marginRight: 5,
                      padding: 10,
                      marginTop: 4,
                      marginBottom: 6,
                      backgroundColor: "#DEDEDE"
                    } : [
                      styles.inputLocation,
                      { backgroundColor: "#DEDEDE" },
                    ]}
                    placeholder="Lat"
                    maxLength={10}
                    editable={false}
                    keyboardType={"numeric"}
                    value={Lat}
                  />
                  <TextInput
                    placeholder="Longitude"
                    style={isMobile ? {
                      borderColor: "#c3c3c3",
                      width: "100%",
                      borderWidth: 1,
                      borderRadius: 4,
                      marginRight: 5,
                      padding: 10,
                      marginTop: 4,
                      marginBottom: 6,
                      backgroundColor: "#DEDEDE"
                    } : [
                      styles.inputLocation,
                      { backgroundColor: "#DEDEDE" },
                    ]}
                    placeholder="Long"
                    maxLength={10}
                    keyboardType={"numeric"}
                    editable={false}
                    keyboardType={"numeric"}
                    value={Long}
                  />
                  <IconLoc
                    name="my-location"
                    size={20}
                    style={{ margin: 10 }}
                  />
                </View>
              </View>
              <View style={isMobile ? { marginLeft: -15, marginTop: -10 } : null}>
                <Text style={styles.lableText}>Based On</Text>
                <View style={isMobile ? { flexDirection: "row", marginTop: 5, alignItems: 'center' } : { flexDirection: "row", marginTop: 10, alignItems: 'center' }}>
                  <Text>Omega</Text>
                  <RadioButton
                    value="1"
                    status={isAssignchecked === 1 ? "checked" : "unchecked"}
                    onPress={() => { setAssignChecked(1); setBasedOnSelected(true); }}
                  />
                  <Text>IMEI</Text>
                  <RadioButton
                    value="2"
                    status={isAssignchecked === 2 ? "checked" : "unchecked"}
                    onPress={() => { setAssignChecked(2); setBasedOnSelected(true); }}
                  />
                  <Text>Both</Text>
                  <RadioButton
                    value="3"
                    status={isAssignchecked === 3 ? "checked" : "unchecked"}
                    onPress={() => { setAssignChecked(3); setBasedOnSelected(true); }}
                  />
                </View>
                {/* {!basedOnSelected ? null : <Text style={{ color: 'red' }}>Please select an option</Text>} */}
              </View>
            </View>
          </View>

          {shouldShow ? null : (


            <View style={isMobile ? { marginTop: 20, width: "80%", alignSelf: "center" } : { marginTop: 20, width: "40%", alignSelf: "center" }}>
              {DataRecord.length > 0 &&
                <TabView
                  navigationState={{ index, routes }}
                  renderScene={renderScene}
                  onIndexChange={setIndex}
                  pressColor="#b3bb"
                  activeColor={"red"}
                  inactiveColor={"white"}
                  initialLayout={{ width: layout.width }}
                  // renderLabel={({ route, color }) => (
                  //   <Text style={{ color: 'black', margin: 8 }}>
                  //     {route.title}
                  //   </Text>
                  // )}
                  renderTabBar={(props) => (
                    <TabBar
                      {...props}
                      indicatorStyle={{ backgroundColor: "yellow", height: 5 }}
                      renderLabel={({ route, color, focused }) => (
                        <Text style={{ color: "white", margin: 8 }}>
                          {route.title}
                        </Text>
                      )}
                      style={{ backgroundColor: "#2c7bbe" }}
                    />
                  )}

                // <-- add this line
                />
              }
            </View>
          )}
          <View
            style={{
              justifyContent: "center",
              alignContent: "center",
              alignItems: "center",
              backgroundColor: "#F6F9F9",
              width: "60%",
              marginBottom: 50
            }}
          >
            {" "}
            <View style={[styles.card, { flexGrow: 1 }]}>
              <View
                style={[
                  styles.shadowProp,
                  {
                    backgroundColor: "white",
                  },
                ]}
              >
                <View
                  style={isMobile ? {
                    width: "180%",
                    // flex: 1,
                    backgroundColor: "#F6F9F9",
                    marginLeft: -57
                  } : {
                    width: "100%",
                    // flex: 1,
                    backgroundColor: "#F6F9F9",
                  }}
                >
                  {tableVisible && (
                    <View
                      style={{
                        borderWidth: 1,
                        borderColor: "#e3e0e0",
                      }}
                    >
                      <View>
                        <DataTable
                          // title="Country"
                          columns={columnsDashboardName}
                          data={dashboardName}
                          customStyles={customDashboardNameStyles}
                          pagination
                          paginationPerPage={5}
                          paginationRowsPerPageOptions={[5, 10, 15, 20, 25]}
                        />
                      </View>
                    </View>
                  )}
                </View>
                {/* </ScrollView> */}
              </View>
            </View>
          </View>
          {/* </ScrollView> */}
          <View
            style={{
              flexDirection: "row",
              justifyContent: "center",
              marginHorizontal: 20,
              marginBottom: 20,
            }}
          >
            <TouchableOpacity
              style={isMobile ? {
                alignItems: "center",
                backgroundColor: "#006EB9",
                padding: 10,
                borderWidth: 1,
                borderColor: "#006EB9",
                width: "80%",
                marginRight: 10,
              } : styles.saveBtn}
              onPress={() => CheckEnteredData()}
            >
              <Text style={styles.btnText}>Save</Text>
            </TouchableOpacity>

            <TouchableOpacity
              style={isMobile ? {
                alignItems: "center",
                backgroundColor: "#006EB9",
                padding: 10,
                borderWidth: 1,
                borderColor: "#006EB9",
                width: "80%",
                marginRight: 10,
              } : styles.saveBtn}
              onPress={() => navigation.navigate("ProjectProfile")}
            >
              <Text style={styles.btnText}>Cancel</Text>
            </TouchableOpacity>
          </View>
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    padding: 20,
    // marginTop: 150,
  },
  genderNames: {
    margin: 7,
  },
  statusNames: {
    margin: 7,
  },
  containeralt: {
    // alignItems: "center",
    backgroundColor: "white",
    borderColor: "#eee",
    borderRadius: 10,
    borderWidth: 1,
    // justifyContent: "center",
    height: "100%",
    marginRight: "10%",
    marginTop: "0%",
    margin: "auto",
    padding: 30,
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
    // marginRight: 10,
    // marginRight: 1,
    width: "70%",
  },
  inputLocation: {
    borderColor: "#c3c3c3",
    width: "40%",
    borderWidth: 1,
    borderRadius: 4,
    marginRight: 5,
    padding: 10,
    marginTop: 4,
    marginBottom: 6,
  },
  input: {
    borderColor: "#c3c3c3",
    width: "100%",
    borderWidth: 1,
    borderRadius: 4,

    padding: 10,
    marginTop: 4,
    marginBottom: 6,
  },
  title: {
    fontSize: 20,
    fontWeight: "bold",
  },
  addCountry: {
    alignItems: "center",
    backgroundColor: "#006EB9",
    padding: 10,
    borderWidth: 1,
    borderColor: "#006EB9",
    width: "70%",
    // marginTop: 20,
  },
  saveBtn: {
    alignItems: "center",
    backgroundColor: "#006EB9",
    padding: 10,
    borderWidth: 1,
    borderColor: "#006EB9",
    width: "100%",
    marginRight: 10,
  },
  btnText: {
    color: "#fff",
    fontWeight: "600",
  },
  datePicker: {
    justifyContent: "center",
    alignItems: "flex-start",
    width: 320,
    height: 260,
    display: "flex",
  },
  card: {
    // backgroundColor: "white",
    // borderRadius: 8,
    // paddingVertical: 45,
    // paddingHorizontal: 25,
    // width: "100%",
    // flex: 1,
    // marginVertical: 10,
    // paddingBottom: "48%",

    width: "100%",
    flex: 1,
    marginVertical: 0,
  },
  shadowProp: {
    shadowColor: "#171717",
    shadowOffset: { width: -2, height: 4 },
    shadowOpacity: 0.2,
    shadowRadius: 25,
  },

  link: {
    marginTop: 15,
    paddingVertical: 15,
  },
  error_text: {
    color: "red",
    fontSize: 14,
    fontWeight: "600",
    width: "70%",
    marginLeft: 0,
    marginBottom: 10,
  },
  linkText: {
    fontSize: 14,
    color: "#2e78b7",
  },

  // DropDown CSS
  dropdown: {
    borderColor: "#c3c3c3",
    width: "70%",
    borderWidth: 1,
    borderRadius: 2,
    height: 40,
    outlineStyle: "none",

    padding: 10,
    marginTop: 5,
    marginBottom: 5,
    // borderRadius: 10,
    // padding: 12,
    // shadowColor: "#000",
    // shadowOffset: {
    //   width: 0,
    //   height: 1,
    // },
    // shadowOpacity: 0.2,
    // shadowRadius: 1.41,

    // elevation: 2,
  },
  icon: {
    marginRight: 5,
  },
  item: {
    padding: 9,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  textItem: {
    flex: 1,
    fontSize: 16,
  },
  placeholderStyle: {
    fontSize: 16,
  },
  selectedTextStyle: {
    fontSize: 16,
  },
  iconStyle: {
    width: 20,
    height: 20,
  },
  inputSearchStyle: {
    height: 40,
    fontSize: 16,
    outlineStyle: "none",
  },
  lableText: {
    color: "#484848",
    fontWeight: "700",
    marginTop: 18,
    // fontSize: 21,
  },
});
