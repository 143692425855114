import AsyncStorage from "@react-native-async-storage/async-storage";
import { useFocusEffect } from "@react-navigation/native";
import React, { Children, Component, useEffect, useState } from "react";
import { Tooltip, Text as TextTool } from "react-native-elements";
import IconTimer from "react-native-vector-icons/Entypo";
import AntDesign from "react-native-vector-icons/AntDesign";
import {
  StyleSheet,
  View,
  Text,
  FlatList,
  Image,
  Dimensions,
  TouchableOpacity,
  Pressable,
  ActivityIndicator,
  Modal,
  TextInput,
  useWindowDimensions,
} from "react-native";
import { CountdownCircleTimer } from "react-native-countdown-circle-timer";

import { RadioButton } from "react-native-paper";
import Icon from "react-native-vector-icons/FontAwesome5";
import { useSelector } from "react-redux";
import IconCross from "react-native-vector-icons/Entypo";
import {
  MenuProvider,
  MenuContext,
  Menu,
  MenuOptions,
  MenuOption,
  MenuTrigger,
} from "react-native-popup-menu";
import { postData, baseUrlSocket } from "../../networking/api";
import moment, { duration, isMoment } from "moment";
import SuccessPopup from "../../components/SuccessPopup";
import { getMinutes } from "react-native-paper-dates/lib/typescript/Time/timeUtils";
import { Item } from "react-native-paper/lib/typescript/components/List/List";
import { ScrollView } from "react-native-gesture-handler";
import { projectTypeValueById } from "./getProjectTypeValue";

export default function ValveView({ navigation, route }) {
  const { OmegaId, OmegaName, basedOn, IMEIId, DeviceId } = route?.params;
  const [timeHr, settimeHr] = useState(0);
  const [timemin, settimemin] = useState(0);
  const [timesec, settimesec] = useState(0);
  var projectName = useSelector((state) => state.projectName);
  const [project_name, setProjectName] = useState("");
  const [isHovered, setIsHovered] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingSync, setIsLoadingSync] = useState(false);
  const [projectId, setProjectId] = useState("");
  //   const [animations, setAnimations] = useState(constructAnimations());
  const [ValveIsVisible, setValveIsVisible] = useState(false);
  const [isMeasureType, setMeasureType] = useState(2);
  const [isOnline, setIsOnline] = useState(false);
  const [valveDetails, setValveDetails] = useState([]);
  const [NewvalveDetails, setNewValveDetails] = useState([]);
  const [waterMeterDetails, setWaterMeterDetails] = useState([]);
  const [switchsDetails, setSwitchsDetails] = useState([]);
  const [analogDetails, setAnalogDetails] = useState([]);
  const [OmegaDetailsGrid, setOmegaDetailsGrid] = useState([]);
  const [valveamount, setvalveamount] = useState<Number>(0);
  const [OmegaIdValue, setOmegaIdValue] = useState(null);
  const [valveIndex, setValveIndex] = useState("");
  const [valveStatus, setvalveStatus] = useState("");
  const [valveName, setValveName] = useState("");
  const [valveState, setValveState] = useState("");
  const [selectedValveIndex, setSelectedValveIndex] = useState("");
  const [refreshflatlist, setrefreshflatlist] = useState(false);
  const [valveDetCount, setvalveDetCount] = useState(0);
  const [vNewIndex, setVNewIndex] = useState("");
  const [count1, setcount1] = useState(0);
  const [socketDetails, setSocketDetails] = useState([]);

  const [issuccessVisible, setissuccessVisible] = useState(false);
  const [responsestatus, setresponsestatus] = useState("");
  const [PopupMessage, setPopupMessage] = useState("");

  const [labelValues, setLabelValues] = useState([]);
  const [IMEIDeviceName, setIMEIDeviceName] = useState([]);
  const [hoveredIndex, setHoveredIndex] = useState(-1);
  //based-on
  const [BasedOn, setBasedOn] = useState(basedOn); //<number | null>(null);
  const [IMEIData, setIMEIData] = useState({});
  const [ValveIMEIData, setValveIMEIData] = useState([]);
  const [waterIMEIData, setWaterIMEIData] = useState([]);
  const [switchIMEIData, setSwitchIMEIData] = useState([]);
  const [pressureIMEIData, setPressureIMEIData] = useState([]);
  const [imeiTabData, setIMEITabData] = useState({});
  const [currentTime, setCurrentTime] = useState(new Date());
  const [initialTime] = useState(new Date());
  const [onlineCheckTime, setOnlineCheckTime] = useState(0);
  const [controlParameter, setControlParameter] = useState([]);
  const [water_by, setWaterBy] = useState(0);
  const [setpul, setSetpul] = useState(0);
  const [decpul, setDecpul] = useState(0);
  const [set_duration, setSetDuration] = useState<any>();
  const [stm, setStm] = useState("");
  const [remainingDuration, setRemainingDuration] = useState(0);
  const [isTimerCompleted, setIsTimerCompleted] = useState(false);
  const [IMEIData12, setIMEIData12] = useState(false);
  const [projectTypeValue, setProjectTypeValueData] = useState("IRRIGATION")

  // useEffect(() => {
  //   const fetchDataAndSetTimer = async () => {
  //     try {
  //       // console.log('Starting fetchDataAndSetTimer function');
  //       let projValue = await AsyncStorage.getItem("projectData");
  //       await getIMEITabsData(projValue);
  //       // console.log('getIMEITabsData completed',);
  //       const initialTime = moment(stm).format('HH:mm');
  //       // console.log('initialTime:',initialTime);
  //       const currentTime = moment().format('HH:mm');
  //       // console.log('currentTime:',currentTime);

  //       const durationStr = set_duration;
  //       const duration = moment.duration(durationStr);
  //       const elapsedTime = moment.duration(currentTime.diff(initialTime));
  //       // console.log('elapsedTime:', elapsedTime);
  //       const remainingTime = duration.subtract(elapsedTime).asSeconds();
  //       // console.log('remainingTime:', remainingTime);

  //       if (remainingTime <= 0) {
  //         setRemainingDuration(0);
  //         setIsTimerCompleted(true);
  //       } else {
  //         setRemainingDuration(remainingTime);
  //       }
  //     } catch (error) {
  //       console.error('Error in fetchDataAndSetTimer:', error);
  //     }
  //   };

  //   fetchDataAndSetTimer();
  //   const timeOut = setTimeout(fetchDataAndSetTimer, 5000);
  //   return () => {
  //     // console.log('Clearing timeout');
  //     clearTimeout(timeOut);
  //   };
  // }, [currentTime]);

  useEffect(() => {
    const fetchDataAndSetTimer = async () => {
      try {
        console.log("Starting fetchDataAndSetTimer function");
        let projValue = await AsyncStorage.getItem("projectData");
        console.log("projValue", projValue);
        console.log("Base", BasedOn);
        await getIMEITabsData(projValue);
        console.log("getIMEITabsData completed");

        // Ensure stm is valid
        //const stmstat = "3:00";
        if (stm) {
          const initialTime = stm;

          //// console.log('initialTime:', initialTime.format('HH:mm'));
          // console.log('initialTime:', initialTime);

          const currentTime = moment();
          // console.log('currentTime:', currentTime.format('HH:mm'));

          const durationStr = set_duration;
          const duration = moment.duration(durationStr);
          // console.log('duration:', duration);

          const elapsedTime = moment.duration(currentTime.diff(initialTime));
          // console.log('elapsedTime:', elapsedTime);

          const remainingTime = duration.subtract(elapsedTime).asSeconds();
          console.log("remainingTime:", remainingTime);

          if (remainingTime <= 0) {
            setRemainingDuration(0);
            setIsTimerCompleted(true);
          } else {
            setRemainingDuration(remainingTime);
            //setIsTimerCompleted(false);
          }
        } else {
          console.error("stm is not set");
        }
      } catch (error) {
        console.error("Error in fetchDataAndSetTimer:", error);
      }
    };

    fetchDataAndSetTimer();

    const timeOut = setTimeout(fetchDataAndSetTimer, 5000);

    return () => {
      // console.log('Clearing timeout');
      clearTimeout(timeOut);
    };
  }, [currentTime]);

  const handleTimerComplete = () => {
    // console.log('Timer completed');
    setIsTimerCompleted(true);
    //setRemainingDuration(0);
  };

  //const isonline = true;

  const timer = `${timeHr < 10 ? `0${timeHr}` : timeHr}:${timemin < 10 ? `0${timemin}` : timemin
    }:${timesec < 10 ? `0${timesec}` : timesec}`;

  useFocusEffect(
    React.useCallback(() => {
      getAdminData();
      // getIMEIDeviceDetailData();
      // getIMEIDetails();
    }, [projectId, projectName])
  );

  useEffect(() => {
    setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);
  }, []);

  // useEffect(() => {
  //   // Check conditions and set BasedOn accordingly
  //   // console.log("basedOn",basedOn);
  //   if (BasedOn == null || BasedOn == 1) {
  //     setBasedOn(1);
  //   } else if (BasedOn == 2) {
  //     // console.log("basedOn",basedOn);
  //     setBasedOn(2);
  //   }
  // }, [BasedOn]);
  // // console.log("BasedOnsetter...",BasedOn);

  useEffect(() => {
    const b = timer.split(":"); // split it at the colons
    const result = +b[0] * 60 * 60 + +b[1] * 60 + +b[2];
    setvalveamount(result);
  }, [timer]);

  useEffect(() => {
    if (vNewIndex != "") {
      getValvesData(vNewIndex);
    }
  }, [valveamount]);

  let socket: WebSocket;

  useEffect(() => {
    const connectWebSocket = () => {
      socket = new WebSocket(baseUrlSocket);

      socket.onopen = () => {
        sendMessage();
      };

      socket.onmessage = (event) => {
        const message = event.data;
        if (BasedOn == 1 || BasedOn == null) {
          setSocketDetails(JSON.parse(message));
        }
        if (BasedOn == 2) {
          if (message) {
            setIMEITabData(JSON.parse(message));
          }
        }

        setrefreshflatlist(true);
        console.log('Received message:', BasedOn, "--", message);
      };

      socket.onerror = (error) => {
        // console.log('WebSocket error:', error);
      };

      socket.onclose = (event) => {
        // console.log('WebSocket connection closed:', event.code, event.reason);
      };
    };

    connectWebSocket();

    setInterval(() => {
      sendMessage();
    }, 5000);

    // Clean up the WebSocket connection when the component unmounts
    return () => {
      if (socket) {
        socket.close();
      }
    };
  }, [OmegaId, projectId]);

  const sendMessage = async () => {
    if (socket && socket.readyState === WebSocket.OPEN) {
      let jsonValue = await AsyncStorage.getItem("userData");
      let projValue = await AsyncStorage.getItem("projectData");
      // console.log("BasedOn value:...", BasedOn);
      if (projValue != null) {
        const data = projValue + "-" + OmegaId + "-" + basedOn;
        // console.log("data", data);
        socket.send(data);
      } else if (jsonValue != null) {
        var userDetailsresponse = JSON.parse(jsonValue);
        const data =
          userDetailsresponse.project_selected + "-" + OmegaId + "-" + basedOn;
        // console.log("data....", data);
        socket.send(data);
      }
    }
  };

  const downTimeAmount = () => {
    const interval = setInterval(() => {
      setvalveDetCount(valveDetails[selectedValveIndex]["leftover"] - 1);
    }, 1000);
    return () => clearInterval(interval);
  };
  const getAdminData = async () => {

    // console.log("here---------------------------------------------------");
    try {
      const projectTypeValue = await projectTypeValueById();
      setProjectTypeValueData(projectTypeValue)
      let jsonValue = await AsyncStorage.getItem("userData");
      let proCodeId = await AsyncStorage.getItem("projectCode");
      let proName = await AsyncStorage.getItem("projectName");
      setProjectName(JSON.parse(proName));
      let proCode = JSON.parse(proCodeId);
      let projValue = await AsyncStorage.getItem("projectData");
      if (BasedOn !== 2) {
        // getValveOmegaDetails(projValue);
      }
      //getValveOmegaDetails(projValue);
      let projectNewId;
      if (projValue != null) {
        projectNewId = projValue;
        setProjectId(projValue);
      } else if (jsonValue != null) {
        var userDetailsresponse = JSON.parse(jsonValue);
        projectNewId = userDetailsresponse.project_selected;
        setProjectId(userDetailsresponse.project_selected);
      }
      if (BasedOn == 2) {
        await getIMEITabsData(projectNewId);
        await getValveOmegaDetails(projectNewId);
      }
      if (BasedOn !== 2) {
        await getValveOmegaDetails(projectNewId);
      }
      getIMEIDeviceDetailData(projectNewId);
      getIMEIDetails(projectNewId);
    } catch (err) {
      // console.log("err", err);
    }
  };
  const getValvesData = async (vIndex) => {
    var vIn = vIndex;

    setVNewIndex(vIn);
  };
  const getCounts = (count1) => {
    if (count1 == 1) {
      // downTimeAmount();
      startProgram();
    }
  };

  async function calculateTotalMinutes(hours = 0, minutes = 0) {
    return hours * 60 + minutes;
  }

  //start Program
  const startProgram = async () => {
    if (vNewIndex != "") {
      var dIndex = valveDetails.filter((item) => item.index == vNewIndex);
      dIndex[0]["leftover"] = Number(valveamount);
      dIndex[0]["state"] = valveState;
      dIndex[0]["status"] = valveStatus;
      dIndex[0]["index"] = vNewIndex;
      dIndex[0]["index"] = vNewIndex;
      dIndex[0]["name"] = valveName;
      dIndex[0]["initialTime"] = valveamount;

      let newData = JSON.stringify(dIndex).slice(1, -1);
      let valveData = JSON.parse(newData);
      valveDetails[selectedValveIndex] = valveData;

      setValveDetails(valveDetails);
      setrefreshflatlist(true);
    }
    setIsLoading(true);
    try {
      var startProgram = JSON.stringify({
        omega_id: OmegaId,
        index: valveIndex,
        type: isMeasureType,
        amount: valveamount,
      });
      const startProgramResponse = await postData(
        startProgram,
        "reports/valveStart"
      );
      setIsLoading(false);
      var code = startProgramResponse.code;
      if (code == 1) {
      } else {
        setIsLoading(false);
        dIndex[0]["initialTime"] = 0;
        //alert(startProgramResponse.message);
      }
    } catch (error) {
      dIndex[0]["initialTime"] = 0;
      setIsLoading(false);
      // // console.log(error);
    }
  };
  //stop Program
  const stopProgram = async (index) => {
    setIsLoading(true);
    try {
      var stopProgram = JSON.stringify({
        omega_id: OmegaId,
        index: index,
      });
      // // console.log("stop program payload", stopProgram);
      const stopProgramResponse = await postData(
        stopProgram,
        "reports/valveStop"
      );
      setIsLoading(false);
      var code = stopProgramResponse.code;
      if (code == 1) {
        // // console.log(" stop valve", stopProgramResponse.data);
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      // // console.log(error);
    }
  };

  const AutoOptionSelected = async (type) => {
    setIsLoading(true);
    try {
      var AutoOptionSelected = JSON.stringify({
        omega_id: OmegaId,
        operation: type,
      });
      // console.log("menu option selected payload", AutoOptionSelected);
      const autoOptionSelectedResponse = await postData(
        AutoOptionSelected,
        "/masters/changeautomanualforimei"
      );
      // setIsLoading(false);
      var code = autoOptionSelectedResponse.code;
      if (code == 1) {
        // // console.log(" stop valve", stopProgramResponse.data);
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      // // console.log(error);
    }
  };
  //Get details when click on omega id
  const getValveOmegaDetails = async (proId) => {
    try {
      var OmegaDetails = JSON.stringify({
        omega_id: OmegaId,
        project_id: proId,
      });
      const OmegaDetailsResponse = await postData(
        OmegaDetails,
        "dashboards/dashboardGridView"
      );
      setIsLoadingSync(false);
      var code = OmegaDetailsResponse.code;
      if (code == 1) {
        setIsOnline(OmegaDetailsResponse.data[0].online);
        console.log("OmegaDetailsResponse.data[0].online", OmegaDetailsResponse.data[0].online);

        setValveDetails(OmegaDetailsResponse.data[0].valves);
        setWaterMeterDetails(OmegaDetailsResponse.data[0].watermeter);
        setSwitchsDetails(OmegaDetailsResponse.data[0].switches);
        setAnalogDetails(OmegaDetailsResponse.data[0].analogs);
        setOmegaDetailsGrid(OmegaDetailsResponse.data);
      } else {
        setIsLoadingSync(false);
      }
    } catch (error) {
      setIsLoadingSync(false);
      // // console.log(error);
    }
  };

  const getDetailsFromSocket = (index) => {
    var mainindex = index;
    var leftover = 0;
    var state = 0;
    var valveIndex = 0;
    var mainsIndex = "";
    var arr: number[] = [];
    // console.log("valveDetails", valveDetails);
    // console.log("socketDetails", socketDetails);
    // console.log("arr", arr);

    try {
      if (socketDetails[0].programs && socketDetails[0].programs.length > 0) {
        for (let j = 0; j < socketDetails[0].programs.length; j++) {
          if (
            socketDetails[0].programs[j].valve == valveDetails[mainindex].index
          ) {
            // console.log("socketDetails[0].programs[j]", j)
            mainsIndex = socketDetails[0].programs[j].index;
            valveIndex = socketDetails[0].programs[j].valve;
            leftover = Number(socketDetails[0].programs[j].leftover);
            state = socketDetails[0].programs[j].state;
            arr.push(Number(socketDetails[0].programs[j].leftover));
            break;
          }
        }
      }
    } catch (error) {
      // console.log("socket disconnected121212", error);
    }

    const data = { leftover: leftover, state: state };

    return data;
  };
  const getIMEIDeviceDetailData = async (proId) => {
    //setIsLoading(true);
    // console.log("IMEIId", IMEIId);
    try {
      var DeviceDetailsIMEIData = JSON.stringify({
        isValveView: true,
        module: "device_profile",
        condition: {
          //"imei_no": "869523059681875",
          project_id: proId,
          imei_no: OmegaId,
        },
      });
      const DeviceDetailsIMEIResponse = await postData(
        DeviceDetailsIMEIData,
        "/masters/getMasterList"
      );
      setIsLoading(false);
      // console.log("DeviceDetailsIMEIResponse ==> ", DeviceDetailsIMEIResponse);
      var code = DeviceDetailsIMEIResponse.code;
      if (code == 1) {
        const IMEIMonitoringData = DeviceDetailsIMEIResponse.data[0];
        setIMEIDeviceName(IMEIMonitoringData);

        const online_check_time =
          DeviceDetailsIMEIResponse.data[0].online_check_time;
        let totalMinutes = 0;
        if (online_check_time) {
          totalMinutes = await calculateTotalMinutes(
            online_check_time.hours,
            online_check_time.minutes
          );
        }
        setOnlineCheckTime(totalMinutes);

        //setBasedOn(IMEIMonitoringData.based_on);
        // console.log("ValveViewData", IMEIMonitoringData.imei_static_label_data);
        setLabelValues(IMEIMonitoringData.imei_static_label_data);
      } else {
        // Error case
        console.error(
          "Failed to fetch DatatableLabelResponse:",
          DeviceDetailsIMEIResponse.message
        );
      }
    } catch (error) {
      setIsLoading(false);
      // console.log("error ==> ", error);
    }
  };

  const getIMEIDetails = async (proId) => {
    //setIsLoading(true);
    try {
      var IMEIDetailsData = JSON.stringify({
        isBasedOn: true,
        module: "live_monitoring",
        condition: {
          //"device_id": "1233",
          project_id: proId,
          omega_id: OmegaId,
        },
        "sort": {
          "column": "updatedAt",
          "sortType": "DESC"
        }
      });
      const IMEIDetailsDataResponse = await postData(
        IMEIDetailsData,
        "/masters/getMasterList"
      );
      setIsLoading(false);
      var code = IMEIDetailsDataResponse.code;
      if (code == 1) {
        console.log('IMEIDetailsDataResponse.data[0].valves', IMEIDetailsDataResponse.data[0].valves);

        setIMEIData(IMEIDetailsDataResponse.data[0]);
        setIsOnline(IMEIDetailsDataResponse.data[0].isonline);
        setValveIMEIData(IMEIDetailsDataResponse.data[0].valves);
        setWaterIMEIData(IMEIDetailsDataResponse.data[0].watermeter);
        setSwitchIMEIData(IMEIDetailsDataResponse.data[0].switches)
        setPressureIMEIData(IMEIDetailsDataResponse.data[0].analogs);
        setControlParameter(IMEIDetailsDataResponse.data[0].control_parameter);
        setWaterBy(IMEIDetailsDataResponse.data[0].control_parameter.water_by);
        setSetDuration(
          IMEIDetailsDataResponse.data[0].control_parameter.set_duration
        );
        const dynamic_based_on = IMEIDetailsDataResponse.data[0].based_on
          ? IMEIDetailsDataResponse.data[0].based_on
          : 2;
        setBasedOn(dynamic_based_on);
      } else {
        // Error case
        console.error(
          "Failed to fetch DatatableLabelResponse:",
          IMEIDetailsDataResponse.message
        );
      }
    } catch (error) {
      setIsLoading(false);
      // console.log("error ==> ", error);
    }
  };

  const handleOmegaOptions = async (props: any) => {
    var data = JSON?.stringify({
      imei_number: props?.imei_number,
      status: props?.status == 'OFF' ? 'Ideal' : props?.status
    })
    console.log("data", data);
    await postData(data, "masters/valveOperation")
      .then((res: any) => {
        console.log("res?.data", res?.data);
      })
      .catch((err: any) => {
        console.log("err", err);
      });
  };

  const getIMEITabsData = async (proId) => {
    try {
      if (BasedOn != 2) {
        return false;
      }
      var IMEITabData = JSON.stringify({
        active: 1,
        condition: {
          project_id: projectId,
          based_on: BasedOn,
          omega_id: OmegaId,
        },
        module: "devices_master",
      });
      const IMEITabDataResponse = await postData(
        IMEITabData,
        "/masters/getMasterList"
      );
      setIsLoading(false);
      var code = IMEITabDataResponse.code;
      if (code == 1) {
        const imeiData = JSON.parse(IMEITabDataResponse.data[0].imei_data);
        const { setpul, decpul, stm } = imeiData;
        setSetpul(setpul);
        setDecpul(decpul);
        setStm(stm);
        setIMEIData12(imeiData);
      } else {
        // Error case
        console.error(
          "Failed to fetch DatatableLabelResponse:",
          IMEITabDataResponse.message
        );
      }
    } catch (error) {
      setIsLoading(false);
      console.log("error ==> ", error);
    }
  };
  const screenwidth = Dimensions.get("window").width;
  const isMobile = screenwidth < 768;

  const renderValveItem = (item, index) => {
    const data = getDetailsFromSocket(index);
    var tempProgramArray = [];
    var tempValveArray = [];
    var tempLeft: number | any[] = [];
    var leftover = data.leftover;
    var state = data.state;
    var flow = 0;
    if (socketDetails.length != 0) {
      var tempsocketDetailsValve = socketDetails[0].valves;
      if (tempsocketDetailsValve) {
        for (let i = 0; i < tempsocketDetailsValve.length; i++) {
          const element = tempsocketDetailsValve[i];
          if (element.inputNumber === item.inputNumber) {
            tempValveArray.push(element);
          }
        }
      }

      var tempsocketDetails = socketDetails[0].programs;
      // console.log("tempsocketDetails", tempsocketDetails);

      if (tempsocketDetails) {
        for (let i = 0; i < tempsocketDetails.length; i++) {
          const element = tempsocketDetails[i];
          if (element.valve === Number(item.inputNumber)) {
            flow = element.flow;
            tempProgramArray.push(element);
            tempLeft.push(element.leftover);
          }
        }
      }
    }
    // console.log("tempValveArray", tempValveArray);
    // console.log("tempProgramArray", tempProgramArray);
    // console.log("tempLeft", tempLeft);

    var initialTimeNew = 0;
    if (tempProgramArray.length > 0) {
      initialTimeNew =
        (100 / tempProgramArray[0].perc) * tempProgramArray[0].leftover;
    }
    var alert = ''
    if (tempValveArray.length > 0) {
      const valve = tempValveArray[0];
      for (const element of tempValveArray) {
        const inputNumber = element?.inputNumber;
        if (inputNumber == item?.index) {
          if (valve.state === "open") {
            // Depending on the specific error status, increment the corresponding count.
            if (valve.status === "OPEN_WITH_ERROR_WITH_LOW_FLOW") {
              alert = 'Low Flow'
            } else if (valve.status === "OPEN_WITH_ERROR_WITH_HIGH_FLOW") {
              alert = 'High Flow'
            } else if (valve.status === "OPEN_WITH_ERROR_NO_PULSE") {
              alert = 'No pulse'
            }
          }
        }
      }
    }
    return (
      <View style={{}}>
        <View style={{ padding: 10 }}>
          <View style={{ alignSelf: "center", marginBottom: 5 }}>
            <View style={{ flexDirection: "row" }}>
              <Text
                style={{
                  alignSelf: "center",
                  marginLeft: 20,
                  fontWeight: "500",
                  fontSize: 18,
                }}
              >
                {item.name}

                <View
                  style={{
                    width: "10%",
                    left: 5,
                    top: "0px",
                  }}
                >
                  <Menu>
                    <MenuOptions
                      optionsContainerStyle={{
                        borderWidth: 1,
                        zIndex: 5,
                        flex: 1,
                        width: "10%",
                        // width: useWindowDimensions().width,
                        position: "absolute",
                        marginTop: -92,
                        marginLeft: -150,
                        borderColor: "#7b757573",
                        height: 30,
                      }}
                    >
                      {tempProgramArray.length == 0 && (
                        <MenuOption
                          onSelect={() => {
                            setSelectedValveIndex(index);
                            setValveIndex(item.index);
                            setValveName(item.name);
                            setvalveStatus(item.status);
                            setValveState(item.state);
                            getValvesData(item.index, item.name);
                            setValveIsVisible(true);
                            setvalveamount(0);
                          }}
                          text={"Start"}
                        />
                      )}
                      {tempProgramArray.length > 0 && (
                        <MenuOption
                          onSelect={() => {
                            stopProgram(item.index);
                          }}
                          text={"Stop"}
                        />
                      )}
                    </MenuOptions>
                    <MenuTrigger
                      text=""
                      customStyles={{
                        triggerWrapper: {
                          width: 30,
                        },
                      }}
                    >
                      <IconCross
                        color="#000"
                        name="dots-three-vertical"
                        size={15}
                      />
                    </MenuTrigger>
                  </Menu>
                </View>
              </Text>
            </View>
            <View style={{ flexDirection: "row", marginLeft: "20%" }}>
              <Text
                style={{
                  fontWeight: "500",
                  fontSize: 30,
                }}
              >
                {flow}
              </Text>
              <View style={{ marginLeft: 10, alignSelf: "center" }}>
                <Text
                  style={{
                    fontWeight: "500",
                    fontSize: 10,
                    color: "grey",
                  }}
                >
                  Flow
                </Text>
                <Text
                  style={{
                    fontWeight: "500",
                    fontSize: 10,
                    color: "grey",
                  }}
                >
                  (m3/h)
                </Text>
              </View>
            </View>
          </View>
          <CountdownCircleTimer
            isPlaying={state === 3 ? false : true}
            // duration={item.leftover}
            // duration={item.initialTime !== null ? item.initialTime : 0}
            duration={initialTimeNew}
            // initialRemainingTime={leftover !== 0 ? item.leftover : leftover}
            // initialRemainingTime={leftover}
            // key={leftover}
            initialRemainingTime={
              tempProgramArray.length > 0 ? tempProgramArray[0].leftover : 0
            }
            key={
              tempProgramArray.length > 0 ? tempProgramArray[0].leftover : ""
            }
            colors={["#009df0", "#F7B801", "#A30000", "#A30000"]}
            colorsTime={[7, 5, 2, 0]}
            rotation={"counterclockwise"}
            size={140}
          >
            {({ remainingTime }) => {
              <Text></Text>;
              var hours = Math.floor(remainingTime / 3600).toString();
              var minutes = Math.floor((remainingTime % 3600) / 60).toString();
              var seconds = Math.floor(remainingTime % 60).toString();
              hours = ("0" + hours).slice(-2);
              minutes = ("0" + minutes).slice(-2);
              seconds = ("0" + seconds).slice(-2);
              return (
                <View>
                  {<Text style={{
                    fontWeight: "500",
                    fontSize: 12,
                    width: 90,
                    textAlign: "center"
                  }}
                    numberOfLines={1}>
                    {item.description}
                  </Text>}
                  <Text
                    style={{
                      alignSelf: "center",
                      marginTop: 2,
                      fontWeight: "500",
                      fontSize: 15,
                    }}
                  >
                    {item.measurementype == 1
                      ? // secondsToHMS(leftover)
                      leftover / 1000 + " m³"
                      : new Date(leftover * 1000).toISOString().slice(11, 19)}
                  </Text>
                  <View style={{ height: 25 }}>
                    {alert != "" && (
                      <View
                        style={{ flexDirection: "row", alignSelf: "center" }}
                      >
                        <AntDesign
                          color="red"
                          name="exclamationcircleo"
                          size={16}
                        />
                        <Text
                          style={{
                            fontWeight: "500",
                            fontSize: 12,
                            color: "red",
                            marginLeft: 5,
                          }}
                        >
                          {alert}
                        </Text>
                      </View>
                    )}
                  </View>
                </View>
              );
            }}
          </CountdownCircleTimer>
        </View>
      </View>
    );
  };

  const renderValveIMEIItem = (item, index) => {

    var mnl = IMEIData12?.mnl;
    const isPlaying = Boolean(isOnline && !isTimerCompleted);
    const timerColors = isOnline
      ? ["#009df0", "#F7B801", "#A30000", "#A30000"]
      : ["#D3D3D3", "#D3D3D3", "#D3D3D3", "#D3D3D3"];

    let colorsObject: any = {};

    colorsObject = {
      0: timerColors[0],
      1: timerColors[1],
      2: timerColors[2],
      3: timerColors[3],
    };

    const durationStr = set_duration;
    const startTimeStr = stm;
    const duration = moment.duration(durationStr);
    const startTime = moment(startTimeStr, "HH:mm");
    const startTimeNumber = startTime.hour() * 100 + startTime.minute();

    // New Code of timmer (IMEI valve)
    // mode 1=duration, 0=volume
    let isTimerExpired = false;
    let totalDuration_new;
    let remainingTime_new;
    let showDuration = "00:00:00";

    const fstOptCdn =
      mnl == 0 ? "Manual" : mnl == 1 || mnl == 2 ? "Auto" : "ON";
    const sdnOptCdn =
      mnl == 2 ? "Manual" : mnl == 0 || mnl == 1 ? "OFF" : "OFF";

    if (mnl == 0) {
      if (IMEIData12?.mode == 1) {
        const currentTime = moment().format("HH:mm");
        const currentDate = moment().format("YYYY-MM-DD");

        // Define start and end times with the same date
        const startTime_new = moment(
          `${currentDate} ${stm}`,
          "YYYY-MM-DD HH:mm"
        );
        const endTime_new = moment(
          `${currentDate} ${set_duration}`,
          "YYYY-MM-DD HH:mm"
        );

        // Handle cases where the timer spans midnight
        if (endTime_new.isBefore(startTime_new)) {
          endTime_new.add(1, "day"); // Move end time to the next day
        }

        const currentTime_new = moment(
          `${currentDate} ${currentTime}`,
          "YYYY-MM-DD HH:mm"
        );

        // Calculate total duration and remaining time in seconds
        totalDuration_new = endTime_new.diff(startTime_new, "seconds");
        remainingTime_new = endTime_new.diff(currentTime_new, "seconds");

        // Check if the current time has passed the end time
        isTimerExpired = remainingTime_new <= 0;

        // Calculate showDuration for mode 1
        const hours = Math.floor(remainingTime_new / 3600)
          .toString()
          .padStart(2, "0");
        const minutes = Math.floor((remainingTime_new % 3600) / 60)
          .toString()
          .padStart(2, "0");
        const seconds = Math.floor(remainingTime_new % 60)
          .toString()
          .padStart(2, "0");
        showDuration =
          !isOnline || isTimerExpired
            ? "00:00:00"
            : `${hours}:${minutes}:${seconds}`;
      } else if (IMEIData12?.mode == 0) {
        // Calculate for mode 0
        const set_volume = IMEIData12?.setpul * IMEIData12?.setpf; // Total volume
        const supplied_volume = IMEIData12?.decpul * IMEIData12?.setpf; // Supplied volume
        const remaining_volume = Math.max(set_volume - supplied_volume, 0); // Ensure non-negative

        // Use remaining volume as the duration
        totalDuration_new = set_volume; // Total volume in "seconds equivalent"
        remainingTime_new = remaining_volume; // Remaining volume in "seconds equivalent"
        isTimerExpired = remainingTime_new <= 0;

        // console.log('NewDta:  IMEIData12', IMEIData12);
        // console.log('NewDta:  set_volume', set_volume);
        // console.log('NewDta:  supplied_volume', supplied_volume);
        // console.log('NewDta:  remaining_volume', remaining_volume);
        // console.log('NewDta:  totalDuration_new', totalDuration_new);
        // console.log('NewDta: remainingTime_new', remainingTime_new);
        // console.log('NewDta: isTimerExpired', isTimerExpired);
        
        // Display remaining volume in meter cube format
        showDuration =
          !isOnline || isTimerExpired
            ? "0 m3"
            : `${remaining_volume.toFixed(2)} m³`; // Two decimal places
      }
    } else if (mnl == 1) {
      colorsObject = {
        0: "#009df0",
      };
      if (IMEIData12?.mode == 1) {
        showDuration = "00:00:00";
      } else if (IMEIData12?.mode == 0) {
        showDuration = "0 m³";
      }
    } else if (mnl == 2) {
      colorsObject = {
        0: "#D3D3D3",
      };
      if (IMEIData12?.mode == 1) {
        showDuration = "00:00:00";
      } else if (IMEIData12?.mode == 0) {
        showDuration = "0 m³";
      }
    }

    // valve description code
    let valveDescription = "";
    if (item.valve_description) {
      valveDescription = item.valve_description;
    }

    let alert = '';
    const flow1 = IMEIData12?.flow;
    const alertTimeMinutes = parseInt(item.alert_time) || 0;
    const targetTime = new Date(initialTime.getTime() + alertTimeMinutes * 60000);

    const is_open = IMEIData12?.sol == 1 ? true : false;
    const low_flow = item?.percentage_for_low_flow ? parseFloat(item?.percentage_for_low_flow) : 0;
    const high_flow = item?.percentage_for_high_flow ? parseFloat(item?.percentage_for_high_flow) : 0;
    const flow = IMEIData12?.flow ? parseFloat(IMEIData12?.flow) : 0;
    console.log('log here is_open', is_open);
    console.log('log here flow', flow);
    console.log('log here low_flow', low_flow);
    console.log('log here high_flow', high_flow);

    if (is_open) {
      if (flow == 0) {
        alert = 'No Flow'
      } else if (flow < low_flow) {
        alert = 'Low Flow'
      } else if (flow > high_flow) {
        alert = 'High Flow'
      }
    }

    return (
      <View style={{ padding: 10 }}>
        <View
          style={{
            alignSelf: "center",
            marginBottom: 5,
          }}
        >
          <View
            style={{
              alignItems: "center",
              flexDirection: "row",
              width: "100%",
              marginLeft: 0,
            }}
          >
            <Text
              style={{
                alignSelf: "center",
                marginLeft: 20,
                fontWeight: "500",
                fontSize: 18,
              }}
            >
              {item.name ? item?.name : "V1"}
            </Text>
            <View
              style={{
                left: 5,
                top: "0px",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Menu>
                <MenuOptions
                  optionsContainerStyle={{
                    borderWidth: 1,
                    borderColor: "#7b757573",
                    borderRadius: 8,
                    backgroundColor: "#fff",
                    width: 120,
                    elevation: 5,
                    shadowColor: "#000",
                    shadowOffset: { width: 0, height: 2 },
                    shadowOpacity: 0.3,
                    shadowRadius: 4,
                    paddingVertical: 5,
                    marginTop: -55,
                    marginLeft: -60,
                  }}
                >
                  <MenuOption
                    onSelect={() => {
                      console.log("First option selected");
                      handleOmegaOptions({ imei_number: IMEIData12?.imei, status: fstOptCdn });
                    }}
                    text={fstOptCdn}
                    customStyles={{
                      optionWrapper: {
                        padding: 12
                      },
                      optionText: {
                        fontSize: 16,
                        fontWeight: "500",
                      },
                    }}
                  />
                  <MenuOption
                    onSelect={() => {
                      console.log("Second option selected");
                      handleOmegaOptions({ imei_number: IMEIData12?.imei, status: sdnOptCdn });
                    }}
                    text={sdnOptCdn}
                    customStyles={{
                      optionWrapper: {
                        padding: 12,
                      },
                      optionText: {
                        fontSize: 16,
                        fontWeight: "500",
                      },
                    }}
                  />
                </MenuOptions>
                <MenuTrigger
                  text=""
                  customStyles={{
                    triggerWrapper: {
                      width: 30,
                    },
                  }}
                >
                  <IconCross
                    color="#000"
                    name="dots-three-vertical"
                    size={15}
                  />
                </MenuTrigger>
              </Menu>
              {mnl == 1 && (
                <Text
                  style={{
                    color: "#009df0",
                    fontWeight: "700",
                    marginLeft: -10,
                    marginRight: 10,
                  }}
                >
                  Manual
                </Text>
              )}
            </View>
          </View>
          <View
            style={{ flexDirection: "row", marginLeft: "30%", marginTop: 10 }}
          >
            <Text
              style={{
                fontWeight: "500",
                fontSize: 30,
              }}
            >
              {IMEIData12.flow ? IMEIData12.flow : 0}
            </Text>
            <View style={{ marginLeft: 10, alignSelf: "center" }}>
              <Text
                style={{
                  fontWeight: "500",
                  fontSize: 10,
                  color: "grey",
                }}
              >
                Flow
              </Text>
              <Text
                style={{
                  fontWeight: "500",
                  fontSize: 10,
                  color: "grey",
                }}
              >
                (m3/h)
              </Text>
            </View>
          </View>
        </View>

        <CountdownCircleTimer
          key={remainingTime_new}
          isPlaying={true}
          duration={totalDuration_new}
          initialRemainingTime={remainingTime_new}
          colors={colorsObject}
          colorsTime={[420, 300, 120, 0]}
          rotation="counterclockwise"
          size={140}
          onComplete={() => {
            console.log("Timer completed");
            return { shouldRepeat: false }; // Stop repeating when complete
          }}
        >
          {({ remainingTime }) => {
            return (

              <View>
                {<Text style={{
                  fontWeight: "500",
                  fontSize: 12,
                  width: 90,
                  textAlign: "center"
                }}
                  numberOfLines={1}>
                  {item.description}
                </Text>}
                <Text
                  style={{
                    alignSelf: "center",
                    marginTop: 2,
                    fontWeight: "500",
                    fontSize: 15,
                  }}
                >
                  {showDuration}
                </Text>
                <View style={{ height: 25 }}>
                  {alert != '' &&
                    <View style={{ flexDirection: 'row', alignSelf: 'center' }}>
                      <AntDesign
                        color="red"
                        name="exclamationcircleo"
                        size={16}
                      />
                      <Text style={{
                        fontWeight: "500",
                        fontSize: 12,
                        color: 'red',
                        marginLeft: 5,
                      }}>
                        {alert}
                      </Text>
                    </View>
                  }
                </View>
              </View>
            );
          }}
        </CountdownCircleTimer>
      </View>
    );
  };
  //omega based pressure transmitter block
  const renderItemLeft = ({ item, index }) => {
    var mainindex = index;
    var name = "";
    var value = 0;
    var error = "";

    try {
      if (socketDetails[0].analogs && socketDetails[0].analogs.length > 0) {
        for (let j = 0; j < socketDetails[0].analogs.length; j++) {
          if (
            Number(socketDetails[0].analogs[j].index) ===
            Number(analogDetails[mainindex].index)
          ) {
            name = socketDetails[0].analogs[j].name;
            value = socketDetails[0].analogs[j].value;
            error = socketDetails[0].analogs[j].error;
            break;
          }
        }
      }
    } catch (error) {
      // console.log("socket disconnected121212", error);
    }
    return (
      <View style={{ justifyContent: "space-between" }}>
        {isOnline == true ? (
          <View
            style={{
              backgroundColor: "#009df01f",
              marginLeft: 10,
              marginBottom: 10,
              padding: 7,
              height: 100,
              width: 200,
            }}
          >
            <View
              style={{
                flexDirection: "row",
                width: "95%",
                alignSelf: "flex-start",
                marginLeft: 5,
                marginTop: 5,
                justifyContent: "space-between",
              }}
            >
              <Tooltip
                popover={<Text style={{ color: "white" }}> {name}</Text>}
              >
                <Text style={{ fontWeight: "700" }}>
                  {name !== "" ? name.toUpperCase() : ""}
                </Text>
              </Tooltip>
            </View>
            {error ? (
              <Text
                style={{
                  alignSelf: "flex-start",
                  marginLeft: 0,
                  color: "red",
                  marginTop: 5,
                  fontWeight: "700",
                  marginRight: 20,
                  padding: 2,
                }}
              >
                {error}
              </Text>
            ) : (
              <Text
                style={{
                  alignSelf: "flex-start",
                  marginLeft: 5,
                  color: "red",
                  // padding: 2,
                  marginTop: 10,
                  marginRight: 20,
                }}
              >
                {" "}
              </Text>
            )}

            <Text
              style={{
                alignSelf: "center",
                fontWeight: "700",
                fontSize: 15,
                marginTop: 5,
                color:
                  item.level == "Open"
                    ? "#0fbf00"
                    : item.level == "Close"
                      ? "#bababa"
                      : "#000000",
                textAlign: "center",
              }}
            >
              <Text style={{ fontSize: 30 }}>{value}</Text> {item.unitOfMeasure}
            </Text>
          </View>
        ) : (
          <View
            style={{
              backgroundColor: "#009df01f",
              marginLeft: 10,
              padding: 7,
              height: 100,
              width: 200,
            }}
          >
            <View
              style={{
                flexDirection: "row",
                width: "95%",
                alignSelf: "flex-start",
                marginLeft: 5,
                marginTop: 5,
                justifyContent: "space-between",
              }}
            >
              <Tooltip
                popover={<Text style={{ color: "white" }}> {item.name}</Text>}
              >
                <Text style={{ fontWeight: "700" }}>
                  {BasedOn == 2
                    ? item.name.toUpperCase()
                    : item.name !== ""
                      ? item.name.toUpperCase()
                      : "PT"}
                </Text>
              </Tooltip>
            </View>
            {item.error ? (
              <Text
                style={{
                  alignSelf: "flex-start",
                  marginLeft: 0,
                  color: "red",
                  marginTop: 5,
                  fontWeight: "700",
                  marginRight: 20,
                }}
              >
                {item.error}
              </Text>
            ) : (
              <Text
                style={{
                  alignSelf: "flex-start",
                  marginLeft: 5,
                  color: "red",
                  // padding: 2,
                  marginTop: 14,
                  marginRight: 20,
                }}
              >
                {" "}
              </Text>
            )}

            <Text
              style={{
                alignSelf: "center",
                fontWeight: "700",
                fontSize: 15,
                color:
                  item?.level == "Open"
                    ? "#0fbf00"
                    : item?.level == "Close"
                      ? "#bababa"
                      : "#000000",
                textAlign: "center",
              }}
            >
              <Text style={{ fontSize: 30 }}>--</Text> {item.unitOfMeasure}
            </Text>
          </View>
        )}
      </View>
    );
  };

  // IMEI based pressureTransmitter block
  const renderPressureIMEILeft = ({ item, index }) => {
    var mainindex = index;
    var name = "";
    var value = 0;
    var error = "";
    if (imeiTabData) {
      try {
        var imeiTabDataObject = JSON.parse(imeiTabData);
        var flow1 = imeiTabDataObject.pres; // for pressure
        // console.log("pres...", flow1);
        var flow2 = imeiTabDataObject.flow; // for level
        // console.log("flow2", flow2);
      } catch (error) {
        console.error("Error parsing JSON:", error);
      }
    }
    let imeiDataData;
    let pres = 0.0;
    let levl = 0.0;
    try {
      imeiDataData = JSON.parse(imeiTabData);
      pres = imeiDataData.pres.toFixed(2);
      levl = imeiDataData.levl.toFixed(2);
    } catch (err) {
      // console.log("err", err);
    }

    // presure imei
    if (Number(IMEIData12.pres) < Number(item.low_pressure)) {
      error = "Low Pressure";
    }
    if (Number(IMEIData12.pres) > Number(item.high_pressure)) {
      error = "High Pressure";
    }

    // leve imei
    if (Number(IMEIData12.levl) < Number(item.min_level)) {
      error = "Low Level";
    }
    if (Number(IMEIData12.levl) > Number(item.max_level)) {
      error = "High Level";
    }

    return (
      <View
        style={{
          justifyContent: "space-between",
          marginBottom: isMobile ? 10 : 0,
        }}
      >
        <View
          style={{
            backgroundColor: "#009df01f",
            marginLeft: 10,
            padding: 7,
            height: 100,
            width: 200,
          }}
        >
          <View
            style={{
              flexDirection: "row",
              width: "95%",
              alignSelf: "flex-start",
              marginLeft: 5,
              marginTop: 5,
              justifyContent: "space-between",
            }}
          >
            <Tooltip
              popover={<Text style={{ color: "white" }}> {item.name}</Text>}
            >
              <Text style={{ fontWeight: "700" }}>
                {BasedOn == 2
                  ? item.name !== ""
                    ? item.name.toUpperCase()
                    : index == 0 ? "PT" : "LT"
                  : item.name !== ""
                    ? item.name.toUpperCase()
                    : "PT"}{" "}
              </Text>
            </Tooltip>
          </View>
          {error ? (
            <Text
              style={{
                alignSelf: "flex-start",
                marginLeft: 0,
                color: "red",
                marginTop: 5,
                fontWeight: "700",
                marginRight: 20,
              }}
            >
              {error}
            </Text>
          ) : (
            <Text
              style={{
                alignSelf: "flex-start",
                marginLeft: 5,
                color: "red",
                // padding: 2,
                marginTop: 14,
                marginRight: 20,
              }}
            ></Text>
          )}

          <Text
            style={{
              alignSelf: "center",
              fontWeight: "700",
              fontSize: 15,
              color:
                item.level == "Open"
                  ? "#0fbf00"
                  : item.level == "Close"
                    ? "#bababa"
                    : "#000000",
              textAlign: "center",
            }}
          >
            {basedOn == 2 ? (
              item.index == 1 ? (
                <>
                  <Text style={{ fontSize: 30 }}>
                    {IMEIData12.pres != undefined
                      ? parseFloat(IMEIData12.pres).toFixed(2)
                      : "--"}
                  </Text>{" "}
                  {item.unit}
                </>
              ) : (
                <View style={{ flexDirection: "row", alignItems: "baseline" }}>
                  <Text style={{ fontSize: 30 }}>
                    {" "}
                    {IMEIData12.levl != undefined
                      ? parseFloat(IMEIData12.levl).toFixed(2)
                      : "--"}{" "}
                  </Text>{" "}
                  {item.unit}
                </View>
              )
            ) : (
              <>
                <Text style={{ fontSize: 30 }}>-- </Text>
                {item.unit}
              </>
            )}

            {/* <Text style={{ fontSize: 30 }}>--</Text> {item.unit} */}
          </Text>
        </View>
      </View>
    );
  };
  // Omega based watermeter blocks
  const renderWaterMtLeft = ({ item, index }) => {
    var mainindex = index;
    var name = "";
    var totalizer = 0;
    var status = 0;
    var error = "";
    var flow = 0;
    var inputNumber = 0;

    try {
      if (
        socketDetails[0].watermeter &&
        socketDetails[0].watermeter.length > 0
      ) {
        for (let j = 0; j < socketDetails[0].watermeter.length; j++) {
          if (
            Number(socketDetails[0].watermeter[j].index) ===
            Number(waterMeterDetails[mainindex].index)
          ) {
            name = socketDetails[0].watermeter[j].name;
            totalizer = socketDetails[0].watermeter[j].totalizer;
            status = socketDetails[0].watermeter[j].status;
            error = socketDetails[0].watermeter[j].error;
            flow = socketDetails[0].watermeter[j].flow;
            inputNumber = socketDetails[0].watermeter[j].inputNumber;
            break;
          }
        }
      }
    } catch (error) {
      // console.log("error", error);
    }

    return (
      // <View style={{ justifyContent: "space-between", width: "100%" }}>
      <View style={{ justifyContent: "space-between" }}>
        {name != undefined &&
          name != "" &&
          isOnline == true && (
            <View
              style={{
                backgroundColor: "#009df01f",
                marginLeft: 10,
                marginBottom: 10,
                padding: 7,
                height: 100,
                width: 200,
              }}
            >
              <View
                style={{
                  flexDirection: "row",
                  width: "95%",
                  alignSelf: "flex-start",
                  marginLeft: 5,
                  marginTop: 5,
                  justifyContent: "space-between",
                }}
              >
                <Tooltip
                  popover={<Text style={{ color: "white" }}> {name}</Text>}
                >
                  <Text style={{ fontWeight: "700" }}>{name ? name.toUpperCase() : "WATER METER"}</Text>
                </Tooltip>
              </View>
              <View>
                <Text style={{ alignSelf: "flex-start" }}>
                  {" "}
                  {totalizer / 1000} m³
                </Text>
              </View>
              {error != "" ? (
                <Text
                  style={{
                    alignSelf: "flex-start",
                    marginLeft: 5,
                    color: "red",
                    marginTop: 14,
                    marginRight: 20,
                  }}
                ></Text>
              ) : (
                <Text
                  style={{
                    alignSelf: "flex-start",
                    marginLeft: 5,
                    color: "red",
                    marginTop: 14,
                    marginRight: 20,
                  }}
                ></Text>
              )}
              <Text
                style={{
                  alignSelf: "center",
                  fontWeight: "700",
                  fontSize: 25,
                  color:
                    item.level == "Open"
                      ? "#0fbf00"
                      : item.level == "Close"
                        ? "#bababa"
                        : "#000000",
                  textAlign: "center",
                  marginVertical: 5,
                }}
              >
                {flow} m³/h
              </Text>
            </View>
          )}
        {item.name != undefined && item.name != "" && isOnline == false && (
          <View
            style={{
              backgroundColor: "#009df01f",
              marginLeft: 10,
              marginBottom: 10,
              padding: 7,
              height: 100,
              width: 200,
            }}
          >
            <View
              style={{
                flexDirection: "row",
                width: "95%",
                alignSelf: "flex-start",
                marginLeft: 5,
                marginTop: 5,
                justifyContent: "space-between",
              }}
            >
              <Tooltip
                popover={<Text style={{ color: "white" }}> {item.name}</Text>}
              >
                <Text style={{ fontWeight: "700" }}>{item.name ? item.name.toUpperCase() : "WATER METER"}</Text>
              </Tooltip>
            </View>
            <View>
              <Text style={{ alignSelf: "flex-start" }}> 0 m³</Text>
            </View>
            {item.error != "" ? (
              <Text
                style={{
                  alignSelf: "flex-start",
                  marginLeft: 5,
                  color: "red",
                  marginTop: 14,
                  marginRight: 20,
                }}
              ></Text>
            ) : (
              <Text
                style={{
                  alignSelf: "flex-start",
                  marginLeft: 5,
                  color: "red",
                  marginTop: 14,
                  marginRight: 20,
                }}
              ></Text>
            )}
            <Text
              style={{
                alignSelf: "center",
                fontWeight: "700",
                fontSize: 25,
                color:
                  item.level == "Open"
                    ? "#0fbf00"
                    : item.level == "Close"
                      ? "#bababa"
                      : "#000000",
                textAlign: "center",
                marginVertical: 5,
              }}
            >
              --
            </Text>
          </View>
        )}
      </View>
    );
  };
  //IMEI based water meter blocks
  const renderWaterMtIMEILeft = ({ item, index }) => {
    var error = "";
    if (imeiTabData) {
      try {
        var imeiTabDataObject = JSON.parse(imeiTabData);
        var tamp = imeiTabDataObject.tamp;
        if (index === 0 && imeiTabDataObject) {
          if (IMEIData12.sol == 0 && imeiDataData12.flow > 0) {
            error = "Water Leak";
          } else if (IMEIData12.sol == 1 && imeiDataData12.flow == 0) {
            error = "No Flow";
          }
        }

        if (index === 1) {
          var tamp = imeiTabDataObject.tamp;
        }
      } catch (e) {
        console.error("Error parsing JSON:", e);
      }
    }

    let imeiDataData;
    let totz;
    let flow12 = 0.0;

    // imei waterleak
    if (index == 0 && IMEIData12.sol == 0 && IMEIData12.flow > 0) {
      error = "Water Leak";
    }
    try {
      imeiDataData = JSON.parse(IMEIData12);
      totz = imeiDataData.totz;
      flow12 = IMEIData12.flow.toFixed(2);
    } catch (err) {
      console.log("err", err);
    }

    return (
      <View
        style={{
          justifyContent: "space-between",
          marginBottom: isMobile ? 10 : 0,
          flex: 1,
        }}
      >
        <View
          style={{
            backgroundColor: "#009df01f",
            marginLeft: 10,
            padding: 7,
            height: 100,
            width: 200,
            overflow: "hidden",
            borderRadius: 5,
          }}
        >
          <View
            style={{
              flexDirection: "row",
              width: "95%",
              alignSelf: "flex-start",
              marginLeft: 5,
              marginTop: 5,
              justifyContent: "space-between",
            }}
          >
            <Tooltip
              popover={<Text style={{ color: "white" }}> {item.name}</Text>}
            >
              <Text style={{ fontWeight: "700" }}>
                {BasedOn == 2
                  ? item.name !== ""
                    ? item.name.toUpperCase()
                    : "WM"
                  : item.name !== ""
                    ? item.name.toUpperCase()
                    : "WM"}
              </Text>
            </Tooltip>
          </View>

          {/* Error Message */}
          {error ? (
            <Text
              style={{
                alignSelf: "flex-start",
                color: "red",
                marginTop: 2,
                fontWeight: "700",
                marginLeft: 5,
              }}
            >
              {error}
            </Text>
          ) : item.index == 1 ? (
            <Text
              style={{
                alignSelf: "flex-start",
                color: "black",
                marginTop: 2,
                marginLeft: 5,
              }}
            >
              {IMEIData12.totz ? IMEIData12.totz + " m³" : ""}
            </Text>
          ) : (
            <Text
              style={{
                alignSelf: "flex-start",
                color: "red",
                marginTop: 15,
                marginLeft: 5,
              }}
            ></Text>
          )}

          {/* Conditional Text Display */}
          {error && basedOn == 2 && item.index == 1 ? (
            <Text
              style={{
                alignSelf: "flex-start",
                marginLeft: 5,
                color: "black",
                marginBottom: -10,
              }}
            >
              {IMEIData12.totz ? IMEIData12.totz + " m³" : ""}{" "}
            </Text>
          ) : (
            <Text
              style={{
                alignSelf: "flex-start",
                marginLeft: 5,
                color: "black",
                marginBottom: 10,
              }}
            ></Text>
          )}

          {/* Level Status Text */}
          <Text
            style={{
              alignSelf: "center",
              fontWeight: "700",
              fontSize: 15,
              color:
                item.level == "Open"
                  ? "#0fbf00"
                  : item.level == "Close"
                    ? "#bababa"
                    : "#000000",
              textAlign: "center",
            }}
          >
            {basedOn == 2 ? (
              item.index == 1 ? (
                <>
                  <Text style={{ fontSize: 30 }}>
                    {IMEIData12.flow ?? "--"}{" "}
                  </Text>
                  {item.unit}
                </>
              ) : (
                <Text style={{ fontSize: 20 }}>
                  {IMEIData12.tamp === undefined
                    ? "--"
                    : IMEIData12.tamp == 1
                      ? "Close"
                      : "Open"}
                </Text>
              )
            ) : (
              <>
                {index == 1 ? (
                  <Text style={{ fontSize: 30 }}>
                    {tamp === undefined ? "--" : tamp == 1 ? "Close" : "Open"}
                  </Text>
                ) : (
                  "--"
                )}
                {item.unit}
              </>
            )}
          </Text>
        </View>
      </View>
    );
  };
  // Omega based Table
  const renderItemRight = (item, index) => {
    var nextIrrigationTime = "";
    var lastIrrigationTime = "";
    var lastConnection = "";
    var lastIrrigationName = "";
    var isonline = false;
    try {
      if (socketDetails[0]) {
        nextIrrigationTime = socketDetails[0].nextIrrigationTime;
        lastIrrigationTime = socketDetails[0].lastIrrigationTime;
        lastConnection = socketDetails[0].lastConnection;
        isonline = socketDetails[0].isonline;
        lastIrrigationName = socketDetails[0].lastIrrigationName;
      }
    } catch (error) {
      // console.log("error", error)
    }
    return (
      <View
        style={
          isMobile
            ? {
              width: 150,
              marginBottom: 10,
              padding: 10,
              borderRadius: 10,
              marginLeft: -60,
            }
            : [styles.cardRight]
        }
      >
        <TouchableOpacity onPress={() => { }}>
          <View
            style={[
              styles.shadowProp,
              {
                backgroundColor: "#009df01f",
              },
            ]}
          >
            <View style={{ flexDirection: "row" }}>
              <View style={{ flex: 1, margin: 10 }}>
                <Image
                  style={{
                    height: 35,
                    width: 25,
                    resizeMode: "contain",
                  }}
                  source={require("../../assets/images/omega.png")}
                />
              </View>
              <View style={{ flex: 1, margin: 10 }}>
                <Text>{item.device_name}</Text>
              </View>
              <View style={{ flex: 1, alignItems: "flex-end", margin: 10 }}>
                <Icon
                  name="wifi"
                  color={item.isonline == true ? "#228B22" : "#FF0000"}
                  size={20}
                />
              </View>
            </View>

            <View style={{ flexDirection: "column", padding: 5 }}>
              <View style={{ flexDirection: "row" }}>
                <Text
                  style={
                    isMobile
                      ? {
                        backgroundColor: "#D4E3EB",
                        width: "100%",
                        marginBottom: 10,
                        padding: 8,
                        justifyContent: "flex-end",
                        fontSize: 12,
                      }
                      : {
                        backgroundColor: "#D4E3EB",
                        width: "100%",
                        marginBottom: 10,
                        padding: 8,
                      }
                  }
                >
                  {"Next " + projectTypeValue}
                </Text>

                <Text
                  style={
                    isMobile
                      ? {
                        backgroundColor: "#D4E3EB",
                        width: "100%",
                        marginBottom: 10,
                        padding: 8,
                        justifyContent: "flex-end",
                        fontSize: 12,
                        textAlign: "center",
                      }
                      : {
                        backgroundColor: "#D4E3EB",
                        width: "100%",
                        marginBottom: 10,
                        padding: 8,
                        justifyContent: "flex-end",
                      }
                  }
                >
                  {item.nextIrrigationTime}
                </Text>
              </View>

              <View style={{ flexDirection: "row" }}>
                <Text
                  style={
                    isMobile
                      ? {
                        backgroundColor: "#D4E3EB",
                        width: "100%",
                        marginBottom: 10,
                        padding: 8,
                        justifyContent: "flex-end",
                        fontSize: 12,
                      }
                      : {
                        backgroundColor: "#D4E3EB",
                        width: "100%",
                        marginBottom: 10,
                        padding: 8,
                      }
                  }
                >
                  {"Last " + projectTypeValue}
                </Text>

                <Text
                  style={
                    isMobile
                      ? {
                        backgroundColor: "#D4E3EB",
                        width: "100%",
                        marginBottom: 10,
                        paddingTop: 11,
                        justifyContent: "flex-end",
                        fontSize: 12,
                        textAlign: "center",
                      }
                      : {
                        backgroundColor: "#D4E3EB",
                        width: "100%",
                        marginBottom: 10,
                        padding: 8,
                        justifyContent: "flex-end",
                      }
                  }
                >
                  {item.lastIrrigationTime} ({item.lastIrrigationName})
                </Text>
              </View>

              <View style={{ flexDirection: "row" }}>
                <Text
                  style={
                    isMobile
                      ? {
                        backgroundColor: "#D4E3EB",
                        width: "100%",
                        marginBottom: 10,
                        padding: 8,
                        justifyContent: "flex-end",
                        fontSize: 12,
                      }
                      : {
                        backgroundColor: "#D4E3EB",
                        width: "100%",
                        marginBottom: 10,
                        padding: 8,
                      }
                  }
                >
                  Last Connection
                </Text>

                <Text
                  style={
                    isMobile
                      ? {
                        backgroundColor: "#D4E3EB",
                        width: "100%",
                        marginBottom: 10,
                        paddingTop: 11,
                        justifyContent: "flex-end",
                        fontSize: 12,
                      }
                      : {
                        backgroundColor: "#D4E3EB",
                        width: "100%",
                        marginBottom: 10,
                        padding: 8,
                        justifyContent: "flex-end",
                      }
                  }
                >
                  {isonline == true ? "Online" : item.lastConnection}
                </Text>
              </View>
            </View>
          </View>
        </TouchableOpacity>
      </View>
      // </View>
    );
  };

  function isWithinOnlineCheckTime(dtm, onlineCheckTime) {
    // Convert dtm to a Moment object
    const dtmMoment = moment(dtm, "YYYYMMDDHHmmss");

    // Get the current time
    const currentTime = moment();

    // Calculate the difference in minutes
    const differenceInMinutes = currentTime.diff(dtmMoment, "minutes");

    // Check if the difference is less than or equal to onlineCheckTime
    return differenceInMinutes < onlineCheckTime;
  }

  //IMEI based table
  const renderIMEIItemRight = (item, index) => {
    var nextIrrigationTime = "";
    var lastIrrigationTime = "";
    var lastConnection = "";
    var lastIrrigationName = "";
    let isOnline = false;
    console.log("IMEIData-----", IMEIData);

    try {
      if (IMEIData) {
        // let imeiTabDataData;
        // let dtm;
        // let stm;
        // try {
        //   imeiTabDataData = JSON.parse(imeiTabData);
        //   dtm = imeiTabDataData.dtm;
        //   stm = imeiTabDataData.stm;
        // } catch (err) {
        //   // console.log("err", err);
        // }
        // isOnline = isWithinOnlineCheckTime(dtm, onlineCheckTime);
        // if (BasedOn == 2) {
        //   nextIrrigationTime = stm;
        //   lastIrrigationTime = IMEIData.lastIrrigationTime;
        //   const parsedTimestamp = moment(dtm, 'YYYYMMDDHHmmss');
        //   const formattedTime = parsedTimestamp.format('HH:mm');
        //   lastConnection = formattedTime;
        //   lastIrrigationName = IMEIData.lastIrrigationName;
        // } else {
        //   nextIrrigationTime = IMEIData.nextIrrigationTime;
        //   lastIrrigationTime = IMEIData.lastIrrigationTime;
        //   lastConnection = IMEIData.lastConnection;
        //   lastIrrigationName = IMEIData.lastIrrigationName;
        // }
      }
    } catch (error) {
      // console.log("error", error)
    }

    return (
      <View
        style={
          isMobile
            ? {
              width: 150,
              marginBottom: 10,
              padding: 10,
              borderRadius: 10,
              marginLeft: -60,
            }
            : [styles.cardRight]
        }
      >
        <TouchableOpacity onPress={() => { }}>
          <View
            style={[
              styles.shadowProp,
              {
                backgroundColor: "#009df01f",
              },
            ]}
          >
            <View style={{ flexDirection: "row" }}>
              <View style={{ flex: 1, margin: 10 }}>
                <Image
                  style={{
                    height: 35,
                    width: 25,
                    resizeMode: "contain",
                  }}
                  source={require("../../assets/images/omega.png")}
                />
              </View>
              <View style={{ flex: 1, margin: 10 }}>
                <Text>
                  {BasedOn == 1 && BasedOn == null
                    ? item.device_name
                    : IMEIDeviceName.device_name}
                </Text>
              </View>
              <View style={{ flex: 1, alignItems: "flex-end", margin: 10 }}>
                <Icon
                  name="wifi"
                  color={IMEIData.isonline == true ? "#228B22" : "#FF0000"}
                  size={20}
                />
              </View>
            </View>

            <View style={{ flexDirection: "column", padding: 5 }}>
              <View style={{ flexDirection: "row" }}>
                <Text
                  style={
                    isMobile
                      ? {
                        backgroundColor: "#D4E3EB",
                        width: "100%",
                        marginBottom: 10,
                        padding: 8,
                        justifyContent: "flex-end",
                        fontSize: 12,
                      }
                      : {
                        backgroundColor: "#D4E3EB",
                        width: "100%",
                        marginBottom: 10,
                        padding: 8,
                      }
                  }
                >
                  {"Next " + projectTypeValue}
                </Text>

                <Text
                  style={
                    isMobile
                      ? {
                        backgroundColor: "#D4E3EB",
                        width: "100%",
                        marginBottom: 10,
                        padding: 8,
                        justifyContent: "flex-end",
                        fontSize: 12,
                        textAlign: "center",
                      }
                      : {
                        backgroundColor: "#D4E3EB",
                        width: "100%",
                        marginBottom: 10,
                        padding: 8,
                        justifyContent: "flex-end",
                      }
                  }
                >
                  {BasedOn == 2 && IMEIData.nextIrrigationTime
                    ? IMEIData.nextIrrigationTime
                    : "-"}
                </Text>
              </View>

              <View style={{ flexDirection: "row" }}>
                <Text
                  style={
                    isMobile
                      ? {
                        backgroundColor: "#D4E3EB",
                        width: "100%",
                        marginBottom: 10,
                        padding: 8,
                        justifyContent: "flex-end",
                        fontSize: 12,
                      }
                      : {
                        backgroundColor: "#D4E3EB",
                        width: "100%",
                        marginBottom: 10,
                        padding: 8,
                      }
                  }
                >
                  {"Last " + projectTypeValue}
                </Text>

                <Text
                  style={
                    isMobile
                      ? {
                        backgroundColor: "#D4E3EB",
                        width: "100%",
                        marginBottom: 10,
                        paddingTop: 11,
                        justifyContent: "flex-end",
                        fontSize: 12,
                        textAlign: "center",
                      }
                      : {
                        backgroundColor: "#D4E3EB",
                        width: "100%",
                        marginBottom: 10,
                        padding: 8,
                        justifyContent: "flex-end",
                      }
                  }
                >
                  {BasedOn == 2 && IMEIData.lastIrrigationTime
                    ? moment(IMEIData.lastIrrigationTime).format(
                      "DD-MM-YYYY HH:mm"
                    )
                    : "-"}
                </Text>
              </View>

              <View style={{ flexDirection: "row" }}>
                <Text
                  style={
                    isMobile
                      ? {
                        backgroundColor: "#D4E3EB",
                        width: "100%",
                        marginBottom: 10,
                        padding: 8,
                        justifyContent: "flex-end",
                        fontSize: 12,
                      }
                      : {
                        backgroundColor: "#D4E3EB",
                        width: "100%",
                        marginBottom: 10,
                        padding: 8,
                      }
                  }
                >
                  Last Connection
                </Text>

                <Text
                  style={
                    isMobile
                      ? {
                        backgroundColor: "#D4E3EB",
                        width: "100%",
                        marginBottom: 10,
                        paddingTop: 11,
                        justifyContent: "flex-end",
                        fontSize: 12,
                      }
                      : {
                        backgroundColor: "#D4E3EB",
                        width: "100%",
                        marginBottom: 10,
                        padding: 8,
                        justifyContent: "flex-end",
                      }
                  }
                >
                  {BasedOn == 2 && IMEIData.isonline
                    ? "Online"
                    : IMEIData12.dtm
                      ? moment(IMEIData12.dtm, "YYYYMMDDHHmmss").format(
                        "DD-MM-YYYY HH:mm"
                      )
                      : "-"}
                </Text>
              </View>
            </View>
          </View>
        </TouchableOpacity>
      </View>
      // </View>
    );
  };
  ////// Add for Extra Details Table - RohitB ////////
  const truncateText = (text, maxLength) => {
    return text.length > maxLength ? `${text.slice(0, maxLength)}...` : text;
  };

  const handleMouseEnter = (index) => {
    // setIsHovered(true);
    // console.log("Hovered Index:", index);
    setHoveredIndex(index);
  };

  const handleMouseLeave = () => {
    //setIsHovered(false);
    setHoveredIndex(-1);
  };
  const renderDetailsRight = () => {
    return (
      BasedOn == 2 && (
        <View
          style={
            isMobile
              ? {
                width: 200,
                marginBottom: 10,
                padding: 10,
                borderRadius: 10,
              }
              : [styles.cardRight]
          }
        >
          <TouchableOpacity onPress={() => { }}>
            <View
              style={[
                styles.shadowProp,
                {
                  backgroundColor: "#009df01f",
                },
              ]}
            >
              {labelValues.length > 0 && (
                <View style={{ flexDirection: "row", alignItems: "center" }}>
                  <View style={{ flex: 1, margin: 10 }}>
                    <Text
                      style={{
                        fontWeight: "500",
                        textAlign: "center",
                        fontSize: 14,
                      }}
                    >
                      Information
                    </Text>
                  </View>
                </View>
              )}

              {labelValues.length > 0 ? (
                <View style={{ flexDirection: "column", padding: 5 }}>
                  {labelValues.map((item, index) => (
                    <View style={{ flexDirection: "row" }} key={index}>
                      {item.label && item.label.trim() !== "" && (
                        <Text
                          style={
                            isMobile
                              ? {
                                backgroundColor: "#D4E3EB",
                                width: "100%",
                                marginBottom: 10,
                                padding: 8,
                                justifyContent: "flex-end",
                                fontSize: 12,
                              }
                              : {
                                backgroundColor: "#D4E3EB",
                                width: "100%",
                                marginBottom: 10,
                                padding: 8,
                              }
                          }
                          onMouseEnter={() => handleMouseEnter(index)}
                          onMouseLeave={handleMouseLeave}
                        >
                          {hoveredIndex === index ? (
                            <Tooltip
                              popover={
                                <Text
                                  style={{
                                    //backgroundColor: "#D4E3EB",
                                    width: "100%",
                                    color: "white",
                                    marginTop: 5,
                                    marginBottom: 5,
                                    padding: 8,
                                    justifyContent: "flex-end",
                                    fontSize: 14,
                                  }}
                                >
                                  {" "}
                                  {item.label}
                                </Text>
                              }
                            >
                              <Text>{truncateText(item.label, 15)}</Text>
                            </Tooltip>
                          ) : (
                            truncateText(item.label, 15)
                          )}
                        </Text>
                      )}
                      {item.value && item.value.trim() !== "" && (
                        <Text
                          style={
                            isMobile
                              ? {
                                backgroundColor: "#D4E3EB",
                                width: "100%",
                                marginBottom: 10,
                                padding: 8,
                                justifyContent: "flex-end",
                                fontSize: 12,
                                textAlign: "center",
                              }
                              : {
                                backgroundColor: "#D4E3EB",
                                width: "100%",
                                marginBottom: 10,
                                padding: 8,
                                justifyContent: "flex-end",
                              }
                          }
                          onMouseEnter={() => handleMouseEnter(index)}
                          onMouseLeave={handleMouseLeave}
                        >
                          {hoveredIndex === index ? (
                            <Tooltip
                              popover={
                                <Text
                                  style={{
                                    //backgroundColor: "#D4E3EB",
                                    width: "90%",
                                    color: "white",
                                    marginTop: 10,
                                    marginBottom: 10,
                                    padding: 8,
                                    justifyContent: "flex-end",
                                    fontSize: 12,
                                    // maxWidth: 500,
                                    // maxHeight:100,
                                  }}
                                >
                                  {" "}
                                  {item.value}
                                </Text>
                              }
                            >
                              <Text>{truncateText(item.value, 20)}</Text>
                            </Tooltip>
                          ) : (
                            truncateText(item.value, 20)
                          )}
                        </Text>
                      )}
                    </View>
                  ))}
                </View>
              ) : (
                <View></View>
              )}
            </View>
          </TouchableOpacity>
        </View>
        // </View>
      )
    );
  };

  const renderSwitchLeft = ({ item, index }: { item: any, index: any }) => {
    var mainindex = index;
    var inputNumber = Number(item?.inputNumber);

    if (!socketDetails || !socketDetails.length || !socketDetails[0]?.switches) {
      return;
    }

    const switchData = socketDetails[0]?.switches;
    console.log('switchData', item, switchData);

    if (!Array.isArray(switchData) || switchData.length === 0) {
      return;
    }
    const item_index = item?.index;
    const dash_status = item?.dash_status;
    if (!Array.isArray(dash_status) || dash_status.length < 2) {
      return;
    }
    let switch_status = '';
    for (let i = 0; i < switchData.length; i++) {
      const element = switchData[i];
      if (element.index == item_index) {
        if (element.value == 1) { // open
          switch_status = dash_status[0].new_status
        }
        if (element.value == 0) { // close
          switch_status = dash_status[1].new_status
        }
      }
    }
    return (
      <>
        {isOnline == true ? (
          <View style={{ justifyContent: "space-between" }}>
            <View
              style={{
                backgroundColor: "#009df01f",
                marginLeft: 10,
                marginBottom: 10,
                padding: 7,
                height: 100,
                width: 200,
              }}
            >
              <View
                style={{
                  flexDirection: "row",
                  width: "95%",
                  alignSelf: "flex-start",
                  marginLeft: 5,
                  marginTop: 5,
                  justifyContent: "space-between",
                }}
              >
                <Tooltip
                  popover={<Text style={{ color: "white" }}> {item?.name}</Text>}
                >
                  <Text style={{ fontWeight: "700" }}>{item?.name ? item?.name.toUpperCase() : "DOOR SWITCH"}</Text>
                </Tooltip>
              </View>
              {inputNumber === socketDetails[0]?.switches[mainindex].index && (
                <Text
                  style={{
                    alignSelf: "center",
                    fontWeight: "700",
                    fontSize: 25,
                    color:
                      item.level == "Open"
                        ? "#0fbf00"
                        : item.level == "Close"
                          ? "#bababa"
                          : "#000000",
                    textAlign: "center",
                    marginVertical: 5,
                    position: "absolute",
                    bottom: 5
                  }}
                >
                  {switch_status}
                </Text>
              )}
            </View>
          </View>
        ) : (
          <View style={{ justifyContent: "space-between" }}>
            <View
              style={{
                backgroundColor: "#009df01f",
                marginLeft: 10,
                marginBottom: 10,
                padding: 7,
                height: 100,
                width: 200,
              }}
            >
              <View
                style={{
                  flexDirection: "row",
                  width: "95%",
                  alignSelf: "flex-start",
                  marginLeft: 5,
                  marginTop: 5,
                  justifyContent: "space-between",
                }}
              >
                <Tooltip
                  popover={<Text style={{ color: "white" }}> {item?.name}</Text>}
                >
                  <Text style={{ fontWeight: "700" }}>{item?.name}</Text>
                </Tooltip>
              </View>
              <Text
                style={{
                  alignSelf: "center",
                  fontWeight: "700",
                  fontSize: 25,
                  color:
                    item.level == "Open"
                      ? "#0fbf00"
                      : item.level == "Close"
                        ? "#bababa"
                        : "#000000",
                  textAlign: "center",
                  marginVertical: 5,
                  position: "absolute",
                  bottom: 5
                }}
              >
                --
              </Text>
            </View>
          </View>
        )}
      </>
    );
  };

  const renderSwitchImeiLeft = ({ item, index }: { item: any, index: any }) => {
    
    const switchData = switchsDetails;
    if (!Array.isArray(switchData) || switchData.length === 0) {
      return;
    }
    const dash_status = switchData[0].dash_status;
    if (!Array.isArray(dash_status) || dash_status.length < 2) {
      return;
    }
    let switch_status = '';
    if (IMEIData12?.tamp == 1) { // open
      switch_status = dash_status[0].new_status
    } else if (IMEIData12?.tamp == 0) { // close
      switch_status = dash_status[1].new_status
    }

    return (
      <>
        {(isOnline == true && switch_status) ? (
          <View style={{ justifyContent: "space-between" }}>
            <View
              style={{
                backgroundColor: "#009df01f",
                marginLeft: 10,
                marginBottom: 10,
                padding: 7,
                height: 100,
                width: 200,
              }}
            >
              <View
                style={{
                  flexDirection: "row",
                  width: "95%",
                  alignSelf: "flex-start",
                  marginLeft: 5,
                  marginTop: 5,
                  justifyContent: "space-between",
                }}
              >
                <Tooltip
                  popover={<Text style={{ color: "white" }}> {item?.name}</Text>}
                >
                  <Text style={{ fontWeight: "700" }}>{item?.name ? item?.name.toUpperCase() : "DOOR SWITCH"}</Text>
                </Tooltip>
              </View>

              <Text
                style={{
                  alignSelf: "center",
                  fontWeight: "700",
                  fontSize: 25,
                  color:
                    item.level == "Open"
                      ? "#0fbf00"
                      : item.level == "Close"
                        ? "#bababa"
                        : "#000000",
                  textAlign: "center",
                  marginVertical: 5,
                  position: "absolute",
                  bottom: 15
                }}
              >
                {switch_status}
              </Text>
            </View>
          </View>
        ) : (
          <View style={{ justifyContent: "space-between" }}>
            <View
              style={{
                backgroundColor: "#009df01f",
                marginLeft: 10,
                marginBottom: 10,
                padding: 7,
                height: 100,
                width: 200,
              }}
            >
              <View
                style={{
                  flexDirection: "row",
                  width: "95%",
                  alignSelf: "flex-start",
                  marginLeft: 5,
                  marginTop: 5,
                  justifyContent: "space-between",
                }}
              >
                <Tooltip
                  popover={<Text style={{ color: "white" }}> {item?.name}</Text>}
                >
                  <Text style={{ fontWeight: "700" }}>{item?.name}</Text>
                </Tooltip>
              </View>
              <Text
                style={{
                  alignSelf: "center",
                  fontWeight: "700",
                  fontSize: 25,
                  color:
                    item.level == "Open"
                      ? "#0fbf00"
                      : item.level == "Close"
                        ? "#bababa"
                        : "#000000",
                  textAlign: "center",
                  marginVertical: 5,
                  position: "absolute",
                  bottom: 5
                }}
              >
                --
              </Text>
            </View>
          </View>
        )}
      </>
    );
  };

  const WTImeiData = [waterIMEIData[0]]

  return (
    <View style={styles.MainContainer}>
      <SuccessPopup
        visible={issuccessVisible}
        PopupMessage={PopupMessage}
        Responsestatus={responsestatus}
        onChange={() => setissuccessVisible(false)}
      />
      <Modal
        onRequestClose={() => setValveIsVisible(false)}
        transparent={true}
        visible={ValveIsVisible}
      >
        <View
          style={{
            position: "absolute",
            width: "100%",
            height: "100%",
            justifyContent: "center",
            alignItems: "center",
            alignContent: "center",
            backgroundColor: "rgba(100,100,100, 0.5)",
          }}
        >
          <View style={[styles.containeralt]}>
            <TouchableOpacity
              style={{ alignSelf: "flex-end" }}
              onPress={() => setValveIsVisible(false)}
            >
              <IconCross
                name="circle-with-cross"
                size={20}
                color="#000"
                style={{ bottom: 5, top: 10 }}
              />
            </TouchableOpacity>

            <Text style={{ alignSelf: "center", fontWeight: "700" }}>
              Set Amount
            </Text>
            <View
              style={{
                flexDirection: "row",
                flex: 1,
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <RadioButton
                uncheckedColor={"#707070"}
                color={"#707070"}
                value="Duration"
                status={isMeasureType == 2 ? "checked" : "unchecked"}
                onPress={() => setMeasureType(2)}
              />
              <Text style={{ fontSize: 12, color: "#707070" }}>Duration</Text>
              <RadioButton
                color={"#707070"}
                uncheckedColor={"#707070"}
                value="Quantity"
                status={isMeasureType == 1 ? "checked" : "unchecked"}
                onPress={() => setMeasureType(1)}
              />
              <Text style={{ fontSize: 12, color: "#707070" }}>Quantity</Text>
            </View>

            <View
              style={{ width: "90%", alignSelf: "center", marginBottom: 10 }}
            >
              {isMeasureType == 2 ? (
                <View
                  style={{
                    flexDirection: "row",
                    alignSelf: "center",
                    alignItems: "center",
                  }}
                >
                  <View
                    style={{ flexDirection: "column", alignItems: "center" }}
                  >
                    <TouchableOpacity
                      onPress={() => timeHr < 23 && settimeHr(timeHr + 1)}
                    >
                      <Text style={{ margin: 5 }}>
                        <IconTimer
                          color="#767676"
                          name="chevron-thin-up"
                          size={20}
                        />
                      </Text>
                    </TouchableOpacity>

                    <Text
                      style={{
                        borderWidth: 1,
                        margin: 5,
                        width: 40,
                        alignContent: "center",
                        textAlign: "center",
                        alignItems: "center",
                        alignSelf: "center",
                        borderColor: "#707070",
                        borderRadius: 6,
                      }}
                    >
                      {timeHr < 10 ? `0${timeHr}` : timeHr}{" "}
                    </Text>

                    <TouchableOpacity
                      onPress={() => timeHr > 0 && settimeHr(timeHr - 1)}
                    >
                      <Text style={{ margin: 5 }}>
                        <IconTimer
                          color="#767676"
                          name="chevron-thin-down"
                          size={20}
                        />
                      </Text>
                    </TouchableOpacity>
                  </View>
                  <Text style={{ marginHorizontal: 5 }}>:</Text>
                  <View
                    style={{ flexDirection: "column", alignItems: "center" }}
                  >
                    <TouchableOpacity
                      onPress={() => timemin < 59 && settimemin(timemin + 1)}
                    >
                      <Text style={{ margin: 5 }}>
                        <IconTimer
                          color="#767676"
                          name="chevron-thin-up"
                          size={20}
                        />
                      </Text>
                    </TouchableOpacity>

                    <Text
                      style={{
                        borderWidth: 1,
                        margin: 5,
                        width: 40,
                        alignContent: "center",
                        textAlign: "center",
                        alignItems: "center",
                        borderColor: "#707070",
                        borderRadius: 6,
                      }}
                    >
                      {timemin < 10 ? `0${timemin}` : timemin}
                    </Text>

                    <TouchableOpacity
                      onPress={() => timemin > 0 && settimemin(timemin - 1)}
                    >
                      <Text style={{ margin: 5 }}>
                        <IconTimer
                          color="#767676"
                          name="chevron-thin-down"
                          size={20}
                        />
                      </Text>
                    </TouchableOpacity>
                  </View>
                  <Text style={{ marginHorizontal: 5 }}>:</Text>
                  <View
                    style={{ flexDirection: "column", alignItems: "center" }}
                  >
                    <TouchableOpacity
                      onPress={() => timesec < 59 && settimesec(timesec + 1)}
                    >
                      <Text style={{ margin: 5 }}>
                        <IconTimer
                          color="#767676"
                          name="chevron-thin-up"
                          size={20}
                        />
                      </Text>
                    </TouchableOpacity>

                    <Text
                      style={{
                        borderWidth: 1,
                        margin: 5,
                        width: 40,
                        alignContent: "center",
                        textAlign: "center",
                        alignItems: "center",
                        borderColor: "#707070",
                        borderRadius: 6,
                      }}
                    >
                      {timesec < 10 ? `0${timesec}` : timesec}
                    </Text>

                    <TouchableOpacity
                      onPress={() => timemin > 0 && settimesec(timesec - 1)}
                    >
                      <Text style={{ margin: 5 }}>
                        <IconTimer
                          color="#767676"
                          name="chevron-thin-down"
                          size={20}
                        />
                      </Text>
                    </TouchableOpacity>
                  </View>
                </View>
              ) : (
                <TextInput
                  style={styles.input}
                  placeholder="Enter Amount"
                  value={valveamount}
                  autoFocus={true}
                  onChangeText={(text) => setvalveamount(text)}
                />
              )}
            </View>

            <View
              style={{
                flexDirection: "row",
                justifyContent: "space-evenly",
                marginHorizontal: 20,
              }}
            >
              <TouchableOpacity
                style={styles.saveBtn}
                onPress={() => {
                  getCounts(1);
                  setValveIsVisible(false);
                }}
              >
                <Text style={styles.btnText}>Save</Text>
              </TouchableOpacity>

              <TouchableOpacity
                style={styles.saveBtn}
                onPress={() => {
                  setValveIsVisible(false);
                }}
              >
                <Text style={styles.btnText}>Cancel</Text>
              </TouchableOpacity>
            </View>
          </View>
        </View>
      </Modal>
      <View style={{ flexDirection: "row", width: "100%", marginTop: "-1%" }}>
        <View style={{ flex: 1, marginHorizontal: 10 }}>
          <Text style={styles.lableText}>
            Monitor {">"}
            <Text onPress={() => navigation.navigate("GridView")}>
              Grid View
            </Text>
            {">"} {project_name} {">"} {OmegaName}
          </Text>
        </View>
      </View>
      {isLoadingSync ? (
        <ActivityIndicator
          color="#1DA1F2"
          visible={isLoadingSync}
          textContent={"Loading..."}
          size={100}
        />
      ) : (
        <View>
          <View
            style={{
              flexDirection: "row",
              marginTop: "0%",
              flexWrap: "wrap-reverse",
            }}
          >
            <MenuProvider
              style={{
                flexDirection: "row",
                flexWrap: "wrap",
              }}
            >
              {/* {valveDetails != null ?
                (
                  valveDetails.length > 0 && (
                    valveDetails.map((item, index) => {
                      return renderValveItem(item, index);
                    })
                  )
                )
                :
                (
                  // valveDetails.length == 0 && (
                  <View style={{ justifyContent: "center" }}>
                    <Text
                      style={[
                        styles.emptyListStyle,
                        { alignSelf: "center", marginTop: 20 },
                      ]}
                    >
                      No Valve Data Found{" "}
                    </Text>
                  </View>
                  // )
                )
              } */}
              {BasedOn == 2 ? (
                ValveIMEIData &&
                ValveIMEIData.length > 0 &&
                ValveIMEIData.map((item, index) =>
                  renderValveIMEIItem(item, index)
                )
              ) : valveDetails != null ? (
                valveDetails.length > 0 &&
                valveDetails.map((item, index) => renderValveItem(item, index))
              ) : (
                <View style={{ justifyContent: "center" }}>
                  <Text
                    style={[
                      styles.emptyListStyle,
                      { alignSelf: "center", marginTop: 20 },
                    ]}
                  >
                    No Valve Data Found{" "}
                  </Text>
                </View>
              )}
            </MenuProvider>

            <View
              style={{
                flexDirection: "row",
                width: "25%",
              }}
            >
              {BasedOn == 2
                ? renderIMEIItemRight(IMEIData, 0)
                : OmegaDetailsGrid.map((item, index) =>
                  renderItemRight(item, index)
                )}
              {/* {OmegaDetailsGrid.map((item, index) => {
                return renderItemRight(item, index);
              })} */}
            </View>
          </View>
          <View
            style={{
              marginTop: "2%",
              width: "100%",
              //flexDirection: "row",
              flexDirection: isMobile ? "column" : "row", // add for Extra details - RohitB
              // justifyContent: "space-between",
              // alignItems: "flex-start",
            }}
          >
            <View
              style={{
                flexDirection: "row",
                flexWrap: "wrap",
              }}
            >
              {BasedOn == 2 ? (
                <FlatList
                  numColumns={isMobile ? 1 : 2}
                  //style={{ width: "75%" }}
                  style={{ width: isMobile ? "100%" : "75%" }} // add For Extradetails table- RohitB
                  contentContainerStyle={{
                    padding: 5,
                  }}
                  // horizontal
                  data={pressureIMEIData}
                  renderItem={renderPressureIMEILeft}
                />
              ) : (
                <FlatList
                  numColumns={isMobile ? 1 : 2}
                  //style={{ width: "75%" }}
                  style={{ width: isMobile ? "100%" : "75%" }} // add For Extradetails table- RohitB
                  contentContainerStyle={{
                    padding: 5,
                  }}
                  // horizontal
                  data={analogDetails}
                  renderItem={renderItemLeft}
                />
              )}
            </View>
            <View
              style={{
                //flexDirection: "row",
                flexWrap: "wrap",
                flexDirection: isMobile ? "column" : "row", // add For Extradetails table- RohitB
                // marginRight: "14%",
                //  //marginRight: isMobile ? 0 : "14%"
              }}
            >
              {BasedOn == 2 ? (
                <FlatList
                  numColumns={isMobile ? 1 : 2}
                  //style={{ width: "75%", }}
                  style={{ width: isMobile ? "100%" : "75%" }} // add For Extradetails table- RohitB
                  contentContainerStyle={{
                    padding: 5,
                  }}
                  // horizontal
                  data={WTImeiData}
                  renderItem={renderWaterMtIMEILeft}
                />
              ) : (
                <FlatList
                  //numColumns={3}
                  numColumns={isMobile ? 1 : 2} // add For Extradetails table- RohitB
                  //style={{ width: "75%", }}
                  style={{ width: isMobile ? "100%" : "75%" }} // add For Extradetails table- RohitB
                  contentContainerStyle={{
                    padding: 5,
                  }}
                  // horizontal
                  data={waterMeterDetails}
                  renderItem={renderWaterMtLeft}
                />
              )}
            </View>
            <View
              style={{
                //flexDirection: "row",
                flexWrap: "wrap",
                flexDirection: isMobile ? "column" : "row", // add For Extradetails table- RohitB
                // marginRight: "14%",
                // backgroundColor:"red"
                //  //marginRight: isMobile ? 0 : "14%"
              }}
            >
              {BasedOn == 1 ? (
                <FlatList
                  //numColumns={3}
                  numColumns={isMobile ? 1 : 2} // add For Extradetails table- RohitB
                  //style={{ width: "75%", }}
                  style={{ width: isMobile ? "100%" : "75%" }} // add For Extradetails table- RohitB
                  contentContainerStyle={{
                    padding: 5,
                  }}
                  // horizontal
                  data={switchsDetails}
                  renderItem={renderSwitchLeft}
                />
              ) : (
                <FlatList
                  //numColumns={3}
                  numColumns={isMobile ? 1 : 2} // add For Extradetails table- RohitB
                  //style={{ width: "75%", }}
                  style={{ width: isMobile ? "100%" : "75%" }} // add For Extradetails table- RohitB
                  contentContainerStyle={{
                    padding: 5,
                  }}
                  // horizontal
                  data={switchIMEIData}
                  renderItem={renderSwitchImeiLeft}
                />
              )}
            </View>
            <View
              style={{
                // add For Extradetails table- RohitB
                flexDirection: "row",
                width: "25%",
                //marginLeft:"-20px",
                // justifyContent:'flex-end',
                position: "absolute",
                // bottom:0,
                right: 0,
                marginLeft: 10,
              }}
            >
              {renderDetailsRight()}
            </View>
          </View>
        </View>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  MainContainer: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    flex: 1,
    padding: 20,
  },
  lableText: {
    color: "#484848",
    fontWeight: "700",
  },
  btnTextCancel: {
    color: "#747474",
    fontWeight: "600",
    textAlign: "center",
  },
  mapStyle: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
  input: {
    borderColor: "gray",
    width: "100%",
    borderWidth: 1,
    borderRadius: 10,
    // marginLeft: 20,
    padding: 10,
  },
  btnText: {
    color: "#fff",
    fontWeight: "600",
  },
  saveBtn: {
    alignItems: "center",
    backgroundColor: "#006EB9",
    padding: 10,
    borderWidth: 1,
    borderColor: "#006EB9",
    width: "50%",
    marginRight: 10,
  },
  containeralt: {
    // alignItems: "center",
    backgroundColor: "#F8F8F8",
    borderColor: "#eee",
    borderRadius: 10,
    borderWidth: 1,
    // justifyContent: "center",
    margin: "auto",
    padding: 30,
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
    width: "30%",
  },
  emptyListStyle: {
    padding: 10,
    fontSize: 20,
    textAlign: "center",
  },

  // cardLeft: {
  //   width: "15%",
  //   // flex: 0.5,

  //   marginVertical: 10,
  //   padding: 10,
  //   // flexDirection: 'row'
  //   borderRadius: 10,
  // },
  cardRight: {
    width: "100%",
    // flex: 0.5,
    marginBottom: 10,
    padding: 10,
    // flexDirection: 'row'
    borderRadius: 10,
  },
  shadowProp: {
    shadowColor: "#171717",
    shadowOffset: { width: -2, height: 4 },
    shadowOpacity: 0.2,
    shadowRadius: 5,
  },
});
