import {
  StyleSheet,
  TouchableOpacity,
  Image,
  Modal,
  Dimensions,
} from "react-native";
import type { PropsWithChildren } from 'react';
import DoubleClick from "react-native-double-tap"
import IconCross from "react-native-vector-icons/Entypo";

import { Text, View } from "../../components/Themed";
import Svg, { G, Circle, Rect } from "react-native-svg";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { CreateResponsiveStyle, DEVICE_SIZES, useDeviceSize } from 'rn-responsive-styles'

import Animated, {
  useSharedValue,
  useAnimatedStyle,
  withDelay,
  withRepeat,
  withTiming,
} from "react-native-reanimated";
import GoogleMapReact from "google-map-react";
import { useFocusEffect } from "@react-navigation/native";
import { baseUrl, getMethod, postData } from "../../networking/api";
import Icon from "react-native-vector-icons/FontAwesome";
// import { VictoryLabel, VictoryPie } from "victory-native"
// export const VictoryPie = require('victory').VictoryPie;
import victory from "../../constants/victory";
import LiquidFillGauge from "react-liquid-gauge";
import { color } from "d3-color";
import { interpolateRgb } from "d3-interpolate";

import React, { FC, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Dropdown } from "react-native-element-dropdown";
import { Keyboard } from "react-native";
import IconSelect from "react-native-vector-icons/AntDesign";
import { FlatList } from "react-native";
import { TextInput } from "react-native";


export default function HomeScreen({
  navigation,
}) {
  const [userType, setUserType] = useState();
  useEffect(() => {
    const fetchUserType = async () => {
      try {
        const userTypeValue = await AsyncStorage.getItem('userTypes');
        //console.log("userTypeValue",userTypeValue);
        if (userTypeValue !== null) {
          console.log("userTypeValue", userTypeValue);
          setUserType(JSON.parse(userTypeValue));
        }
      } catch (error) {
        console.error('Error fetching userType from AsyncStorage:', error);
      }
    };

    fetchUserType();
  }, []);

  const size = 200;
  const strokeWidth = 50;
  const [counter, setCounter] = useState(0);
  //dropdown omega id
  const [isVisible, setIsVisible] = useState(false);
  // const styles = useStyles()
  const dropdownRefZone = useRef();

  const deviceSize = useDeviceSize()
  // console.log("device sizeeee==", deviceSize)
  const [OmegaIdData, setOmegaIdData] = useState([]);
  const [showPopover, setShowPopover] = useState(false);
  const [OmegaIdGet, setOmegaIdGet] = useState("");
  const [centerLat, setCenterLat] = useState(0);
  const [centerLong, setCenterLong] = useState(0);
  const [isLoadingSync, setIsLoadingSync] = useState(false);
  const [centerLatLong, setCenterLatLong] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [projectId, setProjectId] = useState("");
  // const [isLoading, setIsLoading] = useState(false);
  const [rowGap, setRowGap] = useState(10);
  const [columnGap, setColumnGap] = useState(10);
  const [isstatuschecked, setStatusChecked] = useState(1);

  const [valveDetails, setValveDetails] = useState([]);
  const [waterMeterDetails, setWaterMeterDetails] = useState([]);
  const [analogDetails, setAnalogDetails] = useState([]);

  const [totalFlowName, setTotalFlowName] = useState("");
  const [valveName, setValveName] = useState("");
  const [rtuDeviceName, setRTUDeviceName] = useState("");
  const [energyStatusName, setEnergyStatusName] = useState("");
  const [sensorAnalog1Name, setSensorAnalog1Name] = useState("");
  const [sensorAnalog2Name, setSensorAnalog2Name] = useState("");
  const [sensorDigital1Name, setSensorDigital1Name] = useState("");
  const [sensorDigital2Name, setSensorDigital2Name] = useState("");
  const [sensorDigital3Name, setSensorDigital3Name] = useState("");
  const [sensorDigital4Name, setSensorDigital4Name] = useState("");
  const [sensorDigital5Name, setSensorDigital5Name] = useState("");
  const [sensorDigital6Name, setSensorDigital6Name] = useState("");
  const [sensorDigital7Name, setSensorDigital7Name] = useState("");
  const [sensorDigital8Name, setSensorDigital8Name] = useState("");

  let defaultProps = {};

  const pieData = [
    { value: 54, color: "#177AD5", text: "54%" },
    { value: 40, color: "#79D2DE", text: "30%" },
    { value: 20, color: "#ED6665", text: "26%" },
  ];
  const [users, setUsers] = useState("");
  var idProject = useSelector((state) => state.idUpdating);

  const [valvesArrayData, setvalvesArrayData] = useState([]);

  const [valvesArray, setValvesArray] = useState([]);
  const [valvesArrayColor, setValvesArrayColor] = useState([]);
  const [valvesArrayCount, setValvesArrayCount] = useState(0);
  const [devicesArrayData, setDevicesArrayData] = useState([]);

  const [devicesArray, setDevicesArray] = useState([]);
  const [devicesArrayColor, setDevicesArrayColor] = useState([]);
  const [devicesArrayCount, setDevicesArrayCount] = useState(0);

  const [analogArrayData, setanalogArrayData] = useState([]);

  const [analog1Array, setanalog1Array] = useState([]);
  const [analog1ArrayColor, setanalog1ArrayColor] = useState([]);
  const [analog1ArrayCount, setanalog1ArrayCount] = useState(0);

  const [analog2Array, setanalog2Array] = useState([]);
  const [analog2ArrayColor, setanalog2ArrayColor] = useState([]);
  const [analog2ArrayCount, setanalog2ArrayCount] = useState(0);

  const [digitalArray, setdigitalArray] = useState([]);
  const [digitalArrayData, setdigitalArrayData] = useState([]);

  const [digital1Array, setdigital1Array] = useState([]);
  const [digital1ArrayColor, setdigital1ArrayColor] = useState([]);
  const [digital1ArrayCount, setdigital1ArrayCount] = useState(0);

  const [digital2Array, setdigital2Array] = useState([]);
  const [digital2ArrayColor, setdigital2ArrayColor] = useState([]);
  const [digital2ArrayCount, setdigital2ArrayCount] = useState(0);

  const [digital3Array, setdigital3Array] = useState([]);
  const [digital3ArrayColor, setdigital3ArrayColor] = useState([]);
  const [digital3ArrayCount, setdigital3ArrayCount] = useState(0);

  const [digital4Array, setdigital4Array] = useState([]);
  const [digital4ArrayColor, setdigital4ArrayColor] = useState([]);
  const [digital4ArrayCount, setdigital4ArrayCount] = useState(0);

  const [digital5Array, setdigital5Array] = useState([]);
  const [digital5ArrayColor, setdigital5ArrayColor] = useState([]);
  const [digital5ArrayCount, setdigital5ArrayCount] = useState(0);

  const [digital6Array, setdigital6Array] = useState([]);
  const [digital6ArrayColor, setdigital6ArrayColor] = useState([]);
  const [digital6ArrayCount, setdigital6ArrayCount] = useState(0);

  const [digital7Array, setdigital7Array] = useState([]);
  const [digital7ArrayColor, setdigital7ArrayColor] = useState([]);
  const [digital7ArrayCount, setdigital7ArrayCount] = useState(0);

  const [digital8Array, setdigital8Array] = useState([]);
  const [digital8ArrayColor, setdigital8ArrayColor] = useState([]);
  const [digital8ArrayCount, setdigital8ArrayCount] = useState(0);
  const [energyStatusArrayData, setenergyStatusArrayData] = useState([]);
  const [energyStatusArray, setenergyStatusArray] = useState([]);
  const [energyStatusArrayColor, setenergyStatusArrayColor] = useState([]);
  const [energyStatusArrayCount, setenergyStatusArrayCount] = useState(0);
  const [actualFlow, setActualFlow] = useState(0);
  const [totalFlow, setTotalFlow] = useState(0);
  const [flowCounts, setFlowCount] = useState(0);

  const [ZoneNameData, setZoneNameData] = useState([]);
  const [ZoneValue, setZoneValue] = useState(null);
  const [ZoneValueId, setZoneValueId] = useState();
  const [isAdd, setisAdd] = useState(false);
  const [SubzoneValue, setSubzoneValue] = useState();
  const [SubzoneValueId, setSubzoneValueId] = useState();
  const [subzoneData, setSubzoneData] = useState([]);
  const [isFocus, setIsFocus] = useState(false);
  const [showSubzoneDropdown, setShowSubzoneDropdown] = useState(false);
  const [userTokenValue, setuserTokenValue] = useState('');
  const [OmegaDropdownData, setOmegaDropdownData] = useState([]);
  const [OmegaValue, setOmegaValue] = useState<any>({});
  const [switchDashboard, setSwitchDashboard] = useState<any>([]);
  const [waterMeterDashboard, setWaterMeterDashboard] = useState<any>([]);
  const [searchData, setSearchData] = useState<any>([]);
  const [searchQuery, setSearchQuery] = useState("")

  const screenwidth = Dimensions.get('window').width
  const isMobile = screenwidth < 768;

  //   const switchDashboard = [
  //     {
  //         "id": "switchStatus1",
  //         "dashBoardName": "DOOR SWITCH",
  //         "color": [
  //             "#558bff",
  //             "#ff675a",
  //             "#03fc20"
  //         ],
  //         "switchResponse": [
  //             {
  //                 "x": 40,
  //                 "y": 40,
  //                 "status": 1,
  //                 "statusName": "Closed",
  //                 "index": 1
  //             },
  //             {
  //                 "x": 10,
  //                 "y": 10,
  //                 "status": 0,
  //                 "statusName": "Open",
  //                 "index": 1
  //             },
  //             {
  //                 "x": 170,
  //                 "y": 170,
  //                 "status": 2,
  //                 "statusName": "Offline",
  //                 "index": 1
  //             }
  //         ]
  //     },
  //     {
  //         "id": "switchStatus2",
  //         "dashBoardName": "DP SWITCH",
  //         "color": [
  //             "#558bff",
  //             "#ff675a",
  //             "#03fc20"
  //         ],
  //         "switchResponse": [
  //             {
  //                 "x": 45,
  //                 "y": 45,
  //                 "status": 1,
  //                 "statusName": "Closed",
  //                 "index": 2
  //             },
  //             {
  //                 "x": 0,
  //                 "y": 0,
  //                 "status": 0,
  //                 "statusName": "Open",
  //                 "index": 2
  //             },
  //             {
  //                 "x": 170,
  //                 "y": 170,
  //                 "status": 2,
  //                 "statusName": "Offline",
  //                 "index": 2
  //             }
  //         ]
  //     }
  // ]

  const startColor = "#009df0"; // cornflowerblue
  const endColor = "#009df0"; // crimson
  const radius = 80;
  const interpolate = interpolateRgb(startColor, endColor);
  // console.log("flowCounts", flowCounts);
  const fillColor = interpolate(flowCounts / 100);
  const gradientStops = [
    {
      key: "0%",
      stopColor: color(fillColor).darker(0.5).toString(),
      stopOpacity: 1,
      offset: "0%",
    },
    {
      key: "50%",
      stopColor: fillColor,
      stopOpacity: 0.75,
      offset: "50%",
    },
    {
      key: "100%",
      stopColor: color(fillColor).brighter(0.5).toString(),
      stopOpacity: 0.5,
      offset: "100%",
    },
  ];

  const progress = useSharedValue(0);
  const [data, setData] = React.useState<PieChartData>([]);
  const [startAngles, setStartAngles] = React.useState<number[]>([]);
  const center = size / 2;
  const radius2 = (size - strokeWidth) / 2;
  const circumference = 2 * Math.PI * radius2;

  const generatePieChartData = () => {
    const itemsCount = Math.floor(Math.random() * 7) + 3;
    const value = [];
    for (let i = 0; i < itemsCount; i++) {
      value.push(Math.floor(Math.random() * 60) + 40);
    }
    const total = value.reduce((a, b) => a + b, 0);
    const data: PieChartData = [];
    for (let i = 0; i < itemsCount; i++) {
      const percent = value[i] / total;
      data.push({
        percent,
        color: getRandomColor(),
      });
    }
    return data;
  };

  const getRandomColor = () => {
    let letters = "0123456789ABCDEF";
    let color = "#";
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };

  const refresh = () => {
    const generatedData = generatePieChartData();
    let angle = 0;
    const angles: number[] = [];
    generatedData.forEach((item) => {
      angles.push(angle);
      angle += item.percent * 360;
    });
    setData(generatedData);
    setStartAngles(angles);
    progress.value = 0;
    progress.value = withTiming(1, {
      duration: 1000,
    });
  };

  const onscrollModalOff = useRef();
  const closeModal = () => {
    onscrollModalOff.current.close();
  };

  useEffect(() => {
    window.addEventListener("scroll", closeModal);
    return () => window.removeEventListener("scroll", closeModal, setIsVisible(false));
  }, []);

  useEffect(() => {
    refresh();
  }, []);

  useFocusEffect(
    React.useCallback(() => {
      getAdminData();
    }, [idProject, userType])
  );

  const Ring = ({ delay }) => {
    const ring = useSharedValue(0);
    const ringStyle = useAnimatedStyle(() => {
      return {
        opacity: 0.8 - ring.value,
        transform: [
          {
            scale: interpolate(ring.value, [0, 1], [0, 4]),
          },
        ],
      };
    });
    useEffect(() => {
      ring.value = withDelay(
        delay,
        withRepeat(
          withTiming(1, {
            duration: 4000,
          }),
          -1,
          false
        )
      );
    }, []);
    return <Animated.View style={[styles.ring, ringStyle]} />;
  };
  //console.log("userType",userType);
  const getAdminData = async () => {
    let jsonValue = await AsyncStorage.getItem("userData");
    var userDetailsresponse = JSON.parse(jsonValue);
    var default_project_id = "";
    console.log("userType", userType);
    if (userType == 1) {
      //admin
      default_project_id = userDetailsresponse.project_selected;
      //console.log("Adminn...",userDetailsresponse.project_selected)
    } else {
      //farmer
      default_project_id = userDetailsresponse.project_id;
      console.log("default_project_id", default_project_id);
    }
    let projValue = await AsyncStorage.getItem("projectData");
    const userToken = await AsyncStorage.getItem("userToken");
    console.log("userDetailsresponse", userDetailsresponse);
    setuserTokenValue(userToken)
    if (projValue != null) {
      setProjectId(projValue);
      valveCountDashboard(projValue, userToken, 0, 0);
      deviceCountDashboard(projValue, userToken, 0, 0);
      analogCountDashboard(projValue, userToken, 0, 0);
      actualFlowDashboard(projValue, userToken, 0, 0);
      energyStatusDashboard(projValue, userToken, 0, 0);
      DigitalCountDashboard(projValue, userToken, 0, 0);
      DigitalCount3To8Dashboard(projValue, userToken, 0, 0);
      getOmegaDependentByProjectId(projValue);
      getOmegaDependentDropdownByProjectId(projValue)
      getSwitchDashboard(projValue, userToken, 0, 0);
      getWaterMeterDashboard(projValue, userToken, 0, 0);
    } else if (default_project_id != null) {
      setProjectId(default_project_id);
      valveCountDashboard(default_project_id, userToken, 0, 0);
      deviceCountDashboard(default_project_id, userToken, 0, 0);
      analogCountDashboard(default_project_id, userToken, 0, 0);
      actualFlowDashboard(default_project_id, userToken, 0, 0);
      energyStatusDashboard(default_project_id, userToken, 0, 0);
      DigitalCountDashboard(default_project_id, userToken, 0, 0);
      DigitalCount3To8Dashboard(
        default_project_id,
        userToken, 0, 0
      );
      getOmegaDependentByProjectId(default_project_id);
      getOmegaDependentDropdownByProjectId(default_project_id)
      getSwitchDashboard(default_project_id, userToken, 0, 0);
      getWaterMeterDashboard(default_project_id, userToken, 0, 0);
    }
  };

  // all Dashborad zone and subzone wise data
  const zoneAndSubzoneWiseDashborad = (projectId, userTokenValue, zoneId, subzoneId) => {
    console.log("projectId, userTokenValue, zoneId, subzoneId", projectId, userTokenValue, zoneId, subzoneId);

    actualFlowDashboard(projectId, userTokenValue, zoneId, subzoneId)
    valveCountDashboard(projectId, userTokenValue, zoneId, subzoneId)
    deviceCountDashboard(projectId, userTokenValue, zoneId, subzoneId);
    energyStatusDashboard(projectId, userTokenValue, zoneId, subzoneId);
    analogCountDashboard(projectId, userTokenValue, zoneId, subzoneId);
    DigitalCountDashboard(projectId, userTokenValue, zoneId, subzoneId);
    DigitalCount3To8Dashboard(projectId, userTokenValue, zoneId, subzoneId);
    getSwitchDashboard(projectId, userTokenValue, zoneId, subzoneId);
    getWaterMeterDashboard(projectId, userTokenValue, zoneId, subzoneId);
  }

  const getOmegaDependentDropdownByProjectId = async (project_id: any) => {
    setIsLoading(true);
    try {
      var OmegaIdData = JSON.stringify({
        column: "project_id",
        value: project_id,
        module: "devices_master",
      });
      const OmegaIdList = await postData(
        OmegaIdData,
        "masters/getDependentMasterList"
      );
      setIsLoading(false);
      var code = OmegaIdList.code;
      if (code == 1) {
        setSearchData(OmegaIdList.data);
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };
  const handleSearch = async (query: string) => {
    setSearchQuery(query);
    console.log("query", query);
    if (query.trim()) {
      const searchResultData = search(searchData, query);
      setOmegaDropdownData(searchResultData);
    }
  };

  const search = (data: any[], query: string): any[] => {
    const lowerCaseQuery = query.toLowerCase();

    return data.filter(item =>
      item?.device_name?.toLowerCase().includes(lowerCaseQuery)
    );
  };

  //Valve values shown in pie chart
  const valveCountDashboard = async (project_id, userToken, zoneId, subzoneId) => {
    const api = `${baseUrl}dashboards/valvesDash/${project_id}/${zoneId}/${subzoneId}`;

    try {
      const response = await fetch(api, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: userToken,
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const responseJson = await response.json();
      const valvesResponse = responseJson.data.valvesResponse;
      const sum = valvesResponse.reduce((total: any, valve: any) => total + valve.x, 0);

      setValvesArray(valvesResponse);
      setValvesArrayColor(responseJson.data.color);
      setValveName(responseJson.data.dashboard_name);
      setValvesArrayCount(sum);
      setvalvesArrayData(responseJson.data);
    } catch (error) {
      console.error("valve error", error);
    }
  };

  //Valve values shown in pie chart
  const deviceCountDashboard = async (project_id, userToken, zoneId, subzoneId) => {
    try {
      const response = await fetch(
        `${baseUrl}dashboards/RTUDeviceStatusNew/${project_id}/${zoneId}/${subzoneId}`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: userToken,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const responseJson = await response.json();

      setRTUDeviceName(responseJson.data.dashboard_name);
      setDevicesArray(responseJson.data.deviceStatusResponse);
      setDevicesArrayColor(responseJson.data.color);

      const sum = responseJson.data.deviceStatusResponse.reduce(
        (accumulator, device) => accumulator + device.x,
        0
      );

      setDevicesArrayCount(sum);
      setDevicesArrayData(responseJson.data);
    } catch (error) {
      console.error("device error", error);
    }
  };

  //ANALOG1 values shown in pie chart
  const analogCountDashboard = async (project_id, userToken, zoneId, subzoneId) => {
    try {
      const response = await fetch(
        `${baseUrl}dashboards/analogNew/${project_id}/${zoneId}/${subzoneId}`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: userToken,
          },
        }
      );

      if (!response.ok) {
        throw new Error(`Request failed with status: ${response.status}`);
      }

      const responseJson = await response.json();

      console.log("responseJson", responseJson.data);

      const {
        analogStatus1,
        colorForAnalogStatus1,
        analogStatus2,
        colorForAnalogStatus2,
        dashboard_name_array,
      } = responseJson.data;

      const sum1 = analogStatus1.reduce((acc, item) => acc + item.x, 0);
      const sum2 = analogStatus2.reduce((acc, item) => acc + item.x, 0);

      setanalog1Array(analogStatus1);
      setanalog1ArrayColor(colorForAnalogStatus1);
      setanalog1ArrayCount(sum1);
      setSensorAnalog1Name(dashboard_name_array[0]);
      setSensorAnalog2Name(dashboard_name_array[1]);
      setanalog2Array(analogStatus2);
      setanalog2ArrayColor(colorForAnalogStatus2);
      setanalog2ArrayCount(sum2);
      setanalogArrayData(responseJson.data);
    } catch (error) {
      console.error("device error", error);
    }
  };

  //energy status values shown in pie chart
  const energyStatusDashboard = async (project_id, userToken, zoneId, subzoneId) => {
    try {
      const response = await fetch(`${baseUrl}dashboards/eneryStatusNew/${project_id}/${zoneId}/${subzoneId}`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: userToken,
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const responseData = await response.json();
      console.log("resp", responseData.data);

      const energyStatusResponse = responseData.data.energyStatusResponse;
      const color = responseData.data.color;
      const energyStatusArrayCount = energyStatusResponse.reduce((sum, item) => sum + item.x, 0);
      const energyStatusArrayData = responseData.data;
      const energyStatusName = responseData.data.dashboard_name;

      setenergyStatusArray(energyStatusResponse);
      setenergyStatusArrayColor(color);
      setenergyStatusArrayCount(energyStatusArrayCount);
      setenergyStatusArrayData(energyStatusArrayData);
      setEnergyStatusName(energyStatusName);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  //Total flow api
  const actualFlowDashboard = async (project_id, userToken, zoneId, subzoneId) => {
    try {
      const api = `${baseUrl}dashboards/totalFlowForDashboardNew/${project_id}/${zoneId}/${subzoneId}`;

      const response = await fetch(api, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: userToken,
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const responseJson = await response.json();

      const { actual_flow, total_flow, dashboard_name } = responseJson.data;
      const flowCount = (actual_flow / total_flow) * 100;

      setFlowCount(flowCount);
      setActualFlow(actual_flow);
      setTotalFlow(total_flow);
      setTotalFlowName(dashboard_name);
    } catch (error) {
      console.error("flow error", error);
    }
  };

  //Digital Input Api
  const DigitalCountDashboard = async (project_id, userToken, zoneId, subzoneId) => {
    try {
      const response = await fetch(
        `${baseUrl}dashboards/watermeter/${project_id}/${zoneId}/${subzoneId}`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: userToken,
          },
        }
      );

      if (!response.ok) {
        console.error("Error:", response.statusText);
        return;
      }

      const responseJson = await response.json();

      const index1Response = responseJson.data.index1Reponse;
      const index2Response = responseJson.data.index2Reponse;

      const sum1 = index1Response.reduce((acc, item) => acc + item.x, 0);
      const sum2 = index2Response.reduce((acc, item) => acc + item.x, 0);

      setdigital1Array(index1Response);
      setdigital1ArrayColor(responseJson.data.colorForIndex1);
      setdigital1ArrayCount(sum1);

      setdigital2Array(index2Response);
      setdigital2ArrayColor(responseJson.data.colorForIndex2);
      setdigital2ArrayCount(sum2);

      setdigitalArrayData(responseJson.data);
      console.log("responseJson.data", responseJson.data);

      setSensorDigital1Name(responseJson.data.dashboard_name_array[0]);
      setSensorDigital2Name(responseJson.data.dashboard_name_array[1]);
    } catch (error) {
      console.error("Device error:", error);
    }
  };

  //Digital Input Api
  const DigitalCount3To8Dashboard = (project_id, userToken, zoneId, subzoneId) => {
    //GET request
    fetch(baseUrl + `dashboards/switch/${project_id}/${zoneId}/${subzoneId}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: userToken,
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        const digitalData = responseJson.data;
        const digitalCounters = [
          { array: digitalData.deviceStatus3, setArray: setdigital3Array, setColorArray: setdigital3ArrayColor },
          { array: digitalData.deviceStatus4, setArray: setdigital4Array, setColorArray: setdigital4ArrayColor },
          { array: digitalData.deviceStatus5, setArray: setdigital5Array, setColorArray: setdigital5ArrayColor },
          { array: digitalData.deviceStatus6, setArray: setdigital6Array, setColorArray: setdigital6ArrayColor },
          { array: digitalData.deviceStatus7, setArray: setdigital7Array, setColorArray: setdigital7ArrayColor },
          { array: digitalData.deviceStatus8, setArray: setdigital8Array, setColorArray: setdigital8ArrayColor },
        ];

        digitalCounters.forEach((counter, index) => {
          const sum = counter.array.reduce((total, item) => total + item.x, 0);
          counter.setArray(counter.array);
          counter.setColorArray(digitalData[`colorForDeviceStatus${index + 3}`]);
          setdigitalArray(digitalData);

          switch (index) {
            case 0:
              setdigital3ArrayCount(sum);
              setSensorDigital3Name(digitalData.dashboard_name_array[index]);
              break;
            case 1:
              setdigital4ArrayCount(sum);
              setSensorDigital4Name(digitalData.dashboard_name_array[index]);
              break;
            case 2:
              setdigital5ArrayCount(sum);
              setSensorDigital5Name(digitalData.dashboard_name_array[index]);
              break;
            case 3:
              setdigital6ArrayCount(sum);
              setSensorDigital6Name(digitalData.dashboard_name_array[index]);
              break;
            case 4:
              setdigital7ArrayCount(sum);
              setSensorDigital7Name(digitalData.dashboard_name_array[index]);
              break;
            case 5:
              setdigital8ArrayCount(sum);
              setSensorDigital8Name(digitalData.dashboard_name_array[index]);
              break;
            default:
              break;
          }
        });
      })
      .catch((error) => {
        console.error("device error", error);
      });
  };

  //Get details when click on omega id
  const getValveOmegaDetails = async (deviceId) => {
    setIsLoadingSync(true);
    try {
      const OmegaDetailsResponse = await postData(
        {
          project_id: projectId,
          device_id: deviceId,
        },
        "masters/getUnitStatusAPI"
      );
      setIsLoadingSync(false);
      const code = OmegaDetailsResponse.code;
      if (code === 1) {
        const data = OmegaDetailsResponse.data[0];
        setShowPopover(data.omega_id === deviceId);
        setValveDetails(data.valves);
        setWaterMeterDetails(data.watermeters);
        setAnalogDetails(data.analogs);
      } else {
        setIsLoadingSync(false);
      }
    } catch (error) {
      setIsLoadingSync(false);
    }
  };

  //Get Dependent omega  by project id
  const getOmegaDependentByProjectId = async (project_id) => {
    try {
      setIsLoading(true);

      const OmegaIdData = JSON.stringify({
        column: "project_id",
        value: project_id,
        module: "devices_master",
      });

      const OmegaIdList = await postData(OmegaIdData, "masters/mapDependentList");

      if (OmegaIdList.code === 1 && OmegaIdList.data.length > 0) {
        const { data } = OmegaIdList;

        // Calculate the average latitude and longitude
        const totalLat = data.reduce((sum, item) => sum + Number(item.lat), 0);
        const totalLong = data.reduce((sum, item) => sum + Number(item.long), 0);
        const averageLat = totalLat / data.length;
        const averageLong = totalLong / data.length;

        // Set the center coordinates and map properties
        setCenterLat(averageLat);
        setCenterLong(averageLong);
        setCenterLatLong({ lat: averageLat, lng: averageLong });

        const defaultProps = {
          center: {
            lat: averageLat,
            lng: averageLong,
          },
          zoom: 6,
        };

        setOmegaIdData(data);
        setIsLoading(false);
      } else {
        setIsLoading(false);
        // Handle error or provide feedback for no data
        // alert(OmegaIdList.message);
      }
    } catch (error) {
      setIsLoading(false);
      // Handle the error appropriately
      // console.log(error);
    }
  };

  const getSwitchDashboard = async (project_id, userToken, zoneId, subzoneId) => {
    const api = `${baseUrl}dashboards/switchesDash/${project_id}/${zoneId}/${subzoneId}`;
    console.log("getSwitchDashboard", api);

    try {
      const response = await fetch(api, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: userToken,
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const responseJson = await response.json();
      setSwitchDashboard(responseJson?.data)
    } catch (error) {
      console.error("valve error", error);
    }
  };

  const getWaterMeterDashboard = async (project_id, userToken, zoneId, subzoneId) => {
    const api = `${baseUrl}dashboards/watermeterDash/${project_id}/${zoneId}/${subzoneId}`;
    try {
      const response = await fetch(api, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: userToken,
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const responseJson = await response.json();
      setWaterMeterDashboard(responseJson?.data)
    } catch (error) {
      console.error("valve error", error);
    }
  };

  type PreviewLayoutProps = PropsWithChildren<{
    columnGap: number;
    handleColumnGapChange: (gap: number) => void;
    rowGap: number;
    handleRowGapChange: (gap: number) => void;
  }>;

  const PreviewLayout = ({
    children,
    handleColumnGapChange,
    handleRowGapChange,
    rowGap,
    columnGap,
  }: PreviewLayoutProps) => (
    <View style={styles.previewContainer}>
      <View style={[styles.container, { rowGap, columnGap }]}>{children}
      </View>
    </View>
  );

  const getMapOptions = (maps: Maps) => ({
    streetViewControl: true,
    scaleControl: true,
    fullscreenControl: true,
    gestureHandling: "greedy",
    disableDoubleClickZoom: false,
    mapTypeControl: true,
    mapTypeId: maps.MapTypeId.SATELLITE,
    mapTypeControlOptions: {
      style: maps.MapTypeControlStyle.HORIZONTAL_BAR,
      position: maps.ControlPosition.BOTTOM_CENTER,
      mapTypeIds: [
        maps.MapTypeId.ROADMAP,
        maps.MapTypeId.SATELLITE,
        maps.MapTypeId.HYBRID,
      ],
    },
    zoomControl: true,
    clickableIcons: true,
  })


  const AnyReactComponent = ({ text, id, omega_id, isOnline }) => (
    <View style={{ flexDirection: "column" }}>
      {/* <Pressable
        onPress={() => { setOmegaIdGet(omega_id), getValveOmegaDetails(omega_id), setIsVisible(true) }}
      >
        <Image
          style={{
            height: 50,
            width: 50,
            zIndex: 1,
            resizeMode: "contain",
          }}
          source={require("../../assets/images/omega.png")}
        />
        {(isOnline == null || false) && (
          <View>
            <Ring delay={0} />
            <Ring delay={1000} />
            <Ring delay={2000} />
            <Ring delay={3000} />
            <Ring delay={4000} />
          </View>
        )}
        <View style={{ width: "90px", height: "26px", flexDirection: "row" }}>
          <Text
            style={{
              backgroundColor: "#fff",
              color: "black",
              padding: 5,
              // margin: 10,
              textAlign: "center",
            }}
          >
            {text}
          </Text>
        </View>
      </Pressable> */}
      <DoubleClick
        singleTap={() => {
          // console.log("single tap");
          setOmegaIdGet(omega_id), getValveOmegaDetails(omega_id), setIsVisible(true)
        }}
        doubleTap={() => {

          // console.log("double tap");
          setIsVisible(false);
          navigation.navigate("ValveView", { OmegaId: omega_id });
        }}
        delay={200}
      >
        <Image
          style={{
            height: 50,
            width: 50,
            zIndex: 1,
            resizeMode: "contain",
          }}
          source={require("../../assets/images/omega.png")}
        />
        {(isOnline == null || false) && (
          <View>
            <Ring delay={0} />
            <Ring delay={1000} />
            <Ring delay={2000} />
            <Ring delay={3000} />
            <Ring delay={4000} />
          </View>
        )}
        <View style={{ width: "50px", flexDirection: "row" }}>
          <Text
            style={{
              backgroundColor: "#fff",
              color: "black",
              padding: 0,
              margin: 0,
              textAlign: "center",
            }}
          >
            {text}
          </Text>
        </View>
      </DoubleClick>

      {omega_id == OmegaIdGet && (
        <Modal
          ref={onscrollModalOff}
          onRequestClose={() => setIsVisible(false)}
          // onBackdropPress={() => setIsVisible(false)}
          transparent={true}
          visible={isVisible}
        >
          <View style={styles.contanerSwitch}>
            <TouchableOpacity
              style={{ alignSelf: "flex-end" }}
              onPress={() => setIsVisible(false)}
            >
              <IconCross
                name="circle-with-cross"
                size={20}
                color="#000"
                style={{ bottom: 5, top: 0 }}
              />
            </TouchableOpacity>
            <Text
              onPress={() => {
                setIsVisible(false);
                navigation.navigate("ValveView", { OmegaId: omega_id });
              }}
              style={{ textAlign: "center", padding: 5 }}
            >
              {text}
            </Text>
            <View style={{ flexDirection: "row", marginBottom: 10 }}>
              {valveDetails !== undefined &&
                valveDetails !== null &&
                <Image
                  style={{
                    height: 20,
                    width: 40,
                    resizeMode: "contain",
                  }}
                  source={require("../../assets/images/valves.png")}
                />
              }
              {valveDetails !== undefined &&
                valveDetails !== null &&
                valveDetails.map((item, key) => (
                  <View style={{ flexDirection: "row" }}>
                    {item.status == "OPEN" && (
                      <Text key={key}> Valve{item.index} </Text>
                    )}

                    {/* {valveDetails.length - 1 !== key && (
                      <Text key={key}>, </Text>
                    )} */}
                  </View>
                ))}
            </View>
            <View style={{ flexDirection: "row", marginBottom: 10 }}>
              {analogDetails !== undefined &&
                analogDetails !== null &&
                <Image
                  style={{
                    height: 25,
                    width: 45,
                    resizeMode: "contain",
                  }}
                  source={require("../../assets/images/sensorLogo.png")}
                />
              }
              {analogDetails !== undefined &&
                analogDetails !== null &&
                analogDetails.map((item, key) => (
                  <View style={{ flexDirection: "row" }}>
                    <Text key={key}> Analog{item.index}  : {item.value}</Text>
                    {analogDetails.length - 1 !== key && (
                      <Text key={key}>, </Text>
                    )}
                  </View>
                ))}
            </View>
            <View style={{ flexDirection: "row", marginBottom: 10 }}>
              {waterMeterDetails !== undefined &&
                waterMeterDetails !== null &&
                <Image
                  style={{
                    height: 25,
                    width: 45,
                    resizeMode: "contain",
                  }}
                  source={require("../../assets/images/Watermeter.png")}
                />
              }

              {waterMeterDetails !== undefined &&
                waterMeterDetails !== null &&
                waterMeterDetails.map((item, key) => (
                  <View style={{ flexDirection: "row" }}>
                    <Text key={key}> Digital{item.flow} : {item.flow} </Text>
                    {waterMeterDetails.length - 1 !== key && (
                      <Text key={key}>, </Text>
                    )}
                  </View>
                ))}
            </View>
            {/* <View
              style={{
                flexDirection: "row",
                alignItems: "center",
                width: "100%",
              }}
            >
              <View
                style={{
                  flex: 1,
                  margin: 10,
                  flexDirection: "row",
                  alignItems: "flex-start",
                }}
              >
                <View style={{ alignItems: "flex-start" }}></View>
                <View style={{ alignItems: "flex-start" }}></View>
              </View>
            </View> */}
          </View>
        </Modal>
      )}
    </View>
  );

  useFocusEffect(
    React.useCallback(() => {
      getAdminData_zone();
    }, [])
  );

  const getAdminData_zone = async () => {
    let jsonValue = await AsyncStorage.getItem("userData");
    // console.log("jsonValue:", jsonValue);
    let projValue = await AsyncStorage.getItem("projectData");

    // console.log("jsonValue & projValue:", jsonValue, projValue);
    if (projValue != null) {
      // console.log("project id select in zone create", projValue);
      getAllZoneProfileData(projValue);
    } else if (jsonValue != null) {
      var userDetailsresponse = JSON.parse(jsonValue);
      // console.log("userDetailsresponse==>", userDetailsresponse);
      var default_project_id = "";
      console.log("userType", userType);
      if (userType == 1) {
        //admin
        default_project_id = userDetailsresponse.project_selected;
        //console.log("Adminn...",userDetailsresponse.project_selected)
      } else {
        //farmer
        default_project_id = userDetailsresponse.project_id;
        console.log("default_project_id", default_project_id);
      }
      getAllZoneProfileData(default_project_id);
    }
  };

  const getAllZoneProfileData = async (pid) => {
    // console.log("pidpid", pid);
    setIsLoading(true);
    try {
      var getZoneData = JSON.stringify({
        condition: {
          project_id: pid,
        },
        module: "zone_master",
        relation: [],
      });
      const ZoneNameResponse = await postData(
        getZoneData,
        "masters/getMasterList"
      );
      setIsLoading(false);
      var code = ZoneNameResponse.code;
      if (code == 1) {
        console.log("ZoneNameResponse list====>", ZoneNameResponse.data);
        var mergeDataDrop = ZoneNameResponse.data
        mergeDataDrop.unshift({
          zone_name: "All",
          active: 1,
          id: 0
        });
        setZoneValue("All")
        setZoneNameData(mergeDataDrop);
      } else {
        setIsLoading(false);
        //alert(ZoneNameResponse.message);
      }
    } catch (error) {
      setIsLoading(false);
      // console.log(error);
    }
  };

  const getDependentZoneMaster = async (zone_id) => {
    // console.log("zone_idmasters", zone_id);
    setIsLoading(true);

    try {
      var dependentData = JSON.stringify({
        column: "zone_id",
        value: zone_id,
        module: "sub_zone_master",
      });
      const dependentZoneRes = await postData(
        dependentData,
        "masters/getDependentMasterList"
      );
      setIsLoading(false);
      var code = dependentZoneRes.code;
      if (code == 1) {
        console.log("getDependentCountryMaster======", dependentZoneRes.data);
        // setCountryLength(dependentZoneRes.data.length);
        var mergeDataSubDrop = dependentZoneRes.data
        mergeDataSubDrop.unshift({
          sub_zone_name: "All",
          active: 1,
          id: 0
        });
        setZoneValue("All")
        setSubzoneData(mergeDataSubDrop);
      } else {
        setIsLoading(false);
        //alert(dependentZoneRes.message);
      }
    } catch (error) {
      setIsLoading(false);
      // console.log(error);
    }
  };

  const renderZoneIdListItems = (item: any) => {
    return (
      <View style={styles.item}>
        {isAdd == true && <Text style={styles.textItem}>{item.zone_name}</Text>}
        {isAdd == false && (
          <Text style={styles.textItem}>{item.zone_name}</Text>
        )}
      </View>
    );
  };
  const renderSubZoneIdListItems = (item: any) => {
    return (
      <View style={styles.item}>
        {isAdd == true && (
          <Text style={styles.textItem}>{item.sub_zone_name}</Text>
        )}
        {isAdd == false && (
          <Text style={styles.textItem}>{item.sub_zone_name}</Text>
        )}
      </View>
    );
  };

  const renderOmegaIdListItems = ({ item, index }: { item: any, index: any }) => {
    return (
      <TouchableOpacity activeOpacity={1} style={{ zIndex: 999, height: 50, justifyContent: "center", backgroundColor: "#fff", width: "100%", paddingLeft: 10, }} onPress={() => {
        window.open(`/ValveView?OmegaId=${item.omega_id}&OmegaName=${item.device_name}&basedOn=${item.based_on}`, "_blank");
        setOmegaDropdownData([])
        setSearchQuery("")
      }}>
        {isAdd == true && (
          <Text style={styles.textItem}>{item.device_name}</Text>
        )}
        {isAdd == false && (
          <Text style={styles.textItem}>{item.device_name}</Text>
        )}
        {item.id === OmegaValue?.id && (
          <IconSelect
            style={styles.icon}
            color="black"
            name="Safety"
            size={20}
          />
        )}
      </TouchableOpacity>
    );
  };

  const renderItem = ({ item, index }: any) => (
    <TouchableOpacity
      style={{ flexDirection: "row", marginRight: 10, width: index < 2 ? 120 : 90, marginBottom: 3, justifyContent: "center" }}
      onPress={() => handleNavigation(item.status)}
    >
      <Icon color={valvesArrayColor[index] || "#000"} name="square" size={20} />
      <Text style={[styles.btnTextCancel, { marginLeft: 5, fontSize: isMobile ? 13 : 15 }]}>
        {item.statusName}
      </Text>
    </TouchableOpacity>
  );

  const handleNavigation = (status: any) => {
    try {
      navigation.navigate("ValveDetailsReport", {
        status,
        zoneId: ZoneValueId,
        subzoneId: SubzoneValueId,
      });
    } catch (error) {
      console.log("error", error);
    }
  };

  const handleSwitchNavigation = (item: any, type: any) => {
    try {
      if (type === 1) {
        navigation.navigate(
          "DigitalDetailsReport",
          {
            status: item?.status,
            index: item?.index,
            zoneId: ZoneValueId,
            subzoneId: SubzoneValueId
          }
        );
      } else {
        navigation.navigate(
          "DigitalInputsReport",
          {
            status: item?.status,
            index: item?.index,
            zoneId: ZoneValueId,
            subzoneId: SubzoneValueId,
            statusName: item?.statusName,
          }
        );
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const renderSwitchItem = (item: any, index: any) => {
    const sum = item?.switchResponse?.reduce((total: any, valve: any) => total + valve.x, 0);
    const colorArray = item?.color || []
    const pieData = item?.switchResponse || [];

    const filteredData = pieData.reduce(
      (acc: any, obj: any, index: any) => {
        if (!(obj.x === 0 && obj.y === 0)) {
          acc.filteredPieData.push(obj);
          acc.filteredColors.push(colorArray[index]);
        }
        return acc;
      },
      { filteredPieData: [], filteredColors: [] }
    );

    const filteredPieData = filteredData.filteredPieData;
    const filteredColors = filteredData.filteredColors;
    return (
      <View style={isMobile ? { width: "48%", height: 340 } : { width: "23%", height: 340, marginTop: "-4%", marginRight: 2 }}>
        <View
          style={isMobile ? [
            styles.shadowProp,
            {
              width: "100%",
              height: 340
            },
          ] : [
            styles.shadowProp,
            {
              width: "100%",
              height: 275,
              overflow: 'hidden'
            },
          ]}
        >
          <View>
            <View style={isMobile ? { width: "100%", padding: 10, paddingTop: 30, borderBottomWidth: 1, borderBottomColor: 'grey' } : { width: "100%", padding: 8, borderBottomWidth: 1, borderBottomColor: 'grey' }}>
              <View style={{ alignItems: "flex-start" }}>
                <View style={{ flexDirection: "row" }}>
                  <View
                    style={{
                      alignItems: "center",
                      flexDirection: "row",
                      marginRight: 10,
                    }}
                  >
                    <Image
                      style={isMobile ? {
                        height: 25,
                        width: 45,
                        resizeMode: "contain",
                        marginLeft: -10
                      } : {
                        height: 25,
                        width: 45,
                        resizeMode: "contain",
                      }}
                      source={require("../../assets/images/Digitalinput3-8.jpg")}
                    />
                    <Text
                      style={isMobile ? [styles.btnTextCancel, { fontSize: 13, marginLeft: -7, width: 100, }] : [styles.btnTextCancel, { marginLeft: 6, fontSize: 16 }]}
                      numberOfLines={isMobile ? 2 : 1}
                    >
                      {item?.dashBoardName}
                    </Text>
                  </View>
                </View>
              </View>
            </View>
            <View style={{ width: "100%", padding: 0 }}>
              <View
                style={{
                  alignItems: "center",
                  alignSelf: "center",
                  width: "85%",
                }}
              >
                <svg style={{ cursor: "pointer" }} viewBox={isMobile ? "0 0 400 400" : "-100 0 600 400"}>
                  <G>
                    <victory.VictoryPie
                      standalone={false}
                      width={400}
                      height={400}
                      data={filteredPieData}
                      colorScale={filteredColors}
                      labels={({ datum }) => `${datum.x}`}
                      events={[
                        {
                          target: "data",
                          eventHandlers: {
                            onClick: () => {
                              return [
                                {
                                  target: "data",
                                  mutation: (props) => {
                                    var props_index = props.index;
                                    var temp = [];
                                    var jsonstring = JSON.stringify(
                                      props,
                                      null,
                                      2
                                    );
                                    var jsonstring1 =
                                      jsonstring.replaceAll(
                                        "index:  ",
                                        ""
                                      );
                                    var jsonparse =
                                      JSON.parse(jsonstring1);
                                    var iLength = jsonparse.data.length;
                                    navigation.navigate(
                                      "DigitalInputsReport",
                                      {
                                        status:
                                          jsonparse.data[props_index]
                                            .status,
                                        index:
                                          jsonparse.data[props_index]
                                            .index,
                                        zoneId: ZoneValueId,
                                        subzoneId: SubzoneValueId,
                                        statusName: jsonparse.data[props_index].statusName,
                                      }
                                    );
                                    return null;
                                  },
                                },
                                {
                                  target: "labels",
                                  mutation: (props) => {
                                    return null;
                                  },
                                },
                              ];
                            },
                          },
                        },
                      ]}
                      innerRadius={75}
                      labelRadius={160}
                      style={isMobile ? { labels: { fontSize: 25, fill: "#000" } } : { labels: { fontSize: 35, fill: "#000" } }}
                    />
                    <victory.VictoryLabel
                      textAnchor="middle"
                      style={{ fontSize: 35 }}
                      x={200}
                      y={200}
                      text={
                        sum
                          ? sum
                          : "No Data Found"
                      }
                    />
                  </G>
                </svg>
              </View>
              <View style={{ width: "100%", alignSelf: "center", alignItems: "center", marginTop: -10, marginLeft: "-18%" }}>
                <FlatList
                  data={item?.switchResponse}
                  numColumns={isMobile ? 1 : 2}
                  keyExtractor={(item, index) => index.toString()}
                  renderItem={({ item, index }: any) => (
                    <TouchableOpacity
                      style={{ flexDirection: "row", marginRight: 10, width: 120, marginBottom: 3 }}
                      onPress={() => handleSwitchNavigation(item, 0)}
                    >
                      <Icon color={colorArray[index] || "#000"} name="square" size={20} />
                      <Text style={[styles.btnTextCancel, { marginLeft: 5, fontSize: isMobile ? 13 : 15 }]}>
                        {item?.statusName}
                      </Text>
                    </TouchableOpacity>
                  )}
                  contentContainerStyle={{ paddingHorizontal: 8, width: isMobile ? "40%" : "80%", justifyContent: "space-around", alignSelf: "center", marginLeft: -20 }}
                />
              </View>
            </View>
          </View>
        </View>
      </View>
    )
  }

  const renderWaterMeterItem = (item: any, index: any) => {
    const sum = item?.watermeterResponse?.reduce((total: any, valve: any) => total + valve.x, 0);
    const colorArray = item?.color || []
    const pieData = item?.watermeterResponse || [];

    const filteredData = pieData.reduce(
      (acc: any, obj: any, index: any) => {
        if (!(obj.x === 0 && obj.y === 0)) {
          acc.filteredPieData.push(obj);
          acc.filteredColors.push(colorArray[index]);
        }
        return acc;
      },
      { filteredPieData: [], filteredColors: [] }
    );

    const filteredPieData = filteredData.filteredPieData;
    const filteredColors = filteredData.filteredColors;
    return (
      <View style={isMobile ? { width: "48%", height: 340 } : { width: "23%", height: 340, marginTop: "-4%", marginRight: 2 }}>
        <View
          style={isMobile ? [
            styles.shadowProp,
            {
              width: "100%",
              height: 340
            },
          ] : [
            styles.shadowProp,
            {
              width: "100%",
              height: 275,
              overflow: 'hidden'
            },
          ]}
        >
          <View>
            <View style={isMobile ? { width: "100%", padding: 10, paddingTop: 30, borderBottomWidth: 1, borderBottomColor: 'grey' } : { width: "100%", padding: 8, borderBottomWidth: 1, borderBottomColor: 'grey' }}>
              <View style={{ alignItems: "flex-start" }}>
                <View style={{ flexDirection: "row" }}>
                  <View
                    style={{
                      alignItems: "center",
                      flexDirection: "row",
                      marginRight: 10,
                    }}
                  >
                    <Image
                      style={isMobile ? {
                        height: 25,
                        width: 45,
                        resizeMode: "contain",
                        marginLeft: -10
                      } : {
                        height: 25,
                        width: 45,
                        resizeMode: "contain",
                      }}
                      source={require("../../assets/images/Watermeter.png")}
                    />
                    <Text
                      style={isMobile ? [styles.btnTextCancel, { fontSize: 13, marginLeft: -7, width: 100, }] : [styles.btnTextCancel, { marginLeft: 4, fontSize: 16 }]}
                      numberOfLines={isMobile ? 2 : 1}
                    >
                      {item?.dashBoardName}
                    </Text>
                  </View>
                </View>
              </View>
            </View>
            <View style={{ width: "100%", padding: 0 }}>
              <View
                style={{
                  alignItems: "center",
                  alignSelf: "center",
                  width: "85%",
                }}
              >
                <svg style={{ cursor: "pointer" }} viewBox={isMobile ? "0 0 400 400" : "-100 0 600 400"}>
                  <G>
                    <victory.VictoryPie
                      standalone={false}
                      width={400}
                      height={400}
                      data={filteredPieData}
                      colorScale={filteredColors}
                      labels={({ datum }) => `${datum.x}`}
                      events={[
                        {
                          target: "data",
                          eventHandlers: {
                            onClick: () => {
                              return [
                                {
                                  target: "data",
                                  mutation: (props) => {
                                    var props_index = props.index;
                                    var temp = [];
                                    var jsonstring = JSON.stringify(
                                      props,
                                      null,
                                      2
                                    );
                                    var jsonstring1 =
                                      jsonstring.replaceAll(
                                        "index:  ",
                                        ""
                                      );
                                    var jsonparse =
                                      JSON.parse(jsonstring1);
                                    var iLength = jsonparse.data.length;
                                    navigation.navigate(
                                      "DigitalDetailsReport",
                                      {
                                        status:
                                          jsonparse.data[props_index]
                                            .status,
                                        index:
                                          jsonparse.data[props_index]
                                            .index,
                                        zoneId: ZoneValueId,
                                        subzoneId: SubzoneValueId
                                      }
                                    );
                                    return null;
                                  },
                                },
                                {
                                  target: "labels",
                                  mutation: (props) => {
                                    return null;
                                  },
                                },
                              ];
                            },
                          },
                        },
                      ]}
                      innerRadius={75}
                      labelRadius={160}
                      style={isMobile ? { labels: { fontSize: 25, fill: "#000" } } : { labels: { fontSize: 35, fill: "#000" } }}
                    />
                    <victory.VictoryLabel
                      textAnchor="middle"
                      style={{ fontSize: 35 }}
                      x={200}
                      y={200}
                      text={
                        sum
                          ? sum
                          : "No Data Found"
                      }
                    />
                  </G>
                </svg>
              </View>
              <View style={{ width: "100%", alignSelf: "center", marginTop: -5, alignItems: "center" }}>
                <FlatList
                  data={pieData}
                  numColumns={isMobile ? 1 : pieData.length > 4 ? 3 : 2}
                  keyExtractor={(item, index) => index.toString()}
                  renderItem={({ item, index }: any) => (
                    <TouchableOpacity
                      style={{ flexDirection: "row", marginRight: 10, marginBottom: 3, width: 120, }}
                      onPress={() => handleSwitchNavigation(item, 1)}
                    >
                      <Icon color={colorArray[index] || "#000"} name="square" size={20} />
                      <Text style={[styles.btnTextCancel, { marginLeft: 5, fontSize: isMobile ? 13 : 15 }]}>
                        {item?.statusName}
                      </Text>
                    </TouchableOpacity>
                  )}
                  contentContainerStyle={{ paddingHorizontal: 8, width: isMobile ? "40%" : "80%", flexWrap: "wrap", flexDirection: isMobile ? "column" : "row", justifyContent: "space-around", alignSelf: "center", marginLeft: -20 }}
                />
              </View>
            </View>
          </View>
        </View>
      </View>
    )
  }

  const colorArray = valvesArrayColor || []
  const valveData = valvesArray || [];

  const filteredData = valveData?.reduce(
    (acc: any, obj: any, index: any) => {
      if (!(obj.x === 0 && obj.y === 0)) {
        acc.filteredPieData.push(obj);
        acc.filteredColors.push(colorArray[index]);
      }
      return acc;
    },
    { filteredPieData: [], filteredColors: [] }
  );

  const filteredValveData = filteredData.filteredPieData;
  const filteredValveColors = filteredData.filteredColors;

  return (
    <>
      <View style={isMobile ? { flexDirection: 'row', zIndex: 999 } : { flexDirection: 'row', zIndex: 999 }}>
        <View style={isMobile ? { marginLeft: "2%", width: "30%" } : { marginLeft: "0.8%", width: "20%" }}>
          <View style={{ height: "14%" }}>
            <Dropdown
              ref={dropdownRefZone}
              style={styles.dropdown1}
              placeholderStyle={styles.placeholderStyle}
              selectedTextStyle={styles.selectedTextStyle}
              inputSearchStyle={styles.inputSearchStyle}
              iconStyle={styles.iconStyle}
              data={ZoneNameData}
              search
              maxHeight={300}
              labelField="zone_name"
              valueField="zone_name"
              placeholder={!isFocus ? "Select Zone Id" : "...."}
              searchPlaceholder="Search..."
              value={ZoneValue}
              onChange={(item) => {
                if (item.zone_name == "All") {
                  setZoneValue(item.zone_name);
                  setShowSubzoneDropdown(false)
                } else {
                  setZoneValue("All");
                  setShowSubzoneDropdown(true)
                }
                zoneAndSubzoneWiseDashborad(projectId, userTokenValue, item.id, 0)
                setZoneValueId(item.id);
                getDependentZoneMaster(item.id);
                // setZoneIdError("");
              }}
              renderItem={renderZoneIdListItems}
            />
          </View>
        </View>
        {showSubzoneDropdown && (
          <View style={isMobile ? { marginLeft: "2%", width: "30%" } : { marginLeft: "2%", width: "20%" }}>
            <View>
              <Dropdown
                ref={dropdownRefZone}
                style={styles.dropdown1}
                placeholderStyle={styles.placeholderStyle}
                selectedTextStyle={styles.selectedTextStyle}
                inputSearchStyle={styles.inputSearchStyle}
                iconStyle={styles.iconStyle}
                data={subzoneData}
                search
                maxHeight={300}
                labelField="sub_zone_name"
                valueField="sub_zone_name"
                placeholder={!isFocus ? "Select subzone " : "...."}
                searchPlaceholder="Search..."
                value={ZoneValue}
                onChange={(item) => {
                  setSubzoneValue(item.sub_zone_name);
                  setSubzoneValueId(item.id);
                  // setSubZoneIdError("");
                  zoneAndSubzoneWiseDashborad(projectId, userTokenValue, ZoneValueId, item.id)
                  console.log("SUB zone id value---", item.id);
                }}
                renderItem={renderSubZoneIdListItems}
              />
            </View>
          </View>
        )}
        <View style={isMobile ? { marginLeft: "2%", width: "30%" } : { marginLeft: "2%", width: "20%" }}>
          <View>
            <TextInput
              value={searchQuery}
              style={[styles.dropdown1, { width: "100%", height: 30, borderWidth: 1, fontSize: 12 }]}
              placeholder="Search Omega"
              onChangeText={(text: any) => handleSearch(text)}
              onBlur={() => {
                setTimeout(() => {
                  setOmegaDropdownData([])
                  setSearchQuery("")
                }, 1000);
              }}
            />
            {OmegaDropdownData?.length > 0 && (
              <FlatList
                data={OmegaDropdownData}
                keyExtractor={(item, index) => index.toString()}
                renderItem={renderOmegaIdListItems}
                scrollEnabled={true}
                style={{
                  width: "100%", position: "absolute", top: 40, maxHeight: 200, shadowColor: "#000",
                  shadowOffset: { width: 0, height: 2 },
                  shadowOpacity: 0.3,
                  shadowRadius: 4,
                }}
                contentContainerStyle={{
                  backgroundColor: "#fff",
                  justifyContent: "center",
                  flexGrow: 1,
                }}
              />
            )}
          </View>
        </View>
      </View>
      <PreviewLayout
        columnGap={columnGap}
        handleColumnGapChange={setColumnGap}
        rowGap={rowGap}
        handleRowGapChange={setRowGap}>
        {/* Map view */}
        <View
          style={isMobile ? [styles.shadowProp, {
            width: "100%",
            marginBottom: 20,
            marginRight: 10,
            height: 300,
            backgroundColor: "white",
            marginTop: -10
          },] : [
            styles.shadowProp,
            {
              width: "47%",
              marginBottom: 20,
              marginRight: 4,
              height: 268,
              backgroundColor: "white",
              marginTop: -10
            },
          ]}
        >
          <View>
            <View style={{ width: "100%", padding: 2 }}>
              <View style={{ alignItems: "flex-start" }}>
                <View style={{ flexDirection: "row" }}>
                  <View
                    style={{
                      alignItems: "center",
                      flexDirection: "row",
                      marginRight: 20,
                    }}
                  >
                    <Text style={[styles.btnTextCancel, { marginLeft: 5 }]}>
                      <Image
                        style={{
                          height: 15,
                          width: 35,
                          resizeMode: "contain",
                        }}
                        source={require("../../assets/images/mapLogo.png")}
                      />{" "}
                      Map
                    </Text>
                  </View>
                </View>
              </View>
            </View>
            <View style={{ width: "100%", padding: 5 }}>
              <View style={{ height: "30vh" }}>
                <GoogleMapReact
                  bootstrapURLKeys={{
                    key: "AIzaSyATARbxSEQ3jSYNcKxQLIYc1ZCA3Lf7gK0",
                  }}
                  // bootstrapURLKeys={{ key: "" }}
                  defaultCenter={centerLatLong}
                  center={centerLatLong}
                  defaultZoom={6}
                  options={getMapOptions}
                >
                  {OmegaIdData.map((item, key) => (
                    <AnyReactComponent
                      id={item.id}
                      omega_id={item.omega_id}
                      lat={item.lat}
                      lng={item.long}
                      text={item.device_name}
                      isOnline={item.isonline}
                    />
                  ))}
                </GoogleMapReact>
              </View>
            </View>
          </View>
        </View>
        {/* Total flow */}
        <View style={isMobile ? { width: "48%", height: 340 } : { width: "24%", height: 340, marginTop: -10, marginRight: -8, }}>
          <View
            style={isMobile ? [
              styles.shadowProp,
              {
                backgroundColor: "white",
                width: "100%",
                height: 340
              },
            ] : [
              styles.shadowProp,
              {
                backgroundColor: "white",
                width: "94%",
                height: 268,
                overflow: 'hidden'
              },
            ]}
          >
            <View>
              <View style={isMobile ? { width: "100%", padding: 10, paddingTop: 20, borderBottomWidth: 1, borderBottomColor: 'grey' } : { width: "100%", padding: 8, borderBottomWidth: 1, borderBottomColor: 'grey' }}>
                <View style={{ alignItems: "flex-start" }}>
                  <View style={{ flexDirection: "row" }}>
                    <View
                      style={isMobile ? {
                        alignItems: "center",
                        flexDirection: "row",
                        marginRight: 10,
                      } : {
                        alignItems: "center",
                        flexDirection: "row",
                        marginRight: 20,
                      }}
                    >
                      <Image
                        style={{
                          height: 25,
                          width: 45,
                          resizeMode: "contain",
                        }}
                        source={require("../../assets/images/waterflow.png")}
                      />
                      <Text
                        style={isMobile ? [
                          styles.btnTextCancel,
                          {
                            marginLeft: 5,
                            fontSize: 12,
                            alignItems: "center",
                          },
                        ] : [
                          styles.btnTextCancel,
                          {
                            marginLeft: 4,
                            fontSize: 16,
                            alignItems: "center",
                          },
                        ]}
                      >
                        {totalFlowName}
                      </Text>
                    </View>
                  </View>
                </View>
              </View>
              {/* <View
               style={{
                 marginTop: 5,
                 height: 0.5,
                 width: "100%",
                 alignSelf: "center",
                 backgroundColor: "grey",
               }}
             /> */}
              <View
                style={isMobile ? {
                  width: "100%",
                  paddingTop: 10,
                  paddingHorizontal: 20,
                  paddingBottom: 5,
                } : {
                  width: "100%",
                  paddingTop: 10,
                  paddingHorizontal: 20,
                  paddingBottom: 5,
                }}
              >
                <View style={{ alignItems: "center", marginBottom: 5, marginTop: 10 }}>
                  {/* <Image style={{
                         height: 170,
                         width: 200,
                         resizeMode: 'contain',
                       }} source={require('../../assets/images/Tank.png')}
                       /> */}
                  <LiquidFillGauge
                    style={{ background: "#d0d0d0", borderRadius: 100 }}
                    // style={{ margin: '0 auto' }}
                    width={isMobile ? (radius * 1.5) : (radius * 1.9)}
                    height={isMobile ? (radius * 1.5) : (radius * 1.9)}
                    value={flowCounts}
                    // data={waterData}
                    percent="%"
                    textSize={1}
                    textOffsetX={0}
                    textOffsetY={0}
                    textRenderer={(item) => {
                      const value = Math.round(item.value);

                      const radius1 = Math.min(
                        item.height / 2,
                        item.width / 2
                      );
                      const textPixels = (item.textSize * radius1) / 2;
                      const valueStyle = {
                        fontSize: 15,
                      };
                      const percentStyle = {
                        fontSize: 15 * 0.6,
                      };

                      return (
                        // <tspan>
                        //   <tspan>
                        //     <tspan x="0" dy="1.2em" style={valueStyle}>{actualFlow}</tspan>
                        //     <tspan x="0" dy="1.2em" style={valueStyle}>
                        //       m³/h / {totalFlow} m³/h{" "}
                        //     </tspan>

                        //   </tspan>
                        // </tspan>
                        // <g transform="translate(123 456)">
                        <tspan>
                          <tspan fill="black" text-anchor="middle" x="0"
                            font-size="15"
                            // dy="1.5em"
                            style={{ borderBlockWidth: "1" }}>{
                              !isNaN(actualFlow) ?
                                `${Math.round(actualFlow)} m³/h (A)` :
                                '0 m³/h (A)'
                            }
                          </tspan>
                          <tspan fill="black" text-anchor="middle" x="0"
                            font-size="15"
                            dy="1.5em"
                            style={valueStyle}>{totalFlow} m³/h (N)
                          </tspan>
                        </tspan>                              // </g>
                      );
                    }}
                    riseAnimation
                    waveAnimation
                    waveFrequency={2}
                    waveAmplitude={1}
                    gradient
                    gradientStops={gradientStops}
                    circleStyle={{
                      fill: fillColor,
                    }}
                    waveStyle={{
                      fill: fillColor,
                    }}
                    textStyle={{
                      fill: color("#444").toString(),
                      fontFamily: "Arial",
                    }}
                    waveTextStyle={{
                      fill: color("#fff").toString(),
                      fontFamily: "Arial",
                    }}
                  >

                  </LiquidFillGauge>
                </View>
                <View
                  style={isMobile ? {
                    // flexDirection: "row",
                    marginTop: 20,
                    alignSelf: "flex-start",
                    width: "100%",
                  } : {
                    flexDirection: "row",
                    marginTop: 40,
                    alignSelf: "flex-start",
                    width: "100%",
                    bottom: "15%",
                  }}
                >
                  <View
                    style={isMobile ? {
                      alignItems: "center",
                      width: "100%",
                      flexDirection: "row",
                      marginRight: 5,
                      marginTop: 12,
                      marginBottom: 10,
                    } : {
                      alignItems: "center",
                      width: "50%",
                      flexDirection: "row",
                      marginLeft: 4,
                      marginTop: 12,
                      marginBottom: 10,
                    }}
                  >
                    <Icon color="#009df0" name="square" size={20} />
                    <Text
                      style={isMobile ? [styles.btnTextCancel, { marginLeft: 5, fontSize: 13 }] : [styles.btnTextCancel, { marginLeft: 5 }]}
                    >
                      Actual Flow
                    </Text>
                  </View>
                  <View
                    style={isMobile ? {
                      // alignItems: "center",
                      width: "100%",
                      flexDirection: "row",
                      // marginLeft: -8,
                      marginBottom: 10,
                      marginTop: -8,
                    } : {
                      alignItems: "center",
                      width: "50%",
                      flexDirection: "row",
                      marginRight: 5,
                      marginBottom: 10,
                      marginTop: 12,
                    }}
                  >
                    <Text
                      style={isMobile ? [styles.btnTextCancel, { fontSize: 13 }] : [styles.btnTextCancel, { marginLeft: 5 }]}
                    >
                      <Icon color="#D0D0D0" name="square" size={20} />{" "}
                      Nominal Flow
                    </Text>
                  </View>
                </View>
              </View>
            </View>
          </View>
        </View>
        {/* valves */}
        {valvesArrayData.length != 0 &&
          (valvesArray.length != 0 && valvesArray != undefined &&
            <View style={isMobile ? { width: "48%", height: 340 } : { width: "24%", height: 340, marginLeft: -10, marginTop: -10 }}>
              <View
                style={isMobile ? [
                  styles.shadowProp,
                  {
                    backgroundColor: "white",
                    width: "100%",
                    height: 340
                  },
                ] : [
                  styles.shadowProp,
                  {
                    backgroundColor: "white",
                    width: "95%",
                    height: 268,
                    overflow: "hidden"
                  },
                ]}
              >
                <View>
                  <View style={isMobile ? { width: "100%", padding: 10, paddingTop: 25, borderBottomWidth: 1, borderBottomColor: 'grey' } : { width: "100%", padding: 8, borderBottomWidth: 1, borderBottomColor: 'grey' }}>
                    <View style={{ alignItems: "flex-start" }}>
                      <View style={{ flexDirection: "row" }}>
                        <View
                          style={{
                            alignItems: "center",
                            flexDirection: "row",
                            marginRight: 10,
                          }}
                        >
                          <Image
                            style={{
                              height: 20,
                              width: 40,
                              resizeMode: "contain",
                            }}
                            source={require("../../assets/images/valves.png")}
                          />
                          <Text
                            style={isMobile ? [
                              styles.btnTextCancel,
                              {
                                marginLeft: 5,
                                fontSize: 12,
                                alignItems: "center",
                              },
                            ] : [
                              styles.btnTextCancel,
                              { marginLeft: 6, fontSize: 16 },
                            ]}
                          >
                            {valveName}
                          </Text>
                        </View>
                      </View>
                    </View>
                  </View>
                  <View style={{ width: "100%", padding: 0, height: "51%" }}>
                    <svg style={isMobile ? { cursor: "pointer" } : { cursor: "pointer" }} viewBox={isMobile ? "0 -10 450 500" : "0 0 450 400"}>
                      <G>
                        <victory.VictoryPie
                          standalone={false}
                          // labelComponent={<VictoryTooltip />}
                          width={isMobile ? 450 : 400}
                          height={isMobile ? 450 : 400}
                          // data={[
                          //   { x: 10, y: 10, status: "open" },
                          //   { x: 20, y: 20, status: "open" },
                          //   { x: 40, y: 40, status: "open" },
                          //   { x: 50, y: 50, status: "open" },
                          // ]}
                          // colorScale={[
                          //   "#8b8b8b",
                          //   "#d0d0d0",
                          //   "#e94343",
                          //   "#558bff",
                          // ]}
                          data={filteredValveData}
                          colorScale={filteredValveColors}
                          // labelPosition={({ index }) => index
                          //   ? "centroid"
                          //   : "startAngle"
                          // }
                          labels={({ datum }) => `${datum.x}`}
                          // labels={({ datum }) => datum.x}

                          events={[
                            {
                              target: "data",
                              eventHandlers: {
                                onClick: () => {
                                  return [
                                    {
                                      target: "data",
                                      mutation: (props) => {
                                        var props_index = props.index;
                                        console.log("props", props);

                                        // // console.log('index: '+JSON.stringify(props));
                                        var temp = [];
                                        var jsonstring = JSON.stringify(
                                          props,
                                          null,
                                          2
                                        );
                                        var jsonstring1 =
                                          jsonstring.replaceAll(
                                            "index:  ",
                                            ""
                                          );
                                        // temp.push(jsonstring);

                                        var jsonparse =
                                          JSON.parse(jsonstring1);
                                        var iLength = jsonparse.data.length;
                                        // console.log("iLength" + iLength);


                                        navigation.navigate(
                                          "ValveDetailsReport",
                                          {
                                            status:
                                              jsonparse.data[props_index]
                                                .status,
                                            zoneId: ZoneValueId,
                                            subzoneId: SubzoneValueId
                                          }
                                        );
                                        return null;
                                        // //alert("index: " + props.index);
                                        // navigation.navigate(
                                        //   "ValveDetailsReport",
                                        //   {status: props.index }
                                        // );
                                        // return null;
                                      },
                                    },
                                    {
                                      target: "labels",
                                      mutation: (props) => {
                                        // //alert("text: " + props.text);
                                        // navigation.navigate(
                                        //   "ValveDetailsReport",
                                        //   {status: props.text }
                                        // );

                                        return null;
                                      },
                                    },
                                  ];
                                },
                              },
                            },
                          ]}

                          innerRadius={75}
                          labelRadius={isMobile ? 200 : 160}
                          style={{ labels: { fontSize: 30, fill: "black" } }}
                        />
                        <victory.VictoryLabel
                          textAnchor="middle"
                          style={{ fontSize: 35 }}
                          x={isMobile ? 230 : 200}
                          y={isMobile ? 230 : 200}
                          text={
                            valvesArrayCount
                              ? valvesArrayCount
                              : "No Data Found"
                          }
                        // text="9999"
                        />


                      </G>
                    </svg>
                  </View>
                  <View style={{ width: "100%", alignSelf: "center", alignItems: "center", bottom: 8 }}>
                    <FlatList
                      data={valvesArray}
                      keyExtractor={(item, index) => index.toString()}
                      renderItem={renderItem}
                      contentContainerStyle={{ paddingHorizontal: 8, flexWrap: "wrap", flexDirection: "row", justifyContent: "space-around", alignSelf: "center" }}
                    />
                  </View>
                </View>
              </View>
            </View>
          )
        }
        <View style={{ width: "100%", height: "100%", flexWrap: "wrap", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", gap: 10 }}>
          {/* RTU MENERGY STATUS , SENSOR ANAOLOG1 AND ANALOG2  */}
          {devicesArrayData.length != 0 &&
            (devicesArray.length != 0 && devicesArray != undefined &&
              < View style={isMobile ? { width: "48%", height: 340, } : { width: "24%", marginTop: "-4%", height: 340, marginRight: "-1%", }}>
                <View
                  style={isMobile ? [
                    styles.shadowProp,
                    {
                      backgroundColor: "white",
                      width: "100%",
                      height: 340
                    },
                  ] : [
                    styles.shadowProp,
                    {
                      backgroundColor: "white",
                      width: "95%",
                      height: 275,
                      overflow: "hidden"
                    },
                  ]}
                >
                  <View>
                    <View style={isMobile ? { width: "100%", padding: 10, paddingTop: 20, borderBottomWidth: 1, borderBottomColor: 'grey' } : { width: "100%", padding: 8, borderBottomWidth: 1, borderBottomColor: 'grey' }}>

                      <View style={{ alignItems: "flex-start" }}>
                        <View style={{ flexDirection: "row" }}>
                          <View
                            style={{
                              alignItems: "center",
                              flexDirection: "row",
                              marginRight: 10,
                            }}
                          >
                            <Image
                              style={isMobile ? {
                                marginLeft: -15, height: 25,
                                width: 45,
                                resizeMode: "contain",
                              } : {
                                height: 25,
                                width: 45,
                                resizeMode: "contain",
                              }}
                              source={require("../../assets/images/omega.png")}
                            />
                            <Text
                              style={isMobile ? [styles.btnTextCancel, { fontSize: 13, marginLeft: -7 }] : [styles.btnTextCancel, { marginLeft: 4, fontSize: 16 }]}
                            >
                              {rtuDeviceName}
                            </Text>
                          </View>
                        </View>
                      </View>
                    </View>
                    {/* <View
                    style={{
                      marginVertical: 5,
                      height: 0.5,
                      width: "100%",
                      alignSelf: "center",
                      backgroundColor: "grey",
                    }}
                  /> */}
                    <View style={{ width: "100%", padding: 0 }}>
                      <View
                        style={{
                          alignItems: "center",
                          alignSelf: "center",
                          width: "85%",
                        }}
                      >
                        <svg style={{ cursor: "pointer" }} viewBox={isMobile ? "0 0 400 400" : "-100 0 600 400"}>
                          <G>
                            <victory.VictoryPie
                              standalone={false}
                              // width={400}
                              // height={400}
                              width={400}
                              height={400}
                              // data={[
                              //   { x: 4, y: 4 }, { x: 5, y: 5 }, { x: 5, y: 5 }, { x: 26, y: 26 }
                              // ]}
                              // colorScale={["#8b8b8b", "#d0d0d0", "#e94343", "#558bff"]}
                              data={devicesArray}
                              colorScale={devicesArrayColor}
                              labels={({ datum }) => `${datum.x}`}
                              events={[
                                {
                                  target: "data",
                                  eventHandlers: {
                                    onClick: () => {
                                      return [
                                        {
                                          target: "data",
                                          mutation: (props) => {
                                            var props_index = props.index;
                                            // // console.log('index: '+JSON.stringify(props));
                                            var temp = [];
                                            var jsonstring = JSON.stringify(
                                              props,
                                              null,
                                              2
                                            );
                                            var jsonstring1 =
                                              jsonstring.replaceAll(
                                                "index:  ",
                                                ""
                                              );
                                            // temp.push(jsonstring);

                                            var jsonparse =
                                              JSON.parse(jsonstring1);
                                            var iLength = jsonparse.data.length;
                                            // console.log("iLength" + iLength);


                                            navigation.navigate(
                                              "DeviceDetailsReport",
                                              {
                                                isOnline:
                                                  jsonparse.data[props_index]
                                                    .status,
                                                zoneId: ZoneValueId,
                                                subzoneId: SubzoneValueId
                                              }
                                            );
                                            // // console.log('index: ' + props.index);
                                            return null;
                                          },
                                        },
                                        {
                                          target: "labels",
                                          mutation: (props) => {
                                            // navigation.navigate(
                                            //   "DeviceDetailsReport",
                                            //   {isOnline: props.text }
                                            // );
                                            return null;
                                          },
                                        },
                                      ];
                                    },
                                  },
                                },
                              ]}
                              innerRadius={75}
                              labelRadius={160}
                              style={isMobile ? { labels: { fontSize: 25, fill: "#000" } } : { labels: { fontSize: 35, fill: "#000" } }}
                            />
                            <victory.VictoryLabel
                              textAnchor="middle"
                              style={{ fontSize: 35 }}
                              x={200}
                              y={200}
                              text={
                                devicesArrayCount
                                  ? devicesArrayCount
                                  : "No Data Found"
                              }
                            />
                          </G>
                        </svg>
                        <View
                          style={isMobile ? {
                            marginTop: 15,
                            alignSelf: "flex-start",
                            width: "100%"
                          } : {
                            flexDirection: "row",
                            marginTop: 4,
                            alignSelf: "flex-start",
                            width: "100%",
                          }}
                        >
                          <View
                            style={{
                              alignItems: "center",
                              width: "50%",
                              flexDirection: "row",
                              marginRight: 5,
                              // marginBottom: 14,
                            }}
                          >
                            <TouchableOpacity
                              style={{ flexDirection: "row" }}
                              onPress={() => {
                                try {
                                  const index = devicesArray.findIndex(item => item.status === true)
                                  console.log("status_ok", devicesArray[index].status);
                                  navigation.navigate(
                                    "DeviceDetailsReport",
                                    {
                                      isOnline:
                                        devicesArray[index]
                                          .status,
                                    }
                                  );
                                } catch (error) {
                                  console.log("error", error)
                                }
                              }}>
                              <Icon color="#558BFF" name="square" size={20} />
                              <Text
                                style={isMobile ? [styles.btnTextCancel, { marginLeft: 5, fontSize: 13 }] : [styles.btnTextCancel, { marginLeft: 5 }]}
                              >
                                Online
                              </Text>
                            </TouchableOpacity>
                          </View>
                          <View
                            style={isMobile ? {
                              alignItems: "center",
                              width: "50%",
                              flexDirection: "row",
                              marginLeft: 5,
                            } : {
                              alignItems: "center",
                              width: "50%",
                              flexDirection: "row",
                              marginRight: 5,
                            }}
                          >
                            <TouchableOpacity
                              style={{ flexDirection: "row" }}
                              onPress={() => {
                                try {
                                  const index = devicesArray.findIndex(item => item.status === false)
                                  console.log("status_ok", devicesArray[index].status);
                                  navigation.navigate(
                                    "DeviceDetailsReport",
                                    {
                                      isOnline:
                                        devicesArray[index]
                                          .status,
                                    }
                                  );
                                } catch (error) {
                                  console.log("error", error)
                                }
                              }}>
                              <Text
                                style={isMobile ? [styles.btnTextCancel, { marginLeft: -4.5, fontSize: 13 }] : [styles.btnTextCancel, { marginLeft: 5 }]}
                              >
                                <Icon color="#D0D0D0" name="square" size={20} />{" "}
                                Offline
                              </Text>
                            </TouchableOpacity>
                          </View>
                        </View>
                        <View
                          style={{
                            flexDirection: "row",
                            marginBottom: 20,
                            alignSelf: "flex-start",
                            width: "100%",
                          }}
                        >
                          <View
                            style={{
                              width: "50%",
                              flexDirection: "row",
                              marginRight: 5,
                            }}
                          ></View>
                          <View
                            style={{
                              marginLeft: 5,
                              width: "50%",
                              flexDirection: "row",
                            }}
                          ></View>
                        </View>
                      </View>
                    </View>
                  </View>
                </View>
              </View>
            )
          }

          {energyStatusArrayData.length != 0 &&
            (energyStatusArray.length != 0 && energyStatusArray != undefined &&
              < View style={isMobile ? { width: "48%", height: 340, } : { width: "24%", marginTop: "-4%", height: 340, marginRight: "-1%", }}>
                <View
                  style={isMobile ? [
                    styles.shadowProp,
                    {
                      backgroundColor: "white",
                      width: "100%",
                      height: 340
                    },
                  ] : [
                    styles.shadowProp,
                    {
                      backgroundColor: "white",
                      width: "95%",
                      height: 275,
                      overflow: 'hidden'
                    },
                  ]}
                >
                  <View>
                    <View style={isMobile ? { width: "100%", padding: 10, paddingTop: 20, borderBottomWidth: 1, borderBottomColor: 'grey', paddingBottom: 18 } : { width: "100%", padding: 8, borderBottomWidth: 1, borderBottomColor: 'grey' }}>
                      <View style={{ alignItems: "flex-start" }}>
                        <View style={{ flexDirection: "row" }}>
                          <View
                            style={{
                              alignItems: "center",
                              flexDirection: "row",
                              marginRight: 10,
                            }}
                          >
                            <Image
                              style={isMobile ? {
                                height: 15,
                                width: 25,
                                marginLeft: -5,
                                resizeMode: "contain",
                              } : {
                                height: 25,
                                width: 35,
                                marginRight: 5,
                                resizeMode: "contain",
                              }}
                              source={require("../../assets/images/Energy.png")}
                            />
                            <Text
                              style={isMobile ? [styles.btnTextCancel, { fontSize: 13, marginLeft: 5 }] : [styles.btnTextCancel, { marginLeft: 6, fontSize: 16 }]}
                            >
                              {energyStatusName}
                            </Text>
                          </View>
                        </View>
                      </View>
                    </View>
                    {/* <View
                    style={{
                      marginVertical: 5,
                      height: 0.5,
                      width: "100%",
                      alignSelf: "center",
                      backgroundColor: "grey",
                    }}
                  /> */}
                    <View style={{ width: "100%", padding: 0 }}>
                      <View
                        style={{
                          alignItems: "center",
                          alignSelf: "center",
                          width: "85%",
                        }}
                      >
                        <svg style={{ cursor: "pointer" }} viewBox={isMobile ? "0 0 400 400" : "-100 0 600 400"}>
                          <G>
                            <victory.VictoryPie
                              standalone={false}
                              width={400}
                              height={400}
                              // data={[
                              //   { x: 4, y: 4 }, { x: 5, y: 5 }, { x: 26, y: 26 }
                              // ]}
                              // colorScale={["#8b8b8b", "#E94343", "#558bff"]}
                              data={energyStatusArray}
                              colorScale={energyStatusArrayColor}
                              events={[
                                {
                                  target: "data",
                                  eventHandlers: {
                                    onClick: () => {
                                      return [
                                        {
                                          target: "data",
                                          mutation: (props) => {
                                            var props_index = props.index;
                                            // // console.log('index: '+JSON.stringify(props));
                                            var temp = [];
                                            console.log("props", props)
                                            console.log("props_index", props_index)
                                            var jsonstring = JSON.stringify(
                                              props,
                                              null,
                                              2
                                            );
                                            console.log("jsonstring", jsonstring)
                                            var jsonstring1 =
                                              jsonstring.replaceAll(
                                                "index:  ",
                                                ""
                                              );
                                            console.log("jsonstring1", jsonstring1)
                                            // temp.push(jsonstring);

                                            var jsonparse =
                                              JSON.parse(jsonstring1);
                                            var iLength = jsonparse.data.length;
                                            // console.log("iLength" + iLength);
                                            console.log("jsonparse", jsonparse)

                                            navigation.navigate(
                                              "EnergyStatusDetailReport",
                                              {
                                                status:
                                                  jsonparse.data[props_index]
                                                    .status,
                                                zoneId: ZoneValueId,
                                                subzoneId: SubzoneValueId
                                              }
                                            );
                                            // // console.log('index: ' + props.index);
                                            return null;
                                          },
                                        },
                                        {
                                          target: "labels",
                                          mutation: (props) => {
                                            // navigation.navigate(
                                            //   "DeviceDetailsReport",
                                            //   {isOnline: props.text }
                                            // );
                                            return null;
                                          },
                                        },
                                      ];
                                    },
                                  },
                                },
                              ]}
                              innerRadius={75}
                              labelRadius={160}
                              style={isMobile ? { labels: { fontSize: 25, fill: "#000" } } : { labels: { fontSize: 35, fill: "#000" } }}
                            />
                            <victory.VictoryLabel
                              textAnchor="middle"
                              style={{ fontSize: 35 }}
                              x={200}
                              y={200}
                              text={
                                energyStatusArrayCount
                                  ? energyStatusArrayCount
                                  : "No data found"
                              }
                            />
                          </G>
                        </svg>
                        <View
                          style={{
                            flexDirection: "row",
                            // marginTop: 15,
                            alignSelf: "flex-start",
                            width: "100%",
                          }}
                        >
                          <View
                            style={{
                              alignItems: "center",
                              width: "50%",
                              flexDirection: "row",
                              marginRight: 5,
                            }}
                          >
                            <TouchableOpacity
                              style={{ flexDirection: "row" }}
                              onPress={() => {
                                try {
                                  const index = energyStatusArray.findIndex(item => item.status === "ok")
                                  console.log("status_ok", energyStatusArray[index].status);
                                  navigation.navigate(
                                    "EnergyStatusDetailReport",
                                    {
                                      status:
                                        energyStatusArray[index]
                                          .status,
                                      zoneId: ZoneValueId,
                                      subzoneId: SubzoneValueId
                                    }
                                  );
                                } catch (error) {
                                  console.log("error", error)
                                }
                              }}>

                              <Icon color="#558BFF" name="square" size={20} />
                              <Text
                                style={isMobile ? [styles.btnTextCancel, { fontSize: 13, marginLeft: 5 }] : [styles.btnTextCancel, { marginLeft: 5 }]}
                              >
                                Ok
                              </Text>
                            </TouchableOpacity>
                          </View>
                          <View
                            style={isMobile ? {
                              alignItems: "center",
                              width: "40%",
                              flexDirection: "row",
                              marginLeft: 5,
                            } : {
                              alignItems: "center",
                              width: "40%",
                              flexDirection: "row",
                              marginRight: 5,
                            }}
                          >
                            <TouchableOpacity
                              style={{ flexDirection: "row" }}
                              onPress={() => {
                                try {
                                  const index = energyStatusArray.findIndex(item => item.status === "low_battery")
                                  console.log("status_low", energyStatusArray[index].status);
                                  navigation.navigate(
                                    "EnergyStatusDetailReport",
                                    {
                                      status:
                                        energyStatusArray[index]
                                          .status,
                                      zoneId: ZoneValueId,
                                      subzoneId: SubzoneValueId
                                    }
                                  );
                                } catch (error) {
                                  console.log("error", error)
                                }
                              }}>
                              <Text
                                style={isMobile ? [styles.btnTextCancel, { fontSize: 13, marginLeft: -5 }] : [styles.btnTextCancel, { marginLeft: 5 }]}
                              >
                                <Icon color="#F8DD32" name="square" size={20} /> Low
                              </Text>
                            </TouchableOpacity>
                          </View>
                        </View>
                        <View
                          style={{
                            flexDirection: "row",
                            marginBottom: 10,
                            alignSelf: "flex-start",
                            width: "100%",
                          }}
                        >
                          <View
                            style={{
                              width: "50%",
                              flexDirection: "row",
                              marginRight: 5,
                            }}
                          >
                            <TouchableOpacity
                              style={{ flexDirection: "row" }}
                              onPress={() => {
                                try {
                                  const index = energyStatusArray.findIndex(item => item.status === "critical_low_battery")
                                  console.log("status_critical", energyStatusArray[index].status);
                                  navigation.navigate(
                                    "EnergyStatusDetailReport",
                                    {
                                      status:
                                        energyStatusArray[index]
                                          .status,
                                      zoneId: ZoneValueId,
                                      subzoneId: SubzoneValueId

                                    }
                                  );
                                } catch (error) {
                                  console.log("error", error)
                                }
                              }}>
                              <Icon color="#E94343" name="square" size={20} />
                              <Text
                                style={isMobile ? [styles.btnTextCancel, { fontSize: 13, marginLeft: 5 }] : [
                                  styles.btnTextCancel,
                                  { textAlign: "left", marginLeft: 5 },
                                ]}
                              >
                                Critical
                              </Text>
                            </TouchableOpacity>
                          </View>
                          <View
                            style={isMobile ? {
                              alignItems: "center",
                              width: "40%",
                              flexDirection: "row",
                              marginLeft: 5,
                            } : {
                              alignItems: "center",
                              width: "40%",
                              flexDirection: "row",
                              marginRight: 5,
                            }}
                          >
                            <TouchableOpacity
                              style={{ flexDirection: "row" }}
                              onPress={() => {
                                try {
                                  const index = energyStatusArray.findIndex(item => item.status === "offline")
                                  console.log("status_offline", energyStatusArray[index].status);
                                  navigation.navigate(
                                    "EnergyStatusDetailReport",
                                    {
                                      status:
                                        energyStatusArray[index]
                                          .status,
                                      zoneId: ZoneValueId,
                                      subzoneId: SubzoneValueId
                                    }
                                  );
                                } catch (error) {
                                  console.log("error", error)
                                }
                              }}>
                              <Text
                                style={isMobile ? [styles.btnTextCancel, { fontSize: 13, marginLeft: -5 }] : [styles.btnTextCancel, { marginLeft: 5 }]}
                              >
                                <Icon color="#D0D0D0" name="square" size={20} />{" "}
                                Offline
                              </Text>
                            </TouchableOpacity>
                          </View>
                          {/* <View style={{ marginLeft: 5, width: '50%', flexDirection: 'row', }}>
                          <Icon color="#D0D0D0" name="square" size={20} />
                          <Text style={[styles.btnTextCancel, { alignItems: 'flex-start', marginLeft: 5 }]}>
                          </Text>
                        </View> */}
                        </View>
                      </View>
                    </View>
                  </View>
                </View>
              </View>
            )
          }

          {analogArrayData.length != 0 &&
            (analog1Array.length != 0 && analog1Array != undefined &&
              <View style={isMobile ? { width: "48%", height: 340, } : { width: "24%", marginTop: "-4%", height: 340, marginRight: "-1%", }}>
                <View
                  style={isMobile ? [
                    styles.shadowProp,
                    {
                      backgroundColor: "white",
                      width: "100%",
                      height: 340
                    },
                  ] : [
                    styles.shadowProp,
                    {
                      backgroundColor: "white",
                      width: "95%",
                      height: 275,
                      overflow: 'hidden'
                    },
                  ]}
                >
                  <View>
                    <View style={isMobile ? { width: "100%", padding: 10, paddingTop: 20, borderBottomWidth: 1, borderBottomColor: 'grey' } : { width: "100%", padding: 8, borderBottomWidth: 1, borderBottomColor: 'grey' }}>

                      <View style={{ alignItems: "flex-start" }}>
                        <View style={{ flexDirection: "row" }}>
                          <View
                            style={isMobile ? {
                              alignItems: "center",
                              flexDirection: "row",
                              width: 120
                            } : {
                              alignItems: "center",
                              flexDirection: "row",
                              marginRight: 10,
                            }}
                          >
                            <Image
                              style={isMobile ? {
                                height: 25,
                                width: 45,
                                resizeMode: "contain",
                                marginLeft: -10
                              } : {
                                height: 25,
                                width: 45,
                                resizeMode: "contain",
                              }}
                              source={require("../../assets/images/sensorLogo.png")}
                            />
                            <Text
                              style={isMobile ? [styles.btnTextCancel, { fontSize: 13, marginLeft: -7, width: 100, }] : [styles.btnTextCancel, { marginLeft: 10, fontSize: 16 }]}
                              numberOfLines={isMobile ? 2 : 1}
                            >
                              {sensorAnalog1Name}
                            </Text>
                          </View>
                        </View>
                      </View>
                    </View>
                    {/* <View
                    style={{
                      marginVertical: 5,
                      height: 0.5,
                      width: "100%",
                      alignSelf: "center",
                      backgroundColor: "grey",
                    }}
                  /> */}
                    <View style={{ width: "100%", padding: 0 }}>
                      <View
                        style={{
                          alignItems: "center",
                          alignSelf: "center",
                          width: "85%",
                        }}
                      >
                        <svg
                          style={{ cursor: "pointer" }}
                          viewBox={isMobile ? "0 0 400 400" : "-100 0 600 400"}
                        >
                          <G>
                            <victory.VictoryPie
                              standalone={false}
                              width={400}
                              height={400}
                              // data={[
                              //   { x: 3, y: 3 }, { x: 7, y: 7 }, { x: 5, y: 5 }, { x: 27, y: 27 }
                              // ]}
                              // colorScale={["#8b8b8b", "#d0d0d0", "#e94343", "#558bff"]}
                              data={analog1Array}
                              colorScale={analog1ArrayColor}
                              events={[
                                {
                                  target: "data",
                                  eventHandlers: {
                                    onClick: () => {
                                      return [
                                        {
                                          target: "data",
                                          mutation: (props) => {
                                            var props_index = props.index;
                                            // // console.log('index: '+JSON.stringify(props));
                                            var temp = [];
                                            var jsonstring = JSON.stringify(
                                              props,
                                              null,
                                              2
                                            );
                                            var jsonstring1 =
                                              jsonstring.replaceAll(
                                                "index:  ",
                                                ""
                                              );
                                            // temp.push(jsonstring);

                                            var jsonparse =
                                              JSON.parse(jsonstring1);
                                            var iLength = jsonparse.data.length;
                                            // console.log("iLength" + iLength);


                                            navigation.navigate(
                                              "SensorDetailsReport",
                                              {
                                                status:
                                                  jsonparse.data[props_index]
                                                    .status,
                                                index:
                                                  jsonparse.data[props_index]
                                                    .index,
                                                zoneId: ZoneValueId,
                                                subzoneId: SubzoneValueId

                                              }
                                            );
                                            // // console.log('index: ' + props.index);
                                            return null;
                                          },
                                        },
                                        {
                                          target: "labels",
                                          mutation: (props) => {
                                            // navigation.navigate(
                                            //   "SensorDetailsReport",
                                            //   { status: props.text }
                                            // );
                                            // return null;
                                          },
                                        },
                                      ];
                                    },
                                  },
                                },
                              ]}
                              innerRadius={75}
                              labelRadius={160}
                              style={isMobile ? { labels: { fontSize: 25, fill: "#000" } } : { labels: { fontSize: 35, fill: "#000" } }}
                            />
                            <victory.VictoryLabel
                              textAnchor="middle"
                              style={{ fontSize: 35 }}
                              x={200}
                              y={200}
                              text={
                                analog1ArrayCount
                                  ? analog1ArrayCount
                                  : "No Data found"
                              }
                            />
                          </G>
                        </svg>
                        <View
                          style={isMobile ? {
                            flexDirection: "row",
                            marginTop: 15,
                            alignSelf: "flex-start",
                            width: "100%",
                            marginBottom: 5
                          } : {
                            flexDirection: "row",
                            marginTop: -5,
                            alignSelf: "flex-start",
                            width: "100%",
                          }}
                        >
                          <View
                            style={isMobile ? {
                              alignItems: "center",
                              width: "30%",
                              flexDirection: "row",
                              marginLeft: -5,
                            } : {
                              alignItems: "center",
                              width: "30%",
                              flexDirection: "row",
                              // marginRight: 5,
                              marginLeft: 60,
                              // marginBottom: 10,
                            }}
                          >
                            <TouchableOpacity
                              style={{ flexDirection: "row" }}
                              onPress={() => {
                                try {
                                  console.log("analog1Array", analog1Array);
                                  const index = analog1Array.findIndex(item => item.status === 0)
                                  console.log("status", analog1Array[index].status);
                                  navigation.navigate(
                                    "SensorDetailsReport",
                                    {
                                      status:
                                        analog1Array[index]
                                          .status,
                                      index: analog1Array[index]
                                        .index,

                                      zoneId: ZoneValueId,
                                      subzoneId: SubzoneValueId

                                    }
                                  );
                                } catch (error) {
                                  console.log("error", error)
                                }
                              }}>
                              <Icon color="#558BFF" name="square" size={20} />
                              <Text
                                style={[styles.btnTextCancel, { marginLeft: 5 }]}
                              >
                                Ok
                              </Text>
                            </TouchableOpacity>
                          </View>
                          <View
                            style={isMobile ? {
                              alignItems: "center",
                              width: "30%",
                              flexDirection: "row",
                              marginLeft: 20,
                            } : {
                              alignItems: "center",
                              width: "30%",
                              flexDirection: "row",
                              marginRight: 5,
                            }}
                          >
                            <TouchableOpacity
                              style={{ flexDirection: "row" }}
                              onPress={() => {
                                try {
                                  const index = analog1Array.findIndex(item => item.status === 1)
                                  console.log("status_ok", analog1Array[index].status);
                                  navigation.navigate(
                                    "SensorDetailsReport",
                                    {
                                      status:
                                        analog1Array[index]
                                          .status,
                                      index: analog1Array[index]
                                        .index,
                                      zoneId: ZoneValueId,
                                      subzoneId: SubzoneValueId
                                    }
                                  );
                                } catch (error) {
                                  console.log("error", error)
                                }
                              }}>
                              <Text
                                style={[styles.btnTextCancel, { marginLeft: 5 }]}
                              >
                                <Icon color="#F8DD32" name="square" size={20} /> Low
                              </Text>
                            </TouchableOpacity>
                          </View>
                        </View>
                        <View
                          style={isMobile ? {
                            flexDirection: "row",
                            marginTop: 5,
                            alignSelf: "flex-start",
                            width: "100%",
                          } : {
                            flexDirection: "row",
                            // marginBottom: 20,
                            marginTop: 5,
                            alignSelf: "flex-start",
                            width: "100%",
                          }}
                        >
                          <View
                            style={isMobile ? {
                              alignItems: "center",
                              width: "30%",
                              flexDirection: "row",
                              marginLeft: -5,
                              // paddingTop:-60
                            } : {
                              width: "30%",
                              flexDirection: "row",
                              // marginRight: 5,
                              marginLeft: 25,
                            }}
                          >
                            <TouchableOpacity
                              style={{ flexDirection: "row" }}
                              onPress={() => {
                                try {
                                  const index = analog1Array.findIndex(item => item.status === 2)
                                  console.log("status_ok", analog1Array[index].status);
                                  navigation.navigate(
                                    "SensorDetailsReport",
                                    {
                                      status:
                                        analog1Array[index]
                                          .status,
                                      index: analog1Array[index]
                                        .index,
                                      zoneId: ZoneValueId,
                                      subzoneId: SubzoneValueId
                                    }
                                  );
                                } catch (error) {
                                  console.log("error", error)
                                }
                              }}>
                              <Icon color="#E94343" name="square" size={20} />
                              <Text
                                style={[
                                  styles.btnTextCancel,
                                  { textAlign: "left", marginLeft: 5 },
                                ]}
                              >
                                High
                              </Text>
                            </TouchableOpacity>
                          </View>

                          <View
                            style={isMobile ? {
                              alignItems: "center",
                              width: "30%",
                              flexDirection: "row",
                              marginLeft: 20,
                              // paddingTop:-60
                            } : {
                              alignItems: "center",
                              width: "35%",
                              flexDirection: "row",
                              marginRight: 5,
                            }}
                          >
                            <TouchableOpacity
                              style={{ flexDirection: "row" }}
                              onPress={() => {
                                try {
                                  const index = analog1Array.findIndex(item => item.status === 3)
                                  console.log("status_ok", analog1Array[index].status);
                                  navigation.navigate(
                                    "SensorDetailsReport",
                                    {
                                      status:
                                        analog1Array[index]
                                          .status,
                                      index: analog1Array[index]
                                        .index,
                                      zoneId: ZoneValueId,
                                      subzoneId: SubzoneValueId

                                    }
                                  );
                                } catch (error) {
                                  console.log("error", error)
                                }
                              }}>
                              <Text
                                style={[styles.btnTextCancel, { marginLeft: 5 }]}
                              >
                                <Icon color="#D0D0D0" name="square" size={20} />{" "}
                                Offline
                              </Text>
                            </TouchableOpacity>
                          </View>

                          {!isMobile && (
                            <View
                              style={isMobile ? {
                                alignItems: "center",
                                width: "30%",
                                flexDirection: "row",
                              } : {
                                alignItems: "center",
                                width: "30%",
                                flexDirection: "row",
                                marginRight: 5,
                              }}
                            >
                              <TouchableOpacity
                                style={{ flexDirection: "row" }}
                                onPress={() => {
                                  try {
                                    const index = analog1Array.findIndex(item => item.status === 4)
                                    console.log("status_ok", analog1Array[index].status);
                                    navigation.navigate(
                                      "SensorDetailsReport",
                                      {
                                        status:
                                          analog1Array[index]
                                            .status,
                                        index: analog1Array[index]
                                          .index,
                                        zoneId: ZoneValueId,
                                        subzoneId: SubzoneValueId

                                      }
                                    );
                                  } catch (error) {
                                    console.log("error", error)
                                  }
                                }}>
                                <Text
                                  style={[styles.btnTextCancel, { marginLeft: 5 }]}
                                >
                                  <Icon color="#000" name="square" size={20} />{" "}
                                  Error
                                </Text>
                              </TouchableOpacity>
                            </View>
                          )}
                        </View>
                        {isMobile && (
                          <View
                            style={isMobile ? {
                              alignItems: "center",
                              width: "30%",
                              flexDirection: "row",
                              marginTop: 10,
                              marginLeft: -15
                            } : {
                              alignItems: "center",
                              width: "30%",
                              flexDirection: "row",
                              marginRight: 5,
                            }}
                          >
                            <TouchableOpacity
                              style={{ flexDirection: "row" }}
                              onPress={() => {
                                try {
                                  const index = analog1Array.findIndex(item => item.status === 4)
                                  console.log("status_ok", analog1Array[index].status);
                                  navigation.navigate(
                                    "SensorDetailsReport",
                                    {
                                      status:
                                        analog1Array[index]
                                          .status,
                                      index: analog1Array[index]
                                        .index,
                                      zoneId: ZoneValueId,
                                      subzoneId: SubzoneValueId

                                    }
                                  );
                                } catch (error) {
                                  console.log("error", error)
                                }
                              }}>
                              <Text
                                style={[styles.btnTextCancel, { marginLeft: 5 }]}
                              >
                                <Icon color="#000" name="square" size={20} />{" "}
                                Error
                              </Text>
                            </TouchableOpacity>
                          </View>
                        )}
                      </View>
                    </View>
                  </View>
                </View>
              </View>
            )
          }

          {analogArrayData.length != 0 &&
            (analog2Array.length != 0 && analog2Array != undefined &&
              <View style={isMobile ? { width: "48%", height: 340, } : { width: "24%", marginTop: "-4%", height: 340, }}>
                <View
                  style={isMobile ? [
                    styles.shadowProp,
                    {
                      backgroundColor: "white",
                      width: "100%",
                      height: 340
                    },
                  ] : [
                    styles.shadowProp,
                    {
                      backgroundColor: "white",
                      width: "95%",
                      height: 275,
                      overflow: 'hidden'
                    },
                  ]}
                >
                  <View>
                    <View style={isMobile ? { width: "100%", padding: 10, paddingTop: 20, borderBottomWidth: 1, borderBottomColor: 'grey' } : { width: "100%", padding: 8, borderBottomWidth: 1, borderBottomColor: 'grey' }}>

                      <View style={{ alignItems: "flex-start" }}>
                        <View style={{ flexDirection: "row" }}>
                          <View
                            style={isMobile ? {
                              alignItems: "center",
                              flexDirection: "row",
                              width: 120
                            } : {
                              alignItems: "center",
                              flexDirection: "row",
                              marginRight: 10,
                            }}
                          >
                            <Image
                              style={isMobile ? {
                                height: 25,
                                width: 45,
                                resizeMode: "contain",
                                marginLeft: -10
                              } : {
                                height: 25,
                                width: 45,
                                resizeMode: "contain",
                              }}
                              source={require("../../assets/images/sensorLogo.png")}
                            />
                            <Text
                              style={isMobile ? [styles.btnTextCancel, { fontSize: 13, marginLeft: -7, width: 100, }] : [styles.btnTextCancel, { marginLeft: 10, fontSize: 16 }]}
                              numberOfLines={isMobile ? 2 : 1}
                            >
                              {sensorAnalog2Name}
                            </Text>
                          </View>
                        </View>
                      </View>
                    </View>
                    {/* <View
                    style={{
                      marginVertical: 5,
                      height: 0.5,
                      width: "100%",
                      alignSelf: "center",
                      backgroundColor: "grey",
                    }}
                  /> */}

                    <View style={{ width: "100%", padding: 0 }}>
                      <View
                        style={{
                          alignItems: "center",
                          alignSelf: "center",
                          width: "85%",
                        }}
                      >
                        <svg
                          style={{ cursor: "pointer" }}
                          viewBox={isMobile ? "0 0 400 400" : "-100 0 600 400"}
                        >
                          <G>
                            <victory.VictoryPie
                              standalone={false}
                              width={400}
                              height={400}
                              // data={[
                              //   { x: 6, y: 6 }, { x: 5, y: 5 }, { x: 5, y: 5 }, { x: 24, y: 24 }
                              // ]}
                              // colorScale={["#8b8b8b", "#d0d0d0", "#e94343", "#558bff"]}
                              data={analog2Array}
                              colorScale={analog2ArrayColor}
                              events={[
                                {
                                  target: "data",
                                  eventHandlers: {
                                    onClick: () => {
                                      return [
                                        {
                                          target: "data",
                                          mutation: (props) => {
                                            var props_index = props.index;
                                            // // console.log('index: '+JSON.stringify(props));
                                            var temp = [];
                                            var jsonstring = JSON.stringify(
                                              props,
                                              null,
                                              2
                                            );
                                            var jsonstring1 =
                                              jsonstring.replaceAll(
                                                "index:  ",
                                                ""
                                              );
                                            // temp.push(jsonstring);

                                            var jsonparse =
                                              JSON.parse(jsonstring1);
                                            var iLength = jsonparse.data.length;
                                            // console.log("iLength" + iLength);


                                            navigation.navigate(
                                              "SensorDetailsReport",
                                              {
                                                status:
                                                  jsonparse.data[props_index]
                                                    .status,
                                                index:
                                                  jsonparse.data[props_index]
                                                    .index,
                                                zoneId: ZoneValueId,
                                                subzoneId: SubzoneValueId
                                              }
                                            );
                                            // // console.log('index: ' + props.index);
                                            return null;
                                          },
                                        },
                                        {
                                          target: "labels",
                                          mutation: (props) => {
                                            // navigation.navigate(
                                            //   "SensorDetailsReport",
                                            //   { status: props.text }
                                            // );
                                            // return null;
                                          },
                                        },
                                      ];
                                    },
                                  },
                                },
                              ]}
                              innerRadius={75}
                              labelRadius={160}
                              style={isMobile ? { labels: { fontSize: 25, fill: "#000" } } : { labels: { fontSize: 35, fill: "#000" } }}
                            />
                            <victory.VictoryLabel
                              textAnchor="middle"
                              style={{ fontSize: 35 }}
                              x={200}
                              y={200}
                              text={
                                analog2ArrayCount
                                  ? analog2ArrayCount
                                  : "No Data Found"
                              }
                            />
                          </G>
                        </svg>
                        <View
                          style={isMobile ? {
                            flexDirection: "row",
                            marginTop: 15,
                            alignSelf: "flex-start",
                            width: "100%",
                            marginBottom: 5
                          } : {
                            flexDirection: "row",
                            marginTop: -5,
                            alignSelf: "flex-start",
                            width: "100%",
                          }}
                        >
                          <View
                            style={isMobile ? {
                              alignItems: "center",
                              width: "30%",
                              flexDirection: "row",
                              marginLeft: -5,
                            } : {
                              alignItems: "center",
                              width: "30%",
                              flexDirection: "row",
                              // marginRight: 5,
                              marginLeft: 60,
                              // marginBottom: 10,
                            }}
                          >
                            <TouchableOpacity
                              style={{ flexDirection: "row" }}
                              onPress={() => {
                                try {
                                  const index = analog2Array.findIndex(item => item.status === 0)
                                  console.log("status_ok", analog2Array[index].status);

                                  navigation.navigate(
                                    "SensorDetailsReport",
                                    {
                                      status:
                                        analog2Array[index]
                                          .status,
                                      index: analog2Array[index]
                                        .index,
                                      zoneId: ZoneValueId,
                                      subzoneId: SubzoneValueId
                                    }
                                  );
                                } catch (error) {
                                  console.log("error", error)
                                }
                              }}>
                              <Icon color="#558BFF" name="square" size={20} />
                              <Text
                                style={[styles.btnTextCancel, { marginLeft: 5 }]}
                              >
                                Ok
                              </Text>
                            </TouchableOpacity>
                          </View>
                          <View
                            style={isMobile ? {
                              alignItems: "center",
                              width: "30%",
                              flexDirection: "row",
                              marginLeft: 20,
                            } : {
                              alignItems: "center",
                              width: "30%",
                              flexDirection: "row",
                              marginRight: 5,
                            }}
                          >
                            <TouchableOpacity
                              style={{ flexDirection: "row" }}
                              onPress={() => {
                                try {
                                  const index = analog2Array.findIndex(item => item.status === 1)
                                  console.log("status_ok", analog2Array[index].status);

                                  navigation.navigate(
                                    "SensorDetailsReport",
                                    {
                                      status:
                                        analog2Array[index]
                                          .status,
                                      index: analog2Array[index]
                                        .index,
                                      zoneId: ZoneValueId,
                                      subzoneId: SubzoneValueId
                                    }
                                  );
                                } catch (error) {
                                  console.log("error", error)
                                }
                              }}>
                              <Text
                                style={[styles.btnTextCancel, { marginLeft: 5 }]}
                              >
                                <Icon color="#F8DD32" name="square" size={20} /> Low
                              </Text>
                            </TouchableOpacity>
                          </View>
                        </View>
                        <View
                          style={isMobile ? {
                            flexDirection: "row",
                            marginTop: 5,
                            alignSelf: "flex-start",
                            width: "100%",
                          } : {
                            flexDirection: "row",
                            // marginBottom: 20,
                            marginTop: 5,
                            alignSelf: "flex-start",
                            width: "100%",
                          }}
                        >
                          <View
                            style={isMobile ? {
                              alignItems: "center",
                              width: "30%",
                              flexDirection: "row",
                              marginLeft: -5,
                              // paddingTop:-60
                            } : {
                              width: "30%",
                              flexDirection: "row",
                              // marginRight: 5,
                              marginLeft: 25,
                            }}
                          >
                            <TouchableOpacity
                              style={{ flexDirection: "row" }}
                              onPress={() => {
                                try {
                                  const index = analog2Array.findIndex(item => item.status === 2)
                                  console.log("status_ok", analog2Array[index].status);

                                  navigation.navigate(
                                    "SensorDetailsReport",
                                    {
                                      status:
                                        analog2Array[index]
                                          .status,
                                      index: analog2Array[index]
                                        .index,
                                      zoneId: ZoneValueId,
                                      subzoneId: SubzoneValueId
                                    }
                                  );
                                } catch (error) {
                                  console.log("error", error)
                                }
                              }}>
                              <Icon color="#E94343" name="square" size={20} />
                              <Text
                                style={[
                                  styles.btnTextCancel,
                                  { textAlign: "left", marginLeft: 5 },
                                ]}
                              >
                                High
                              </Text>
                            </TouchableOpacity>
                          </View>

                          <View
                            style={isMobile ? {
                              alignItems: "center",
                              width: "30%",
                              flexDirection: "row",
                              marginLeft: 20,
                              // paddingTop:-60
                            } : {
                              alignItems: "center",
                              width: "35%",
                              flexDirection: "row",
                              marginRight: 5,
                            }}
                          >
                            <TouchableOpacity
                              style={{ flexDirection: "row" }}
                              onPress={() => {
                                try {
                                  const index = analog2Array.findIndex(item => item.status === 3)
                                  console.log("status_ok", analog2Array[index].status);

                                  navigation.navigate(
                                    "SensorDetailsReport",
                                    {
                                      status:
                                        analog2Array[index]
                                          .status,
                                      index: analog2Array[index]
                                        .index,
                                      zoneId: ZoneValueId,
                                      subzoneId: SubzoneValueId
                                    }
                                  );
                                } catch (error) {
                                  console.log("error", error)
                                }
                              }}>
                              <Text
                                style={[styles.btnTextCancel, { marginLeft: 5 }]}
                              >
                                <Icon color="#D0D0D0" name="square" size={20} />{" "}
                                Offline
                              </Text>
                            </TouchableOpacity>
                          </View>

                          {!isMobile && (
                            <View
                              style={{
                                alignItems: "center",
                                width: "30%",
                                flexDirection: "row",
                                marginRight: 5,
                              }}
                            >
                              <TouchableOpacity
                                style={{ flexDirection: "row" }}
                                onPress={() => {
                                  try {
                                    const index = analog2Array.findIndex(item => item.status === 4)
                                    console.log("status_ok", analog2Array[index].status);

                                    navigation.navigate(
                                      "SensorDetailsReport",
                                      {
                                        status:
                                          analog2Array[index]
                                            .status,
                                        index: analog2Array[index]
                                          .index,
                                        zoneId: ZoneValueId,
                                        subzoneId: SubzoneValueId
                                      }
                                    );
                                  } catch (error) {
                                    console.log("error", error)
                                  }
                                }}>
                                <Text
                                  style={[styles.btnTextCancel, { marginLeft: 5 }]}
                                >
                                  <Icon color="#000" name="square" size={20} />{" "}
                                  Error
                                </Text>
                              </TouchableOpacity>
                            </View>
                          )}

                        </View>
                        {isMobile && (
                          <View
                            style={isMobile ? {
                              alignItems: "center",
                              width: "30%",
                              flexDirection: "row",
                              marginTop: 10,
                              marginLeft: -15
                            } : {
                              alignItems: "center",
                              width: "30%",
                              flexDirection: "row",
                              marginRight: 5,
                            }}
                          >
                            <TouchableOpacity
                              style={{ flexDirection: "row" }}
                              onPress={() => {
                                try {
                                  const index = analog2Array.findIndex(item => item.status === 4)
                                  console.log("status_ok", analog2Array[index].status);

                                  navigation.navigate(
                                    "SensorDetailsReport",
                                    {
                                      status:
                                        analog2Array[index]
                                          .status,
                                      index: analog2Array[index]
                                        .index,
                                      zoneId: ZoneValueId,
                                      subzoneId: SubzoneValueId
                                    }
                                  );
                                } catch (error) {
                                  console.log("error", error)
                                }
                              }}>
                              <Text
                                style={[styles.btnTextCancel, { marginLeft: 5 }]}
                              >
                                <Icon color="#000" name="square" size={20} />{" "}
                                Error
                              </Text>
                            </TouchableOpacity>
                          </View>
                        )}
                      </View>
                    </View>
                  </View>
                </View>
              </View>
            )
          }
          {waterMeterDashboard?.map((item: any, index: any) => renderWaterMeterItem(item, index))}
          {switchDashboard?.map((item: any, index: any) => renderSwitchItem(item, index))}
        </View>
      </PreviewLayout>
    </>


  );
}

const styles = StyleSheet.create({
  contanerSwitch: {
    backgroundColor: "white",
    borderColor: "#eee",
    borderRadius: 5,
    alignSelf: "center",
    borderWidth: 1,
    // justifyContent: "center",
    height: "25%",
    margin: "auto",
    padding: 10,
    right: "25%",
    // position: 'fixed',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
    // marginRight: 10,
    // marginRight: 1,
    width: "25%",
  },
  container: {
    flex: 1,
    marginTop: 8,
    backgroundColor: 'aliceblue',
    maxHeight: 400,
    flexWrap: 'wrap',
    display: '-webkit-inline-box',
    alignContent: 'flex-start',
  },
  dropdown: {
    borderColor: "#c3c3c3",
    width: "30%",
    borderWidth: 1,
    backgroundColor: "#EAEAEA",
    marginHorizontal: 20,
    borderRadius: 2,
    height: 40,
    outlineStyle: "none",
    padding: 10,
  },
  dropdown1: {
    borderColor: "#c3c3c3",
    // width: "70%",
    borderWidth: 1,
    borderRadius: 2,
    height: 30,
    padding: 10,
    marginTop: 5,
    // marginBottom: 5,
  },
  lableText: {
    color: "#484848",
    fontWeight: "700",
    marginTop: 18,
    // fontSize: 21,
  },
  box: {
    width: 300,
    height: 300,
  },
  box1: {
    backgroundColor: 'orangered',
  },
  box2: {
    backgroundColor: 'orange',
  },
  box3: {
    backgroundColor: 'mediumseagreen',
  },
  box4: {
    backgroundColor: 'deepskyblue',
  },
  box5: {
    backgroundColor: 'mediumturquoise',
  },
  placeholderStyle: {
    fontSize: 12,
  },
  selectedTextStyle: {
    fontSize: 12,
  },
  iconStyle: {
    width: 20,
    height: 20,
  },
  inputSearchStyle: {
    height: 30,
    fontSize: 12,
    outlineStyle: "none",
  },
  title: {
    fontSize: 20,
    fontWeight: "bold",
  },
  card: {
    width: "50%",
    flex: 1,
    marginVertical: 10,
    height: "30%",
  },
  refreshButton: {
    alignItems: "center",
    backgroundColor: "#2196f3",
    padding: 10,
    borderWidth: 1,
    borderColor: "#2196f3",
  },
  ring: {
    position: "absolute",
    width: 30,
    height: 30,
    borderRadius: 40,
    borderColor: "#E75480",
    borderWidth: 10,
  },
  refreshText: {
    color: "#fff",
    fontWeight: "600",
    marginHorizontal: 10,
  },
  dashCard: {
    width: "100%",
    flex: 1,
    marginVertical: 10,
  },
  btnTextCancel: {
    color: "#747474",
    fontWeight: "600",
    textAlign: "center",
  },
  link: {
    marginTop: 15,
    paddingVertical: 15,
  },
  titleStyle: {
    alignSelf: "center",
    paddingBottom: 20,
    fontSize: 18,
    fontWeight: "700",
  },
  rotate: {
    transform: [{ rotateZ: "-90deg" }],
  },
  linkText: {
    fontSize: 14,
    color: "#2e78b7",
  },
  header: {
    textAlign: "center",
    fontSize: 18,
    padding: 16,
    marginTop: 16,
  },
  previewContainer: { padding: 10, flex: 1 },
  shadowProp: {
    shadowColor: "#171717",
    shadowOffset: { width: -2, height: 4 },
    shadowOpacity: 0.2,
    shadowRadius: 15,
  },
  icon: {
    marginRight: 5,
  },
  item: {
    padding: 9,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  textItem: {
    // flex: 1,
    fontSize: 14,
  },
});
